import React, { FC, useCallback, useEffect, useState } from 'react';
import { Dialog, makeStyles, createStyles } from '@material-ui/core';
import { TextField, useTranslate, useUnselectAll } from 'react-admin';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { colors } from '../../../theme';
import {
  FilePickerBreadcrumb,
  FilePickerDatagridRow,
  FilePickerSaveButton,
} from '.';
import {
  CustomList,
  Datagrid,
  IconField,
  DatagridBody,
  DateField,
  Typography,
  Filter,
  SiteSearchInput,
  Loading,
} from '../..';
import { useResource } from '../../../hooks';
import { FileStorageData } from '../../../dataProvider';
import {
  buttonDisabledOpacity,
  buttonWidth,
  buttonHeight,
} from '../../../theme/define';

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      marginLeft: 60,
      '& > div > div': {
        minWidth: 960,
        height: '87%',
      },
    },
    header: {
      padding: '16px 24px 8px 24px',
      '& > h2 > p': {
        fontSize: 20,
      },
    },
    root: {
      padding: '0px 24px 0px 24px',
    },
    table: {
      '& > div:nth-child(2)': {
        overflow: 'auto',
        height: 'calc(75vh - 34vh)',
      },
      '& > div:nth-child(3)': {
        marginTop: 8,
        marginBottom: 4,
        paddingTop: 8,
        paddingRight: 12,
      },
      '& .MuiTable-root': {
        '& .MuiTableHead-root': {
          '& .MuiTableRow-root': {
            '& .MuiTableCell-root': {
              paddingTop: '6px',
              paddingBottom: '6px',
              boxSizing: 'content-box',
            },
            // チェックボックス
            '& .MuiTableCell-root:nth-child(1)': {
              paddingRight: '16px',
              paddingLeft: '15px',
            },
            // アイコン
            '& .MuiTableCell-root:nth-child(2)': {
              paddingRight: '15px',
              paddingLeft: '15px',
            },
            // 名称
            '& .MuiTableCell-root:nth-child(3)': {
              paddingRight: '15px',
              paddingLeft: '15px',
              width: 300,
            },
            // 作成者
            '& .MuiTableCell-root:nth-child(4)': {
              paddingRight: '15px',
              paddingLeft: '15px',
              width: 120,
            },
            // 最新更新日時
            '& .MuiTableCell-root:nth-child(5)': {
              paddingRight: '15px',
              paddingLeft: '15px',
              width: 140,
            },
            // 最終更新者
            '& .MuiTableCell-root:nth-child(6)': {
              paddingRight: '15px',
              paddingLeft: '15px',
              width: 120,
            },
            // サイズ
            '& .MuiTableCell-root:nth-child(7)': {
              paddingRight: '15px',
              paddingLeft: '15px',
              width: 90,
            },
          },
        },
      },
    },
    loading: {
      '& > div': {
        height: 'calc(75vh - 32vh)',
      },
    },
    footer: {
      paddingLeft: 24,
      paddingBottom: 8,
      height: '10%',
      overflow: 'auto',
      scrollbarWidth: 'none',
    },
    button: {
      background: colors.button.save,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        backgroundColor: colors.button.saveHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.saveDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
      '& span': {
        padding: 0,
      },
    },
    checkBox: {
      marginTop: 14,
      marginRight: 14,
    },
    indent: {
      width: 38,
    },
  }),
);

interface Filter<T> {
  [key: string]: T;
}

const ListFilter: React.FC<any> = props => {
  if (props.isSearchClear) {
    props.setFilters({ q: '' }, { q: '' }); // SearchInput の入力をクリア
    props.setIsSearchClear(false); // SearchInput クリアフラグを解除
  }
  return (
    <Filter {...props}>
      <SiteSearchInput source="q" alwaysOn />
    </Filter>
  );
};

interface Props {
  open: boolean;
  onClose: () => void;
  loading: boolean;
  resourceOwnerId: string;
  handleAddScGroupwareFile: (record: any) => void;
}

export type ScGroupwareFileBreadCrumbs = {
  id: string;
  name: string;
  nodeType?: string;
};

const FilePickerDialog: FC<Props> = ({
  open,
  onClose,
  loading,
  resourceOwnerId,
  handleAddScGroupwareFile,
}) => {
  const resource = useResource('fileStorages');
  const classes = useStyles();
  const translate = useTranslate();
  const unselectAll = useUnselectAll(resource);
  const breadcrumbHome = {
    id: '1',
    name: translate('admin.dialog.filePicker.breadcrumbHome'),
  };
  const [breadcrumbs, setBreadcrumbs] = useState<ScGroupwareFileBreadCrumbs[]>([
    breadcrumbHome,
  ]);
  const [filter, setFilter] = useState<Filter<any>>({ resourceOwnerId });
  const [isSearchClear, setIsSearchClear] = useState(false);
  const [bucketId, setBucketId] = useState<string>();
  const [selectedRecords, setSelectedRecords] = useState<FileStorageData[]>([]);
  const [firstLodingStatus, setFirstLodingStatus] = useState<
    'unloaded' | 'firstLoding' | 'lodingComplete'
  >('unloaded');

  // チェックボックス クリック時
  const onClickCheckbox = useCallback(
    (record: FileStorageData, selected?: boolean) => {
      if (!selected) {
        // チェックボックスが選択された場合、レコードを追加
        setSelectedRecords(prevSelected => [...prevSelected, record]);
      } else {
        // チェックボックスが解除された場合、レコードを削除
        setSelectedRecords(prevSelected =>
          prevSelected.filter(item => item.id !== record.id),
        );
      }
    },
    [setSelectedRecords],
  );

  // レコード クリック時
  const onClickList = (record: FileStorageData) => {
    if (!record) return;
    // bucket clicked
    if (
      record.nodeType === 'bucket' &&
      record.id !== breadcrumbs[breadcrumbs.length - 1].id
    ) {
      setBucketId(record.id);
      setFilter({ bucketId: record.id, parentNodeId: null });
      setBreadcrumbs([
        ...breadcrumbs,
        {
          id: record.id,
          name: record.name,
          nodeType: record.nodeType,
        },
      ]);
      setSelectedRecords([]);
      setIsSearchClear(true);
    }
    // folder clicked
    else if (
      record.nodeType === 'directory' &&
      record.id !== breadcrumbs[breadcrumbs.length - 1].id
    ) {
      setFilter({ bucketId, parentNodeId: record.id });
      setBreadcrumbs([
        ...breadcrumbs,
        {
          id: record.id,
          name: record.name,
          nodeType: record.nodeType,
        },
      ]);
      setSelectedRecords([]);
      setIsSearchClear(true);
    }
    // file clicked
    else {
      setIsSearchClear(false);
    }
  };

  // ダイアログを閉じる・レコード登録時に使用
  const handleClose = useCallback(() => {
    onClose();
    setFilter({ resourceOwnerId });
    setBreadcrumbs([breadcrumbHome]);
    setSelectedRecords([]);
  }, [onClose, resourceOwnerId, breadcrumbHome]);

  // パンくずリスト クリック時
  const onClickBreadcumbs = useCallback(
    (value: ScGroupwareFileBreadCrumbs, index: number) => {
      // 戻り先が 初期画面
      if (value.id === '1') {
        setFilter({ resourceOwnerId, bucketId: null, parentNodeId: null });
        setBreadcrumbs([breadcrumbHome]);
        unselectAll(resource);
        setSelectedRecords([]);
        setIsSearchClear(true);
      }
      // 戻り先が Bucket配下
      else if (
        index !== breadcrumbs.length - 1 &&
        value.nodeType === 'bucket'
      ) {
        setFilter({ bucketId: value.id, parentNodeId: null });
        setBreadcrumbs(breadcrumbs.splice(0, index + 1));
        unselectAll(resource);
        setSelectedRecords([]);
        setIsSearchClear(true);
      }
      // 戻り先が Folder配下
      else if (
        index !== breadcrumbs.length - 1 &&
        value.nodeType === 'directory'
      ) {
        setFilter({
          bucketId,
          parentNodeId: value.id,
        });
        setBreadcrumbs(breadcrumbs.splice(0, index + 1));
        unselectAll(resource);
        setSelectedRecords([]);
        setIsSearchClear(true);
      }
    },
    [
      resource,
      breadcrumbs,
      unselectAll,
      resourceOwnerId,
      bucketId,
      breadcrumbHome,
    ],
  );

  // 画面遷移後、初めてダイアログを開いたか判定
  useEffect(() => {
    if (open && loading && firstLodingStatus === 'unloaded') {
      setFirstLodingStatus('firstLoding');
    }
    if (firstLodingStatus === 'firstLoding' && !loading) {
      setFirstLodingStatus('lodingComplete');
    }
  }, [open, loading, firstLodingStatus]);

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      onClose={handleClose}
      data-testid="file_picker_dialog"
    >
      {open ? (
        <>
          <MuiDialogTitle className={classes.header}>
            <Typography>
              {translate('admin.dialog.filePicker.title')}
            </Typography>
            <FilePickerBreadcrumb
              breadcrumbs={breadcrumbs}
              onClickBreadcumbs={onClickBreadcumbs}
            />
          </MuiDialogTitle>
          <MuiDialogContent className={classes.root}>
            <CustomList
              resource={resource}
              className={classes.table}
              bulkActionButtons={
                <FilePickerSaveButton
                  onClose={handleClose}
                  handleAddScGroupwareFile={handleAddScGroupwareFile}
                  bucketId={bucketId}
                  selectedRecords={selectedRecords}
                />
              }
              filter={filter}
              filters={
                <ListFilter
                  isSearchClear={isSearchClear}
                  setIsSearchClear={setIsSearchClear}
                />
              }
            >
              {firstLodingStatus !== 'lodingComplete' ? (
                <div className={classes.loading}>
                  <Loading />
                </div>
              ) : (
                <Datagrid
                  body={
                    <DatagridBody
                      row={
                        <FilePickerDatagridRow
                          loading={loading}
                          onClickList={onClickList}
                          onClickCheckbox={onClickCheckbox}
                        />
                      }
                    />
                  }
                >
                  <IconField
                    resource={resource}
                    source="nodeType"
                    label=""
                    sortable={false}
                    onTooltip={({ res, translate: translateTooltip }) => {
                      return translateTooltip(res);
                    }}
                  />
                  <TextField resource={resource} source="name" />
                  <TextField
                    resource={resource}
                    sortable={false}
                    source="createdBy.user.profile.name"
                    label="resources.fileStorages.fields.createdBy.user.profile.name"
                  />
                  <DateField resource={resource} source="updatedAt" showTime />
                  <TextField
                    resource={resource}
                    sortable={false}
                    source="updatedBy.user.profile.name"
                    label="resources.fileStorages.fields.updatedby.user.profile.name"
                  />
                  {
                    // 表示する画面がBucketかNodeかで表示項目を切り替える
                    breadcrumbs.length === 1 ? (
                      <TextField
                        resource={resource}
                        sortable={false}
                        source="files.totalSize"
                        label="resources.fileStorages.fields.files.totalSize"
                      />
                    ) : (
                      <TextField
                        resource={resource}
                        source="file.size"
                        label="resources.fileStorages.fields.file.size"
                      />
                    )
                  }
                </Datagrid>
              )}
            </CustomList>
          </MuiDialogContent>
          <Typography className={classes.footer}>
            {`${translate('admin.dialog.filePicker.selectedFile')}: `}
            {(() => {
              const fileCount = selectedRecords.length;
              if (fileCount === 1) {
                return selectedRecords[0].name;
              }
              if (fileCount === 2) {
                return `${selectedRecords[0].name}, ${selectedRecords[1].name}`;
              }
              if (fileCount > 2) {
                const remainingCount = fileCount - 2;
                return `${selectedRecords[0].name}, ${
                  selectedRecords[1].name
                }, ${translate('admin.dialog.filePicker.footer', {
                  remainingCount,
                })}`;
              }
              return ''; // データが未選択の場合
            })()}
          </Typography>
        </>
      ) : (
        false
      )}
    </Dialog>
  );
};

FilePickerDialog.displayName = 'FilePickerDialog';
export default FilePickerDialog;
