import { fetchUtils } from 'react-admin';
import { encode } from 'querystring';
import { config } from '../../utils';
import { FileStorageNodes, LandLogListFileStorageProps, getOption } from '.';

interface ListResponse {
  node: FileStorageNodes;
  nodes: FileStorageNodes[];
  total: number;
}

export default async (
  props: LandLogListFileStorageProps,
  bucketId: string,
  nodeId: string,
): Promise<ListResponse> => {
  const params = encode({ ...props });
  // GetOneパラメータでは bucketId を渡すことができないため、ここでGetOneを実施
  if (nodeId) {
    const uri = `${config.api.landlog.fileStorageUri}/v2/file_storage/buckets/${bucketId}/nodes/${nodeId}`;
    const { json } = await fetchUtils.fetchJson(`${uri}`, await getOption());
    return json;
  }
  // GetList
  const uri = `${config.api.landlog.fileStorageUri}/v2/file_storage/buckets/${bucketId}/nodes?${params}`;
  const { json } = await fetchUtils.fetchJson(`${uri}`, await getOption());
  return json;
};
