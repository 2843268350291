import React from 'react';
import { Admin, Resource, AdminProps } from 'react-admin';
import { getAuthProvider } from './auth';
import { getDataProvider } from './dataProvider';
import { getI18nProvider } from './i18n';
import { getCustomRoutes } from './routes';
import { getDefaultTheme } from './theme';
import {
  CustomLayout,
  SiteList,
  SiteShow,
  RetrofitList,
  RetrofitShow,
  BucketCreate,
  BucketTab,
  RetrofitBasicInfoCreate,
  GnssSettingList,
  Login,
  MaintenancePage,
  SupportModeEdit,
  FirmwareList,
  FirmwareCreate,
  FirmwareShow,
  RetrofitCorporationLicenseList,
  RetrofitCorporationLicenseEdit,
  RetrofitBasicInfoTab,
  BucketEdit,
  ExtensionarmEdit,
  RetrofitAlternateRegistList,
  NtripEdit,
  RetrofitBasicInfoEdit,
} from './components';
import {
  SiteIcon,
  MachineIcon,
  BucketIcon,
  RetrofitIcon,
  GnssSettingIcon,
  LicenceIcon,
  RetrofitAlternateRegistIcon,
} from './assets';
import {
  config,
  agreementFetchableStore,
  redirectAfterLoginStore,
} from './utils';

const App = () => {
  const props: AdminProps = {
    theme: getDefaultTheme(),
    loginPage: Login,
    authProvider: getAuthProvider(),
    dataProvider: getDataProvider(),
    i18nProvider: getI18nProvider(),
    customRoutes: getCustomRoutes(),
    layout: CustomLayout,
  };
  const maintenaceProps: AdminProps = {
    theme: getDefaultTheme(),
    loginPage: MaintenancePage,
    dataProvider: getDataProvider(),
    i18nProvider: getI18nProvider(),
  };

  // メンテナンス中の場合はメンテナンス画面を表示
  if (config.maintenance) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return <Admin {...maintenaceProps}>{() => {}}</Admin>;
  }

  agreementFetchableStore.init();
  redirectAfterLoginStore.init();

  return (
    <Admin {...props}>
      {permissions => {
        return [
          <Resource
            name="sites"
            icon={SiteIcon}
            list={SiteList}
            show={SiteShow}
            key="sites"
          />,
          <Resource
            name="retrofits"
            icon={MachineIcon}
            list={RetrofitList}
            edit={RetrofitShow}
            key="retrofits"
          />,
          <Resource
            name="buckets"
            icon={BucketIcon}
            list={BucketTab}
            create={BucketCreate}
            edit={BucketEdit}
            key="buckets"
          />,
          <Resource name="bucketNames" key="bucketNames" />,
          permissions.userRoles.includes('CreateRetrofit') ? (
            <Resource
              name="retrofitBasicInfos"
              icon={RetrofitIcon}
              list={RetrofitBasicInfoTab}
              create={RetrofitBasicInfoCreate}
              edit={RetrofitBasicInfoEdit}
            />
          ) : null,
          permissions.userRoles.includes('CreateRetrofit') ? (
            <Resource
              name="retrofitBulkActions"
              key="retrofitBulkActions"
              list={RetrofitBasicInfoTab}
            />
          ) : null,
          <Resource
            name="gnssSettings"
            icon={GnssSettingIcon}
            list={GnssSettingList}
            key="gnssSettings"
          />,
          <Resource name="projects" key="projects" />,
          <Resource name="projectNames" key="projectNames" />,
          <Resource name="regions" key="regions" />,
          <Resource name="projections" key="projections" />,
          <Resource name="datums" key="datums" />,
          <Resource
            name="extensionarms"
            key="extensionarms"
            list={BucketTab}
            edit={ExtensionarmEdit}
          />,
          <Resource name="extensionarmShares" key="extensionarmShares" />,
          <Resource name="geofences" key="geofences" />,
          <Resource name="geoids" key="geoids" />,
          <Resource name="tasks" key="tasks" />,
          <Resource name="corporations" key="corporations" />,
          <Resource name="accounts" key="accounts" />,
          <Resource name="retrofitRentals" key="retrofitRentals" />,
          <Resource name="assignments" key="assignments" />,
          <Resource name="asbuilts" key="asbuilts" />,
          <Resource name="asbuiltDownloadFiles" key="asbuiltDownloadFiles" />,
          <Resource name="pendingRentals" key="pendingRentals" />,
          <Resource name="pendingTransfers" key="pendingTransfers" />,
          <Resource
            name="pendingAlternateRegists"
            key="pendingAlternateRegists"
          />,
          <Resource name="panelstatus" key="panelstatus" />,
          <Resource name="pointavailability" key="pointavailability" />,
          <Resource name="startremote" key="startremote" />,
          <Resource name="retrofitShareFiles" key="retrofitShareFiles" />,
          <Resource name="retrofitConfigs" key="retrofitConfigs" />,
          <Resource name="retrofitKitInfos" key="retrofitKitInfos" />,
          <Resource name="retrofitAccuracies" key="retrofitAccuracies" />,
          <Resource name="retrofitCalibs" key="retrofitCalibs" />,
          <Resource name="retrofitBasicSettings" key="retrofitBasicSettings" />,
          <Resource name="retrofitErrors" key="retrofitErrors" />,
          <Resource name="retrofitToposurveys" key="retrofitToposurveys" />,
          <Resource name="siteConfigures" key="siteConfigures" />,
          <Resource name="userConfigures" key="userConfigures" />,
          <Resource name="userPermissions" key="userPermissions" />,
          <Resource name="compactionMaterials" key="compactionMaterials" />,
          <Resource name="weathers" key="weathers" />,
          <Resource name="operators" key="operators" />,
          <Resource name="compactionWorkAreas" key="compactionWorkAreas" />,
          <Resource name="compactionLayers" key="compactionLayers" />,
          <Resource
            name="supportMode"
            key="supportMode"
            edit={SupportModeEdit}
          />,
          <Resource name="retrofitDistributors" key="retrofitDistributors" />,
          <Resource name="retrofitRegions" key="retrofitRegions" />,
          <Resource name="userNotices" key="userNotices" />,
          <Resource name="userNoticeStatus" key="userNoticeStatus" />,
          permissions.userRoles.includes('Firmware') ? (
            <Resource
              name="firmwares"
              key="firmwares"
              list={FirmwareList}
              create={FirmwareCreate}
              show={FirmwareShow}
            />
          ) : null,
          <Resource name="bucketShares" key="bucketShares" />,
          <Resource name="licenseAgreements" key="licenseAgreements" />,
          <Resource
            name="retrofitManageCorporation"
            key="retrofitManageCorporation"
          />,
          <Resource name="retrofitLicenses" key="retrofitLicenses" />,
          config.feature.useRetrofitLicense ? (
            <Resource
              name="retrofitCorporationLicenses"
              icon={LicenceIcon}
              list={RetrofitCorporationLicenseList}
              edit={RetrofitCorporationLicenseEdit}
              key="retrofitCorporationLicenses"
            />
          ) : null,
          <Resource name="customers" key="customers" />,
          <Resource
            name="retrofitNamePlateFiles"
            key="retrofitNamePlateFiles"
          />,
          <Resource name="coordinates" key="coordinates" />,
          <Resource name="retrofitByTasks" key="retrofitByTasks" />,
          <Resource
            name="retrofitAdminCorporationBulkActions"
            key="retrofitAdminCorporationBulkActions"
          />,
          <Resource name="termsConditions" key="termsConditions" />,
          <Resource
            name="termsConditionDocuments"
            key="termsConditionDocuments"
          />,
          <Resource name="geofenceAlerts" key="geofenceAlerts" />,
          <Resource name="geofenceCoordinates" key="geofenceCoordinates" />,
          <Resource
            name="retrofitAlternateRegists"
            key="retrofitAlternateRegists"
            list={RetrofitAlternateRegistList}
            icon={RetrofitAlternateRegistIcon}
          />,
          <Resource
            name="retrofitOwnerRegistCorporations"
            key="retrofitOwnerRegistCorporations"
          />,
          <Resource
            name="retrofitOwnerChangeCorporations"
            key="retrofitOwnerChangeCorporations"
          />,
          <Resource
            name="ntrips"
            key="ntrips"
            list={RetrofitBasicInfoTab}
            edit={NtripEdit}
          />,
          <Resource name="ntripsUnusedTotal" key="ntripsUnusedTotal" />,
          <Resource name="retrofitSendbacks" key="retrofitSendbacks" />,
          <Resource name="fileStorages" key="fileStorages" />,
        ];
      }}
    </Admin>
  );
};

export default App;
