import { DataProvider } from 'react-admin';
import { config } from '../utils';
import {
  fakeDataProvider,
  landlogDataProvider,
  adminDataProvider,
  remoteDataProvider,
  ntripUnusedTotalDataProvider,
  getPendingApprovals,
  getPendingRentals,
  getPendingTransfers,
  getPendingAlternateRegists,
  supportModeDataProvider,
  createLandlogSupportModeWrapper,
} from '.';
import { customerDataProvider } from './customers';
import { retrofitNamePlateDataProvider } from './retrofitNamePlate';
import { coordinateDataProvider } from './coordinate';
import { geofenceCoordinateDataProvider } from './geofenceCordinate';
import { asbuiltDownloadFilesDataprovider } from './asbuiltDownloadFiles';
import { CustomDataProvider } from './landlog/landlogDataProvider';

const getRealResource = (resource: string) => {
  if (resource.startsWith('retrofits')) return 'retrofits';
  if (resource.startsWith('buckets')) return 'buckets';
  if (resource.startsWith('projects')) return 'projects';
  if (resource.startsWith('files')) return 'files';
  if (resource.startsWith('asbuilts')) return 'asbuilts';
  if (resource.startsWith('geofences')) return 'geofences';
  if (resource.startsWith('extensionarms')) return 'extensionarms';
  if (resource.startsWith('retrofitBasicInfos')) return 'retrofits';
  if (resource.startsWith('compactionLayers')) return 'compactionLayers';
  if (resource.startsWith('assignments')) return 'assignments';
  return resource;
};

const getResourceDataProvider = (resource: string) => {
  const landlog = config.api.landlog.useFake
    ? fakeDataProvider
    : landlogDataProvider;
  const admin = config.api.admin.useFake ? fakeDataProvider : adminDataProvider;
  const remote = config.api.remote.useFake
    ? fakeDataProvider
    : remoteDataProvider;
  switch (resource) {
    case 'accounts':
    case 'corporations':
    case 'fileStorages':
    case 'licenses':
    case 'sites':
    case 'users':
      return createLandlogSupportModeWrapper(landlog, supportModeDataProvider);
    case 'panelstatus':
    case 'pointavailability':
    case 'startremote':
      return remote;
    case 'pendingApprovals':
      return getPendingApprovals();
    case 'pendingRentals':
      return getPendingRentals();
    case 'pendingTransfers':
      return getPendingTransfers();
    case 'pendingAlternateRegists':
      return getPendingAlternateRegists();
    case 'supportMode':
      return supportModeDataProvider;
    case 'customers':
      if (config.api.admin.useFake) {
        return fakeDataProvider;
      }
      return customerDataProvider(
        admin,
        createLandlogSupportModeWrapper(landlog, supportModeDataProvider),
      );
    case 'retrofitNamePlateFiles':
      if (config.api.admin.useFake) {
        return fakeDataProvider;
      }
      return retrofitNamePlateDataProvider();
    case 'coordinates':
      if (config.api.admin.useFake) {
        return fakeDataProvider;
      }
      return coordinateDataProvider();
    case 'geofenceCoordinates':
      if (config.api.admin.useFake) {
        return fakeDataProvider;
      }
      return geofenceCoordinateDataProvider();
    case 'asbuiltDownloadFiles':
      if (config.api.admin.useFake) {
        return fakeDataProvider;
      }
      return asbuiltDownloadFilesDataprovider();
    case 'ntripsUnusedTotal':
      if (config.api.admin.useFake) {
        return fakeDataProvider;
      }
      return ntripUnusedTotalDataProvider;
    default:
      return admin;
  }
};

const invoke = <T extends keyof DataProvider>(
  action: T,
  resource: string,
  params: Parameters<DataProvider[T]>[1],
): ReturnType<DataProvider[T]> => {
  if (config.api.trace) {
    console.log('start:', action, 'resource:', resource, 'params:', params);
  }
  const realResource = getRealResource(resource);
  const dataProvider = getResourceDataProvider(realResource);
  const result = dataProvider[action](realResource, params);
  if (config.api.trace) {
    (result as Promise<any>)
      .then(response =>
        console.log(
          'end:',
          action,
          'resource:',
          resource,
          'response:',
          response,
        ),
      )
      .catch(error =>
        console.log('error:', action, 'resource:', resource, 'error:', error),
      );
  }
  return result;
};

const getDataProvider = (): CustomDataProvider => ({
  getList: (resource, params): any => invoke('getList', resource, params),
  getOne: (resource, params): any => invoke('getOne', resource, params),
  getOneAllowedError: (resource, params): any =>
    invoke('getOne', resource, params),
  getMany: (resource, params): any => invoke('getMany', resource, params),
  getManyReference: (resource, params): any =>
    invoke('getManyReference', resource, params),
  update: (resource, params): any => invoke('update', resource, params),
  updateMany: (resource, params) => invoke('updateMany', resource, params),
  create: (resource, params): any => invoke('create', resource, params),
  delete: (resource, params): any => invoke('delete', resource, params),
  deleteMany: (resource, params) => invoke('deleteMany', resource, params),
});
export default getDataProvider;
