import { GetListParams, GetListResult } from 'react-admin';
import { omitBy, isUndefined, isArray, keys } from 'lodash';
import {
  LandLogListSitesProps,
  landlogListSites,
  SiteData,
  landlogSiteConvert,
} from '..';

export default async (params: GetListParams): Promise<GetListResult> => {
  const { corporationId, q, status, ...rest } = params.filter;
  const { page, perPage } = params.pagination;
  const { field, order } = params.sort;
  let setStatus = status;

  if (keys(rest).length > 0) {
    console.error(`Filters are not supported in Get Site List: ${rest} `);
  }
  if (corporationId && isArray(corporationId)) {
    // APIが対応していない。
    console.error(`Can't specify an array: ${corporationId} `);
  }
  if (status) {
    if (isArray(status)) {
      // statusを小文字変換し、カンマ区切りにする
      const editStatus = status.map(item => item.toLowerCase());
      setStatus = editStatus.join(',');
    } else {
      setStatus = status.toLowerCase();
    }
  }
  if (field && field !== 'name') {
    // APIはname以外のソートに対応していない
    console.error(`Unsupported sort field: ${field} `);
  }

  // APIの実行
  const props: LandLogListSitesProps = {
    corporation_id: corporationId,
    name: q,
    status: setStatus,
    offset: (page - 1) * perPage,
    limit: perPage,
    order_by: field === 'name' ? field : undefined,
    order: order && order === 'ASC' ? 'asc' : 'desc',
  };
  const { sites, total } = await landlogListSites(omitBy(props, isUndefined));
  const data: SiteData[] = sites.map(site => landlogSiteConvert(site));
  return { total, data };
};
