import React from 'react';
import {
  Breadcrumbs,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { ScGroupwareFileBreadCrumbs } from './FilePickerDialog';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      fontSize: 16,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  }),
);

export interface FilePickerBreadcrumbProps {
  breadcrumbs: ScGroupwareFileBreadCrumbs[];
  onClickBreadcumbs: (value: ScGroupwareFileBreadCrumbs, index: number) => void;
}

const FilePickerBreadcrumb: React.FC<FilePickerBreadcrumbProps> = ({
  breadcrumbs,
  onClickBreadcumbs,
}) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {breadcrumbs.map((path, index) => (
        <Typography
          className={classes.content}
          color="textPrimary"
          key={index}
          onClick={() => onClickBreadcumbs(path, index)}
        >
          {translate(path.name, { _: path.name })}
        </Typography>
      ))}
    </Breadcrumbs>
  );
};

export default FilePickerBreadcrumb;
