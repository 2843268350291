import React, { useCallback, useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { InputProps, useLoading, useUnselectAll } from 'react-admin';
import { useForm } from 'react-final-form';
import { FilePickerDialog } from '.';
import { Button, DialogOKOnly } from '../..';
import { styles } from '../../../theme';
import { FileStorageData } from '../../../dataProvider';
import { useResource } from '../../../hooks';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      ...styles.iconLightGrayButton,
      marginLeft: 148,
      marginBottom: 4,
      fontSize: 14,
      width: 200,
      '& > span > span': {
        padding: 0,
      },
    },
  }),
);

interface Props {
  resourceOwnerId: string;
}

const FilePickerDialogPopupButton: React.FC<InputProps<Props>> = ({
  resourceOwnerId,
}) => {
  const classes = useStyles();
  const resource = useResource('fileStorages');
  const form = useForm();
  const loading = useLoading();
  const base = 'admin.dialog.filePicker.undefinedResourceOwner';
  const errorMessage = [`${base}.message1`, `${base}.message2`];
  const [open, setOpen] = useState(false);
  const [isfirstLoadingCompleted, setIsfirstLoadingCompleted] = useState(false);
  const unselectAll = useUnselectAll(resource);

  useEffect(() => {
    if (isfirstLoadingCompleted) return;
    if (!loading) {
      setIsfirstLoadingCompleted(true);
    }
  }, [loading, isfirstLoadingCompleted]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleHide = useCallback(() => {
    setOpen(false);
    unselectAll(resource);
  }, [setOpen, unselectAll, resource]);

  const handleAddScGroupwareFile = useCallback(
    (records: any) => {
      const existingFiles = form.getFieldState('file')?.value || [];
      const blobFile = new Blob([JSON.stringify(records)], {
        type: 'application/json',
      });
      const selectedFiles = records.map((file: FileStorageData) => ({
        src: URL.createObjectURL(blobFile),
        title: file.name,
        fileInfo: file,
      }));

      form.batch(() => {
        form.change('file', [...existingFiles, ...selectedFiles]);
      });
    },
    [form],
  );

  return (
    <>
      <Button
        className={classes.button}
        label="admin.dialog.filePicker.buttonLabel"
        data-testid="filePickerDialogPopupButton"
        onClick={handleClick}
        disabled={loading && !isfirstLoadingCompleted}
      />
      {resourceOwnerId ? (
        <FilePickerDialog
          open={open}
          onClose={handleHide}
          resourceOwnerId={resourceOwnerId}
          handleAddScGroupwareFile={handleAddScGroupwareFile}
          loading={loading}
        />
      ) : (
        <DialogOKOnly
          open={open}
          onClose={handleHide}
          title=""
          messages={errorMessage}
        />
      )}
    </>
  );
};

FilePickerDialogPopupButton.displayName = 'FilePickerDialogPopupButton';
export default FilePickerDialogPopupButton;
