// for pl
// ポーランド語
import raMessage from 'ra-language-polish';
import en from './en';

const { ra } = raMessage;
const message: typeof en = {
  ra: {
    ...ra,
    input: {
      ...ra.input,
      password: {
        toggle_visible: '',
        toggle_hidden: '',
      },
    },
    navigation: {
      ...ra.navigation,
      next: '',
      prev: '',
      no_results: 'Nie znaleziono',
    },
    action: {
      ...ra.action,
      add_filter: '',
      confirm: 'OK',
      move_up: '',
      move_down: '',
      select_all: '',
      select_row: '',
    },
    message: {
      ...ra.message,
      bulk_delete_title: '',
      bulk_delete_content: 'Czy chcesz usunąć %{name}?',
    },
  },
  admin: {
    title: {
      normal: 'Smart Construction Pilot',
      split1: 'Smart Construction',
      split2: 'Pilot',
    },
    copyright: 'Copyright © EARTHBRAIN Ltd. All rights reserved.',
    approval: 'Oczekiwanie na zatwierdzenie',
    usermenu: {
      userNotice: 'Powiadomienie',
      terms: 'WARUNKI USŁUGI',
      manual: 'Instrukcja',
      downloads: 'Pobierz',
    },
    breadcrumbs: {
      details: 'Szczegóły',
      retrofitEdit: 'Szczegóły',
      taskCreate: 'Utwórz zadanie',
      taskEdit: 'Szczegóły zadania',
      geofenceCreate: 'Utwórz',
      geofenceEdit: 'Edytuj',
      geofenceAlertList: 'Alert Geofence',
      geofenceAlertShow: 'Szczegóły',
      compactionWorkAreaCreate: 'Dodaj obszary robót zagęszczających',
      compactionWorkAreaShow: 'Obszary robót zagęszczania',
      compactionWorkAreaEdit: 'Edytuj obszary pracy zagęszczania',
      compactionLayerCreate: 'Utwórz warstwy zagęszczania',
      compactionLayerEdit: 'Edytuj warstwy zagęszczania',
      supportMode: 'Tryb wsparcia',
      retrofitManage: 'Rejestracja kontrolera',
      retrofitManageAdmin: 'Rejestracja kontrolera (Admin)',
      retrofitNamePlateFiles: 'Rejestracja kontrolera',
      retrofitAlternateRegistCreate: 'Rejestracja proxy kontrolera',
      retrofitAlternateRegistEdit: 'Szczegóły',
      retrofitManageAdminBulkActionCreate: 'Rejestracja',
      terms: 'WARUNKI USŁUGI',
      downloads: 'Pobierz',
    },
    pages: {
      siteList: 'Lista miejsc budowy',
      siteRetrofitCreate: 'Dodaj maszynę do placu budowy',
      siteRetrofitEdit: '',
      siteBucketCreate: 'Dodaj kubeł do placu budowy',
      siteBucketEdit: 'Szczegóły wiaderka',
      siteProjectEdit: 'Szczegóły projektu',
      siteProjectCreate: 'Rejestracja plików projektowych',
      siteExtensionarmCreate: 'Dodaj przedłużacz do placu budowy',
      taskCreate: 'Utwórz zadanie',
      taskEdit: 'Szczegóły zadania',
      geofenceAlertList: 'Alert Geofence',
      geofenceAlertShow: 'Szczegóły alarmu geofence',
      geofenceCreate: 'Utwórz geofence',
      geofenceEdit: 'Edytuj geofence',
      compactionWorkAreaCreate: 'Dodaj obszary robót zagęszczających',
      compactionLayerCreate: 'Utwórz warstwy zagęszczania',
      compactionLayerEdit: 'Edytuj warstwy zagęszczania',
      siteFileEdit: 'Szczegóły',
      siteFileCreate: 'Dodaj plik do placu budowy',
      retrofitList: 'Lista maszyn',
      retrofitEdit: '',
      retrofitRentalCreate: 'Rejestracja przypisania',
      retrofitTransferCreate: 'Rejestracja transferu',
      retrofitSendBack: 'Wyślij ponownie rejestrację',
      bucketList: 'Lista wiader',
      bucketEdit: 'Szczegóły wiaderka',
      bucketCreate: 'Rejestracja wiaderka',
      bucketRentalCreate: 'Dzielenie się wiaderkiem',
      extensionarmList: 'Lista wydłużenie ramię',
      extensionarmEdit: 'Szczegóły wydłużenie ramię',
      extensionarmShare: 'Udostępnianie wydłużenie ramię',
      retrofitBulkActionList: 'Lista rejestracji zbiorowej',
      retrofitBulkActionCreate: 'Rejestracja zbiorowa',
      retrofitBasicInfoList: 'Lista kontrolerów',
      retrofitBasicInfoCreate: 'Rejestracja kontrolera',
      retrofitBasicInfoEdit: 'Aktualizacja kontrolera',
      gnssSettingList: 'Lista ustawień Ntrip',
      gnssSettingCreate: 'Utwórz ustawienie Ntrip',
      gnssSettingAdminCreate: 'Utwórz ustawienie Ntrip',
      gnssSettingOwnerCreate: 'Utwórz ustawienie Ntrip',
      gnssSettingEdit: 'Edytuj ustawienie Ntrip',
      gnssSettingAdminEdit: 'Edytuj ustawienie Ntrip',
      gnssSettingOwnerEdit: 'Edytuj ustawienie Ntrip',
      approvalList: 'Lista maszyn',
      retrofitRentalEdit: 'Szczegóły przypisania',
      retrofitTransferEdit: 'Szczegóły transferu',
      asbuiltsCreate: 'Pozyskać wynik budowy',
      asbuiltsShow: 'Historia Asbuild',
      asbuiltsRetrofitCreate: 'Pozyskać wynik budowy',
      retrofitConfigList: 'Informacje konfiguracyjne',
      retrofitKitInfoList: 'Informacje o sterowniku',
      retrofitAccuraciesList: 'Wynik kontroli dokładności',
      retrofitAccuraciesShow: 'Szczegóły',
      retrofitCalibaList: 'Informacje o kalibracji',
      retrofitCalibInfo: 'Szczegóły informacji o kalibracji maszyny',
      retrofitBasicSettingList: 'Podstawowe ustawienia',
      retrofitErrorList: 'Informacje o błędzie',
      retrofitErrorShow: 'Szczegóły',
      retrofitTopographicSurveyList: 'Lista pomiarów topografii',
      retrofitTopographicSurveyShow: 'Szczegóły pomiaru topograficznego',
      retrofitShareFileList: 'Dziennik aplikacji',
      retrofitCorporationLicenseList: 'Lista licencji',
      retrofitCorporationLicenseEdit: 'Szczegóły licencji',
      CompactionLayerList: 'Warstwy zagęszczania',
      userNoticeList: 'Lista powiadomień',
      userNoticeCreate: 'Dodaj powiadomienie',
      userNoticeEdit: 'Zaktualizuj powiadomienie',
      userNoticeDrawer: 'Powiadomienie',
      retrofitAlternateRegists: 'Lista rejestracji proxy kontrolera',
      firmwareList: 'Lista oprogramowania',
      firmwareShow: 'Szczegóły oprogramowania',
      firmwareCreate: 'Dodaj oprogramowanie sprzętowe',
      retrofitManage: 'Tekst',
      retrofitNamePlateFiles: 'Zdjęcie',
      retrofitManageAdminBulkAction: 'Rejestracja zbiorowa',
      retrofitManageAdminBulkActionCreate: 'Rejestracja zbiorowa',
      retrofitAlternateRegistCreate: 'Rejestracja proxy kontrolera',
      retrofitAlternateRegistEdit: 'Szczegóły rejestracji proxy kontrolera',
      retrofitAlternateRegistCreateText: 'Tekst',
      retrofitAlternateRegistCreatePhoto: 'Zdjęcie',
      ntripList: 'Informacje Ntrip',
      ntripEdit: 'Zarządzanie Ntrip',
    },
    label: {
      login: 'Zaloguj się',
      selected: 'Wybrane (%{length} przypadki)',
      listNoResults: 'Nie znaleziono danych',
      maintenance: {
        title: 'Obecnie trwa konserwacja.',
        message: `Tymczasowo wstrzymaliśmy usługi z powodu konserwacji.
      Przepraszamy za utrudnienia, prosimy o chwilę cierpliwości.`,
      },
      fileInput: {
        project: 'xml, tp3, tn3, ln3, gc3, dxf, ttm, svd, dsz, cal',
        bucket: 'bk3',
        csv: 'csv',
        pdf: 'pdf',
        namePlate: 'jpg, png',
        plaseholder: 'Upuść lub wybierz plik(i) (%{fileTypes}) do przesłania.',
        maxFileNum:
          'Maksymalna liczba kontrolerów, które można zarejestrować jednocześnie, to pięć.',
      },
      nosetting: 'Brak ustawienia',
      siteRetrofitList: {
        remoteSupport: 'Pomoc zdalna',
      },
      retrofitEdit: {
        breadcrumbs: 'Szczegóły',
        belongsDetails: '',
        ntripSettingInfo: 'Informacje o ustawieniach Ntrip',
        machineryDetails: 'Maszyny',
        retrofitDetails: 'Sterownik',
        basicInfo: 'Informacje podstawowe',
        controller: 'Sterownik',
        reciverMain: 'Odbiornik główny GNSS',
        reciverSub: 'Odbiornik podrz. GNSS',
        retrofitstatus: 'Status sterownika',
        license: 'Licencja',
        licenseId: 'Id. licencji',
        expiredDate: 'Data ważności',
      },
      retrofitRental: {
        rentalDetails: 'Szczegóły przypisania',
        rentalInfo: 'Szczegóły przypisania',
        lending: 'Przypisanie',
        return: 'Powrót',
        lendingDestination: 'Przypisane przedsiębiorstwo',
        lendingCompany: 'Nazwa firmy',
        lendingEmail: 'E-mail',
        companyName: 'Nazwa firmy',
      },
      retorfitTransfer: {
        tab: 'Transfer',
        transferInfo: 'Szczegóły transferu',
        transferButton: 'Transfer',
        transferEditButton: 'Edytuj transfer',
      },
      siteConfigureEdit: {
        onButton: 'WŁ.',
        offButton: 'WYŁ.',
      },
      approvals: {
        rentalTab: 'Przypisanie',
        transferTab: 'Transfer',
      },
      buckets: {
        bucketTab: 'Łyżka',
        extensionarmTab: 'Wydłużenie Ramię',
        extensionarmFile: 'Plik wydłużenie ramię',
        shareTo: 'Udostępnij do',
      },
      bucketRental: {
        corporation: 'Udostępnij do',
        bucketType: 'Typ',
        bk3File: 'Plik BK3',
      },
      retrofitBasic: {
        basicInfoTab: 'Informacje podstawowe',
        bulkActionTab: 'Rejestracja zbiorowa',
        ntripTab: 'Zarządzanie Ntrip',
      },
      retrofitSendbacks: {
        retrofitSendbackInfo: 'Wyślij z powrotem',
        initialize: 'Inicjowanie',
      },
      retrofitBasicInfos: {
        bulkRegistrationTitle: 'Aktualizacja zbiorowa',
        retrofitDistributor: 'Dystrybutor',
        retrofitRegion: 'Region',
        corporationId: 'ID firmy',
        corporationName: 'Nazwa firmy',
      },
      retrofitBasicInfoEdit: {
        corporationId: 'ID firmy jest w użyciu',
        corporationName: 'Firma jest w użyciu',
      },
      gnssSettingsEdit: {
        ownerCorporationName: 'Nazwa firmy',
      },
      terms: {
        title: 'WARUNKI USŁUGI',
        message: 'Proszę kliknij poniższy link, aby przejrzeć Regulamin.',
        confirmMessage1:
          'Aby korzystać z Smart Construction Pilot, musisz zaakceptować następujące dokumenty.',
        confirmMessage2:
          'Jeśli się nie zgadzasz, nie będziesz mógł używać Smart Construction Pilot.',
        select: 'Proszę wybrać swoją region.',
      },
      downloads: {
        title: 'Konwerter plików projektowych',
        message:
          'Jest to samodzielna aplikacja, która tworzy plik projektu z plików projektu.',
        button: 'Pobierz',
      },
      retrofitBasicInfoCreate: {
        uuid: 'Automatyczna numeracja',
      },
      retrofitBulkActions: {
        uploadFileDownload: 'Pobierz',
        resultFileDownload: 'Pobierz',
      },
      tasks: {
        coordinate: 'Współrzędne (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
      },
      compactionWorkAreas: {
        blockSize: 'Rozmiar bloku (%{unit})',
      },
      compactionLayers: {
        thickness: 'Grubość (%{unit})',
      },
      pendingRentals: {
        breadcrumbs: 'Szczegóły',
        rentalDetail: 'Szczegóły przypisania',
      },
      pendingTransfers: {
        breadcrumbs: 'Szczegóły',
        transferDetail: 'Szczegóły transferu',
      },
      pendingAlternateRegists: {
        breadcrumbs: 'Szczegóły',
        alternateRegistDetail: 'Szczegóły rejestracji proxy kontrolera',
      },
      supportMode: {
        retrofit: 'Maszyny',
        optional: 'Opcjonalny',
      },
      retrofitList: {
        assignment: 'Przypisanie',
        license: 'Licencja',
      },
      userNotices: {
        languageDetails: 'Zawartość powiadomienia',
        language: 'Język',
        languageEn: 'English',
        languageJa: 'Japoński',
        languageFr: 'Francuski',
        languageDe: 'Niemiecki',
        languageEs: 'Hiszpański',
        Information: 'Powiadomienie',
        Maintenance: 'Konserwacja',
        emergency: 'Nagły wypadek',
        important: 'Svarbu',
      },
      userNoticeDrawer: {
        more: '',
        close: '',
      },
      retrofitsManage: {
        menuTitle: 'Rejestracja kontrolera',
        corporationName: 'Nazwa firmy',
        basicInfoSerialNumber: 'Numer seryjny',
        confirmInput: 'Wprowadź ponownie numer seryjny',
      },
      retrofitNamePlateFiles: {
        serialNumber: 'Numer seryjny',
        status: 'Status',
        imageFile: 'Obraz',
        message1:
          'Proszę zrobić zdjęcie numeru seryjnego kontrolera lub kodu QR, które jest klarowne i niezamazane.',
        message2: 'Obrazy muszą być mniejsze niż %{mbyte} MB.',
        errormessage:
          'Maksymalna liczba kontrolerów, które można zarejestrować jednocześnie, to pięć.',
      },
      retrofitCorporationLicenses: {
        licenseStatusChoices: {
          enable: 'Ważny',
          disable: 'Niepoprawny',
          unLicense: 'Nieprzydzielony',
        },
        machine: 'Maszyny',
        before: 'Przed',
        after: 'Po',
      },
      geofences: {
        project: 'Projekt',
        projectSourceLayer: 'Warstwa',
        rgb: 'Kolor(RGB)',
        select: 'Geofence wyboru',
        coordinates: 'Współrzędne',
        coordinatesLabel: 'Współrzędne (N,E)',
        unitLength: 'Jednostka:%{unit}',
      },
      geofenceDialog: {
        coordinate: 'Współrzędne (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
        radius: 'Promień',
      },
      retrofitAlternateRegists: {
        menuTitle: 'Rejestracja proxy kontrolera',
        corporationName: 'Nazwa firmy',
        email: 'E-mail',
        basicInfoSerialNumber: 'Numer seryjny',
        confirmInput: 'Wprowadź ponownie numer seryjny',
      },
      ntripList: {
        unusedTotal: 'Nie używana razem',
      },
      calibrationDetail: {
        breadcrumbs: 'Szczegóły',
        machineInfo: 'Informacje o maszynie',
        machineGeometryInfo: 'Informacje o geometrii maszyny',
        bodyCenterToBoomFootPin: '',
        imuMountInfo: 'Informacje o zamont. IFU',
        gnssMountInfo: '',
        bodyCenterToGnssMain: 'Informacje o zamon. główn. GNSS',
        bodyCenterToGnssSub: 'Informacje o zamon. podrz. GNSS',
        extendedModel: 'Model rozszerzony',
        measuredValues: 'Informacje o kalibracji',
      },
      siteList: {
        statusChoices: {
          completed: 'Zakończone',
          waiting: 'Przed rozpoczęciem budowy',
          working: 'W budowie',
          waitingworking: 'Przed rozpoczęciem budowy/W budowie',
        },
      },
    },
    errorInfo: {
      projects: {
        system_scapi_error: 'Błąd konwersji danych',
        system_localization_quantity_error: `Nie można zarejestrować wielu plików zawierających informacje o lokalizacji.
      Zarejestruj tylko jeden plik zawierający informacje o lokalizacji.`,
        system_detect_0byte_file_error: `Rejestracja pliku na serwerze nie powiodła się z powodu błędu komunikacji.
      Zarejestruj plik ponownie.`,
        system_batch_error: 'Błąd systemu (P001)',
        system_parameter_error: 'Błąd systemu (P002)',
        system_parameterfile_notfound: 'Błąd systemu (P003)',
        system_exception: 'Błąd systemu (P004)',
        system_logfile_error: 'Błąd systemu (P005)',
        system_parameterfile_parameter_error: 'Błąd systemu (P006)',
        desing_localization_error: `Brak informacji o lokalizacji.
      Proszę zarejestrować jeden z poniższych plików:

      ・Plik GC3
      ・Plik TP3 zawierający punkty kontrolne
      ・Plik CAL`,
        desing_no_support_dxf_error:
          'Plik(i) DXF nie mógł/mogły zostać odczytany(e), ponieważ jego/ich wersja jest starsza niż AutoCAD2000.',
        desing_tile_error: 'Nie udało się utworzyć pliku projektu.',
        desing_outline_error:
          'Nie udało się utworzyć zewnętrznej linii granicznej.',
        desing_area_length_error:
          'Proces został przerwany, ponieważ obszar projektu jest zbyt duży. Sprawdź, czy dane projektu i skala danych CAD są prawidłowe.',
        desing_layer_name_duplication_error: `Dane projektowe z zduplikowanymi nazwami warstw nie mogą być zarejestrowane.
      Proszę zmień nazwę warstwy projektu lub zarejestruj tak, aby istniał tylko jeden plik z tą samą nazwą warstwy projektu.`,
        targetFiles: '[Nazwa pliku]',
        targetLayers: '[Warstwa]',
        errorFiles: '・%{errorFile}',
      },
      tasks: {
        none: 'Brak',
        system_batch_error: 'Błąd systemu (T001)',
        system_parameter_error: 'Błąd systemu (T002)',
        system_parameterfile_notfound: 'Błąd systemu (T003)',
        system_exception: 'Błąd systemu (T004)',
        system_logfile_error: 'Błąd systemu (T005)',
        system_basefile_notfound: 'Błąd systemu (T006)',
        system_prjfile_multi: 'Błąd systemu (T007)',
        system_basefile_read_error: 'Błąd systemu (T008)',
        system_target_layer_notfound: 'Błąd systemu (T009)',
        system_target_layer_duplication: 'Taka nazwa warstwy już istnieje.',
        system_taskarea_error: 'Nieprawidłowy obszar zadania',
        system_taskarea_design_notfound:
          'Brak danych dla pliku projektu w obszarze zadania (TIN, linie, punkty).',
        system_tile_error: 'Nie udało się utworzyć pliku projektu.',
        system_outline_error:
          'Nie udało się utworzyć zewnętrznej linii granicznej.',
        system_parameterfile_error: 'Błąd systemu',
      },
      retrofitError: {
        1000: 'Nie wykryto danych UDP ze sterownika',
        2000: 'Nie wykryto IMU nadwozia',
        2001: 'Błąd sprzętowy IMU nadwozia',
        2002: 'Błąd algorytmu IMU nadwozia',
        2003: 'Błąd danych IMU nadwozia',
        2004: 'Błąd inicjowania IMU nadwozia',
        2100: 'Nie wykryto IMU wysięgnika',
        2101: 'Błąd sprzętowy IMU wysięgnika',
        2102: 'Błąd algorytmu IMU wysięgnika',
        2103: 'Błąd danych IMU wysięgnika',
        2104: 'Błąd inicjowania IMU wysięgnika',
        2200: 'Nie wykryto IMU ramienia',
        2201: 'Błąd sprzętowy IMU ramienia',
        2202: 'Błąd algorytmu IMU ramienia',
        2203: 'Błąd danych IMU ramienia',
        2204: 'Błąd inicjowania IMU ramienia',
        2300: 'Nie wykryto IMU łyżki',
        2301: 'Błąd sprzętowy IMU łyżki',
        2302: 'Błąd algorytmu IMU łyżki',
        2303: 'Błąd danych IMU łyżki',
        2304: 'Błąd inicjowania IMU łyżki',
        2400: 'Nie wykryto IMU łyżki przechylnej',
        2401: 'Błąd sprzętowy IMU łyżki przechylnej',
        2402: 'Błąd algorytmu IMU łyżki przechylnej',
        2403: 'Błąd danych IMU łyżki przechylnej',
        2404: 'Błąd inicjowania IMU łyżki przechylnej',
        2500: 'Nie wykryto IMU 2. wysięgnika',
        2501: 'Błąd sprzętowy IMU 2. wysięgnika',
        2502: 'Błąd algorytmu IMU 2. wysięgnika',
        2503: 'Błąd danych IMU 2. wysięgnika',
        2504: 'Błąd inicjowania IMU 2. wysięgnika',
        2600: 'Niewykryty czujnik kąta obrotu',
        2601: 'Błąd systemu czujnika kąta obrotu',
        2602: 'Błąd sprzętowy czujnika kąta obrotu',
        2700: 'Niewykryty czujnik przesunięcia',
        2701: 'Błąd systemu czujnika przesunięcia',
        2702: 'Błąd sprzętowy czujnika przesunięcia',
        3000: 'Nie wykryto czołowego czujnika ciśnienia',
        3100: 'Nie wykryto dolnego czujnika ciśnienia',
        4000: 'Nie wykryto anteny GNSS',
        5000: 'Nie wykryto informacji o poprawkach',
        5001: 'Nie wykryto sygnału radioodbiornika',
      },
    },
    dialog: {
      menu: {
        confirmManualLink:
          'Zostaniesz przekierowany na inną stronę internetową. Czy wszystko jest w porządku?',
      },
      bucketEdit: {
        deleteConfirm: {
          messageTargetItem: 'Łyżka',
        },
      },
      retrofitBasicInfoList: {
        saveValidation: {
          message: 'Wymagane są ID firmy i nazwa firmy.',
        },
        save: {
          message1: 'ID/nazwa firmy nie mogą być zmienione, ponieważ obejmują',
          message2: 'maszyna(y) przypisana(y) do innego przedsiębiorstwa.',
          message3: '',
          message4: 'Proszę sprawdź następujące numery seryjne',
        },
      },
      retrofitBasicInfoEdit: {
        deleteConfirm: {
          messageTargetItem: 'Sterownik',
        },
        undeletable: {
          message: 'Kontroler jest używany i nie może być usunięty.',
        },
      },
      Ntrip: {
        save: {
          stop: 'Czy chcesz zatrzymać to ustawienie Ntrip?',
          delete: 'Czy chcesz usunąć ustawienie Ntrip?',
        },
      },
      siteRetrofitList: {
        title: 'Informacje dodatkowe',
        message: 'Okres budowy został zakończony.',
      },
      siteConfigureEdit: {
        title: {
          commonSetting: 'Ustawienia wspólne',
          siteSetting: 'Ustawienia miejsca budowy',
        },
        mapBackgroundColor: 'Kolor tła mapy',
        message:
          'Funkcja wykrywania kontaktu / powiadamiania o alertach geofencingu może nie działać prawidłowo w niektórych środowiskach i warunkach. Nie należy bezkrytycznie polegać na tej funkcji. Powinno się koniecznie zrozumieć jej działanie i warunki jej używania.',
      },
      geofenceList: {
        confirmDelete: {
          message: 'Czy chcesz usunąć ten geofence?',
        },
      },
      retrofitRentalCreate: {
        unableTieUpJobSite: {
          message1:
            'Urządzenie jest połączone z miejscem pracy i nie można go przypisać. Odłącz urządzenie od miejsca pracy.',
          message2: 'Proszę odłączyć maszynę od placu budowy.',
        },
        sameOwner: {
          message:
            'Urządzenie nie może zostać przypisane, ponieważ właścicielem jest Twoja firma.',
        },
        unableTransfer: {
          message:
            'Urządzenie oczekuje na zatwierdzenie transferu i nie może zostać zarejestrowane do przypisania.',
        },
        unableAlternateRegist: {
          message:
            'Urządzenie oczekuje na zatwierdzenie rejestracji Controller Proxy i nie może zostać zarejestrowane do przypisania.',
        },
      },
      retrofitTransferCreate: {
        messageSiteId:
          'Urządzenie jest powiązane z miejscem pracy i nie można go przenieść. Odłącz urządzenie od miejsca pracy.',
        messageRentaled: 'Urządzenie jest przypisane i nie można go przenieść.',
        messageLicense: 'Urządzenie ma licencję i nie można go przenieść.',
        pendingApprovals: {
          message1: 'Otrzymano żądanie transferu. Poczekaj na zatwierdzenie.',
          message2:
            'Jeśli masz jakiekolwiek pytania, skontaktuj się z nami za pomocą poniższego adresu URL.',
        },
      },
      retrofitSendBacks: {
        submit:
          'Czy chcesz odesłać kontroler i odziedziczyć ustawienia na kontrolerze docelowym?',
        deleteNtrip: 'Czy chcesz zainicjować zwrócony kontroler?',
        unableRegion:
          'Ten kontroler został wysłany za granicę. Jeśli jest to właściwy kontroler, zresetuj "Region" na null.',
        unableCorporation: {
          message1: 'Kontroler nie może zostać odesłany.',
          message2:
            'Jeśli uważasz, że to błąd, skontaktuj się ze swoim dealerem.',
        },
        retrofitRentaled:
          'Kontroler jest przypisany i nie może zostać odesłany.',
        tiedToSite:
          'Urządzenie jest powiązane z miejscem pracy i nie można go odesłać.',
      },
      retrofitAddToSiteAction: {
        title: 'Informacje dodatkowe',
        message:
          'Maszyna oczekuje na zatwierdzenie, więc nie można jej połączyć z miejscem pracy.',
      },
      retrofitAddToSiteActionTransfer: {
        title: 'Informacje dodatkowe',
        message:
          'Urządzenie jest przenoszone i nie można go połączyć z miejscem pracy.',
      },
      retrofitEdit: {
        unableSettingGnessInfo: {
          message:
            'Wybrane ustawienie Ntrip jest wysyłane do innego urządzenia.',
        },
        unableTransferInfo: {
          message: 'Aby przesłać, anuluj następujące ustawienia.',
          reasonSiteId: 'Plac budowy',
          reasonRentaled: 'Przypisanie',
          reasonLicense: 'Licencja',
        },
      },
      retrofitRentalEdit: {
        overDoubleRentalStartDate: {
          message1:
            'Przypisana firma obecnie przypisuje maszynę do innego przedsiębiorstwa. Data rozpoczęcia przypisania do innego przedsiębiorstwa to %{date}.',
          message2: 'Proszę wybierz datę zakończenia po %{date}.',
        },
        confirmTieUpJobsite: {
          message:
            'Urządzenie jest powiązane z miejscem pracy w przypisanej firmie. Czy chcesz zakończyć to przypisanie?',
        },
        confirmDoubleRental: {
          message:
            '"Przypisana firma obecnie przypisuje maszynę do innego przedsiębiorstwa. Przypisana firma obecnie przypisuje maszynę do innego przedsiębiorstwa. Ustawienie daty zakończenia kończy również przypisanie do innego przedsiębiorstwa. Czy chcesz ustawić datę zakończenia?"',
        },
        unapprovedSave: {
          message:
            'To zadanie nie zostało zatwierdzone i nie można go zapisać.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Czy chcesz usunąć to zadanie?',
        },
        approvedDelete: {
          title: '',
          message: 'To zadanie zostało zatwierdzone i nie można go usunąć.',
        },
      },
      retrofitTransferEdit: {
        unapprovedSave: {
          title: '',
          message:
            'Urządzenie jest obecnie przypisane i nie można go przenieść.',
        },
        approvedSave: {
          message: 'Urządzenie ma licencję i nie można go przenieść.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Czy chcesz usunąć ten transfer?',
        },
        approvedDelete: {
          title: '',
          message: 'Ten transfer został zatwierdzony i nie można go usunąć.',
        },
      },
      extensionarmEdit: {
        deleteConfirm: {
          messageTargetItem: 'Wydłużenie Ramię',
        },
      },
      projectFileUpload: {
        message:
          'Nie można zarejestrować wielu plików punktów kontrolnych (.gc3/.cal).',
      },
      projectEdit: {
        confirm: {
          message:
            'Po zaktualizowaniu projektu usunięte zostaną istniejące zadania. Czy chcesz zaktualizować?',
        },
      },
      projectConversionStatus: {
        title: 'Stan konwersji',
        button: 'OK',
      },
      taskConversionStatus: {
        title: 'Stan konwersji',
        button: 'OK',
      },
      taskList: {
        undeletable: {
          message:
            'Nie można usunąć wybranego zadania, ponieważ jest ono używane.',
        },
        unableToCreate: {
          message: 'Nie zarejestrowano danych projektowych.',
        },
        unableToDelete: {
          message1:
            'Zadania nie można usunąć, ponieważ status projektu i status zadania są następujące.',
          message2: 'Projekt: Konwertowanie',
          message3: 'Zadanie: Błąd',
        },
        unableToUpdate: {
          message1:
            'Zlecenia nie można edytować, ponieważ status projektu i status zlecenia nie są zgodne.',
          message2: 'Projekt: [Skonwertowany]',
          message3:
            'Zadanie: [Przenoszenie][Przeniesione][Nie udało się przenieść]',
        },
      },
      taskEdit: {
        deleteConfirm: {
          messageTargetItem: 'Zadanie',
        },
        unableToDelete: {
          message:
            'Nie można usunąć wybranego zadania, ponieważ jest ono używane.',
        },
      },
      taskMap: {
        crossingMessage: 'Obszar zlecenia jest przecięty.',
        sameValueMessage: 'Wprowadzono te same współrzędne.',
        areaZeroMessage: 'Nie ma obszaru dla obszaru zadań.',
        noPolygonMessage:
          'Zadania nie można zarejestrować, ponieważ nie zostało ono jeszcze zarejestrowane.',
      },
      compactionMaterials: {
        add: {
          title: 'Dodaj materiał',
        },
        update: {
          title: 'Zaktualizuj materiał',
        },
      },
      weathers: {
        add: {
          title: 'Dodaj pogodę',
        },
        update: {
          title: 'Zaktualizuj pogodę',
        },
      },
      operators: {
        add: {
          title: 'Dodaj operatora',
        },
        update: {
          title: 'Zaktualizuj operatora',
        },
      },
      compactionWorkAreas: {
        update: {
          message: 'Edytuj obszary pracy zagęszczania',
        },
        undeletable: {
          message:
            'Warstwa zagęszczenia w obszarze budowy jest używana i nie może być usunięta.',
        },
      },
      compactionLayers: {
        undeletable: {
          message: 'Warstwa zagęszczenia jest używana i nie może być usunięta.',
        },
        deleteConfirm: {
          messageTargetItem: 'Warstwa zagęszczania',
        },
      },
      gnssSettings: {
        unableSettingGnessInfo: {
          message: 'Inna konfiguracja Ntrip jest wysyłana do wybranej maszyny.',
        },
        confirmDelete: {
          message: 'Czy chcesz usunąć ustawienie Ntrip?',
        },
        confirmDeleteWithMachine: {
          message:
            'Urządzenie jest obecnie powiązane. Czy chcesz usunąć ustawienie Ntrip?',
        },
      },
      userNoticeEdit: {
        deleteConfirm: {
          messageTargetItem: 'Powiadomienie',
        },
      },
      retrofitCorporationLicenses: {
        confirm: {
          message: 'Ustaw licencję(-je) na następującą maszynę.',
        },
        changeDisable: {
          message:
            'Proszę zmień identyfikator licencji następującej maszyny budowlanej na prawidłowy.',
        },
        changeTemporary: {
          message:
            'Ustaw licencję(-je) na następującą maszynę. Obecna tymczasowa licencja zostanie unieważniona.',
        },
        errorAdd: {
          message:
            'Nie można zmienić licencji urządzenia, ponieważ ustawiono już licencję All.',
        },
        errorSameType: {
          message:
            'Nie można zmienić licencji urządzenia, ponieważ ten sam typ licencji został już ustawiony.',
        },
        errorUnlimited: {
          message:
            'Urządzenie ma już ustawioną licencję Unlimited, nie można ustawić innych licencji.',
        },
        addAll: {
          message:
            'Następujące urządzenie ma już ustawione licencje. Czy chcesz ustawić wszystkie licencje?',
        },
        compare: {
          message: 'Zmień licencję(-e) dla następującej maszyny.',
        },
        warning: {
          message:
            '%{retrofit} ma licencję %{id}. Czy chcesz odłączyć licencję?',
        },
        unableTransfer: {
          message:
            'Urządzenie jest przenoszone, więc nie można połączyć licencji.',
        },
      },
      retrofitNamePlateFiles: {
        error: {
          serial: 'Istnieją dane, które nie udało się zanalizować.',
        },
      },
      retrofitManageTab: {
        pendingApprovals: {
          message1:
            'Otrzymano żądanie rejestracji. Poczekaj na jego zatwierdzenie.',
          message2:
            'Jeśli masz jakiekolwiek pytania, skontaktuj się z nami za pomocą poniższego adresu URL.',
        },
        corporationIdCheck: {
          message1:
            'Kontroler został już zarejestrowany. Proszę sprawdź, szukając w maszynach.',
          message2:
            'Jeśli nie możesz znaleźć docelowego kontrolera na liście, skontaktuj się z nami za pomocą poniższego adresu URL.',
        },
        earthbrainIdCheck: {
          message1: 'Przepraszamy za utrudnienia',
          message2:
            'ale proszę o kontakt za pomocą poniższego adresu URL z następującymi informacjami.',
          message3: '-Numer seryjny kontrolera',
          message4: '-Nazwa maszyny do zarejestrowania (opcjonalnie)',
          message5: '-ID firmy do zarejestrowania',
        },
        invalidSerialNumber: {
          message1: 'Kontroler jest już używany przez inną firmę.',
          message2: 'Jeśli chcesz go zmienić z innych powodów,',
          message3:
            'Proszę skontaktuj się z nami pod poniższym adresem URL, podając następujące informacje.',
          message4: '-Numer seryjny kontrolera',
          message5: '-Nazwa maszyny do zarejestrowania (opcjonalnie)',
          message6: '-ID firmy do zarejestrowania',
          message7: '-Powód zmiany',
        },
        lendingRetrofits: {
          message1: 'Kontroler jest przypisany.',
          message2:
            'Proszę ponownie złożyć wniosek po zakończeniu przypisania w firmie przypisującej.',
          message3:
            'Jeśli masz jakiekolwiek pytania, skontaktuj się z nami za pomocą poniższego adresu URL.',
        },
        siteAttached: {
          message1: 'Kontroler jest powiązany z placem budowy.',
          message2:
            'Proszę ponownie złożyć wniosek po odłączeniu maszyny od placu budowy.',
          message3:
            'Jeśli masz jakiekolwiek pytania, skontaktuj się z nami za pomocą poniższego adresu URL.',
        },
        serialNumberExistError: {
          message1:
            'Jest to numer seryjny sterownika, który nie istnieje. Upewnij się, że wprowadzony numer seryjny sterownika jest prawidłowy.',
          message2:
            'Jeśli problem będzie się powtarzał, skontaktuj się z nami za pomocą poniższego adresu URL.',
        },
        ntripShortage: {
          message1:
            'Nie mogliśmy wydać twojego identyfikatora użytkownika Ntrip oraz hasła.',
          message2: 'Proszę skontaktuj się z nami pod poniższym adresem URL.',
        },
        serialnumberCheck: {
          message: 'Proszę sprawdź produkty o następujących numerach seryjnych',
        },
      },
      retrofitAlternateRegists: {
        deleteConfirm: {
          messageTargetItem: 'retrofitAlternateRegists',
        },
        saveValidation: {
          message: 'E-mail jest wymagany',
        },
        pendingApprovals: {
          message1:
            'Otrzymano wniosek o rejestrację. Proszę czekać, aż zostanie zatwierdzony.',
          message2:
            'Jeśli masz jakiekolwiek pytania, skontaktuj się z nami za pomocą poniższego adresu URL.',
        },
        corporationIdCheck: {
          message1:
            'Kontroler został już zarejestrowany. Proszę sprawdź, szukając w maszynach.',
          message2:
            'Jeśli nie możesz znaleźć docelowego kontrolera na liście, skontaktuj się z nami za pomocą poniższego adresu URL.',
        },
        earthbrainIdCheck: {
          message1: 'Przepraszamy za utrudnienia',
          message2:
            'ale proszę o kontakt za pomocą poniższego adresu URL z następującymi informacjami.',
          message3: '-Numer seryjny kontrolera',
          message4: '-Nazwa maszyny do zarejestrowania (opcjonalnie)',
          message5: '-ID firmy do zarejestrowania',
        },
        invalidSerialNumber: {
          message1: 'Kontroler jest już używany przez inną firmę.',
          message2: 'Jeśli chcesz go zmienić z innych powodów,',
          message3:
            'Proszę skontaktuj się z nami pod poniższym adresem URL, podając następujące informacje.',
          message4: '-Numer seryjny kontrolera',
          message5: '-Nazwa maszyny do zarejestrowania (opcjonalnie)',
          message6: '-ID firmy do zarejestrowania',
          message7: '-Powód zmiany',
        },
        lendingRetrofits: {
          message1: 'Kontroler jest przypisany.',
          message2:
            'Proszę ponownie złożyć wniosek po zakończeniu przypisania w firmie przypisującej.',
          message3:
            'Jeśli masz jakiekolwiek pytania, skontaktuj się z nami za pomocą poniższego adresu URL.',
        },
        siteAttached: {
          message1: 'Kontroler jest powiązany z placem budowy.',
          message2:
            'Proszę ponownie złożyć wniosek po odłączeniu maszyny od placu budowy.',
          message3:
            'Jeśli masz jakiekolwiek pytania, skontaktuj się z nami za pomocą poniższego adresu URL.',
        },
        serialNumberExistError: {
          message1:
            'Jest to numer seryjny sterownika, który nie istnieje. Upewnij się, że wprowadzony numer seryjny sterownika jest prawidłowy.',
          message2:
            'Jeśli problem będzie się powtarzał, skontaktuj się z nami za pomocą poniższego adresu URL.',
        },
        ntripShortage: {
          message1:
            'Nie mogliśmy wydać twojego identyfikatora użytkownika Ntrip oraz hasła.',
          message2: 'Proszę skontaktuj się z nami pod poniższym adresem URL.',
        },
        serialnumberCheck: {
          message: 'Proszę sprawdź produkty o następujących numerach seryjnych',
        },
      },
      pendingApprovals: {
        ownerCorporation: {
          message1: 'Ten kontroler jest już własnością firmy.',
          message2: 'Proszę skontaktować się z firmą złożoną wniosek.',
        },
        userCorporation: {
          message1:
            'Nie możesz zatwierdzić tego żądania, ponieważ już wypożyczasz ten kontroler.',
          message2: 'Proszę skontaktować się z firmą złożoną wniosek.',
        },
      },
      licensePurchaseNotice: {
        button: 'OK',
        message1: `Na miejscu używana jest maszyna bez licencji.
        Licencja jest wymagana do użytku na miejscu.
        Jeśli nie zakupiłeś licencji, kup ją w Smart Construction Marketplace.`,
        message2: `
        Jeśli wynajmujesz maszynę od innej firmy, poproś o licencję na maszynę ze źródła wynajmu.

        W razie jakichkolwiek pytań prosimy o sprawdzenie następującej strony pomocy technicznej.`,
      },
      siteBuriedStructureEdit: {
        title: 'Buried Structure',
      },
      filePicker: {
        title: 'Import From Groupware',
        breadcrumbHome: 'Home',
        selectedFile: 'Selected Files',
        buttonLabel: 'Import From Groupware',
        errorMessage: 'The selected file(s) do not exist in Groupware.',
        footer: 'and %{remainingCount} more',
        undefinedResourceOwner: {
          message1: 'Could not import the file(s) from Groupware.',
          message2:
            'Please confirm you are registered as a member of the site.',
        },
      },
    },
    tootip: {
      retrofitShareFileList: 'Dziennik aplikacji',
      retrofitConfigList: 'Informacje konfiguracyjne',
      retrofitKitInfoList: 'Informacje o sterowniku',
      retrofitAccuraciesList: 'Wynik kontroli dokładności',
      retrofitCalibaList: 'Informacje o kalibracji',
      retrofitBasicSettingList: 'Ustawienia podstawowe',
      retrofitErrorList: 'Szczegóły błędu',
      retrofitTopographicSurveyList: 'Pomiar topografii',
      retrofitAsbuilts: 'Wyniki budowy',
    },
    actions: {
      ok: 'OK',
      add: 'Dodaj',
      create: 'Rejestracja',
      yes: 'Tak',
      no: 'Nie',
      confirmation: 'Potwierdź',
      save: 'ZAPISZ',
      cancel: 'Anuluj',
      required: 'Wymagane',
      addMachineToSite: 'Dodaj maszynę do placu budowy',
      addBucketToSite: 'Dodaj',
      deleteFromSite: 'Odłącz z miejsca pracy',
      removeLicense: 'Odłącz z maszyny',
      lendMachine: 'Przypisanie',
      transferMachine: 'Transfer',
      remoteSupport: 'Pomoc zdalna',
      lendBucket: 'Udostępnij',
      approval: 'Zatwierdzenie',
      connect: 'Połącz',
      creationInstructions: 'Pozyskać wynik budowy',
      csvDownload: 'Pobierz',
      retrofitBundleCreate: 'Rejestracja zbiorowa',
      termsAccept: 'Zgoda',
      termsDeny: 'Nie zgadzać się',
      bulkRegistration: 'Aktualizacja zbiorowa',
      bulkRegistrationOK: 'OK',
      compactionItems: 'Elementy zagęszczania',
      addLanguage: 'Dodaj język',
    },
    message: {
      error: 'Wystąpił błąd',
      confirm: 'Czy jest w porządku?',
      fileUpload: 'Przeciągnij i upuść pliki tutaj',
      success: 'Rejestracja została zakończona',
      approved: 'Zatwierdzenie zostało ukończone',
      deleteConfirmTitle: '',
      deleteConfirmContent: 'Czy chcesz usunąć %{title}?',
      bulkDeleteConfirmContent: 'Czy chcesz usunąć %{title}?',
      disconnectFromSite: 'Czy chcesz odłączyć się od miejsca pracy?',
      saveConfirmContent: 'Potwierdź',
      removeLicense: 'Czy chcesz odłączyć się od maszyny?',
      task: {
        crossingMessage: 'Obszar zlecenia jest przecięty.',
        sameValueMessage: 'Wprowadzono te same współrzędne.',
        areaZeroMessage: 'Nie ma obszaru dla obszaru zadań.',
        invalidPolygonMessage: 'Wprowadzono te same współrzędne.',
      },
      geofence: {
        crossingMessage: 'Obszar geofence jest przecięty.',
        sameValueMessage: 'Wprowadzono te same współrzędne.',
        areaZeroMessage: 'Nie ma obszaru dla obszaru geofence.',
      },
      licenseError:
        'Nie masz licencji na aplikację Smart Construction Pilot(Web App).',
      retrofitsManage: {
        serialNumberMatchError: 'Numer seryjny nie pasuje',
        serialNumberInputError: 'Numer seryjny nie istnieje',
        invalidAccess:
          'Nie masz uprawnień do wyświetlania tej strony, wróć do strony głównej.',
      },
      retrofitsAlternateRegistText: {
        serialNumberMatchError: 'Numer seryjny nie pasuje',
        serialNumberInputError: 'Numer seryjny nie istnieje',
        retrofitRentaled:
          'Informacje o firmie nie mogą być aktualizowane, gdy kontroler jest w trakcie zadania.',
        invalidCorporation:
          'Ponieważ Twoja firma nie jest dystrybutorem, nie możesz dokonać rejestracji kontrolera za pośrednictwem proxy.',
      },
    },
    validation: {
      startDate: 'Data rozpoczęcia',
      endDate: 'Data zakończenia',
      invalidDateFormat: 'Format daty jest nieprawidłowy.',
      invalidDateTimeFormat: 'Format daty i czasu jest nieprawidłowy.',
      duplicateProject: 'Nazwa projektu jest zduplikowana',
      incorrectBeforeDate: '%{target} musi być ustawione przed %{before}.',
      incorrectAfterDate: '%{target} musi być ustawione po %{after}.',
      incorrectPlanStartDate:
        'Planowana data rozpoczęcia musi być przed planowaną datą zakończenia.',
      incorrectPlanEndDate:
        'Planowana data zakończenia musi być datą po planowanej dacie rozpoczęcia.',
      incorrectActualStartDate:
        'Data rozpoczęcia musi być datą przed datą zakończenia.',
      incorrectActualEndDate:
        'Data zakończenia musi być ustawiona na datę po dacie rozpoczęcia.',
      withActualStartDate: 'Proszę również podać datę rozpoczęcia.',
      inputAvailableDate: 'Data, którą można wprowadzić, to od %{date}.',
      registrationRequired: 'Proszę wybrać przypisaną firmę lub adres e-mail.',
      bothInput: 'Można wprowadzić tylko przypisaną firmę lub adres e-mail.',
      supportMode:
        'Proszę wprowadź identyfikator firmy lub identyfikator placu budowy.',
      invalidBkFile: 'To nie jest plik BK3.',
      invalidPdfFile: 'To nie jest plik PDF.',
      duplicatedBucketName: 'Nazwa wiaderka jest zduplikowana',
      duplicatedTaskName: 'Nazwa zadania jest zduplikowana.',
      duplicateGeofenceName: 'Nazwa geofence jest zduplikowana',
      duplicatedCompactionWorkAreaName:
        'Zduplikowana nazwa obszaru pracy zagęszczania',
      duplicatedCompactionLayerName: 'Zduplikowana nazwa warstwy zagęszczania',
      duplicated: {
        compactionMaterials: {
          message: 'Zduplikowana nazwa materiału',
        },
        weathers: {
          message: 'Zduplikowana nazwa pogody',
        },
        operators: {
          message: 'Zduplikowana nazwa operatora',
        },
      },
      containInvalidString:
        'Zawarte są nieprawidłowe znaki. (Nie można używać %{msg})',
      validHalfWidthAlphanumeric:
        'Dozwolone są tylko alfanumeryczne znaki o szerokości półznakowej.',
      validNumberFormat: 'Nie jest liczbą',
      validIntegerFormat: 'Nie jest liczbą całkowitą',
      validIntegerRangeFormat:
        'Wprowadzenie jest dozwolone od 0 do 2147483647.',
      validDuplicateLanguage: 'Wybrano ten sam język.',
      validBeforeDate:
        '%{target} musi być ustawione na datę i godzinę przed %{before}.',
      validAfterDate:
        '%{target} musi być ustawione na datę i godzinę po %{after}.',
    },
  },
  resources: {
    sites: {
      name: 'Plac budowy',
      fields: {
        name: 'Plac budowy',
        corporationName: 'Nazwa firmy',
        status: 'Status',
        workPeriodStartDate: 'Data rozpoczęcia',
        workPeriodEndDate: 'Data zakończenia',
      },
      status: {
        completed: 'Zakończone',
        waiting: 'Przed rozpoczęciem budowy',
        working: 'W budowie',
      },
    },
    retrofits: {
      name: 'Maszyny',
      fields: {
        information: 'Informacje o maszynie',
        rental: 'Przypisanie',
        sendBack: 'Wyślij z powrotem',
        corporationId: 'Firma jest w użyciu',
        siteId: 'Plac budowy',
        uuid: 'UUID',
        isError: 'Status',
        isOnline: 'Online',
        lastOnlineTime: 'Ostatnie połączenie',
        basicInfoManufacturer: 'Producent',
        basicInfoModel: 'Model',
        basicInfoProductNumber: 'Nr produktu',
        basicInfoSerialNumber: 'Nr seryjny',
        controllerManufacturer: 'Producent',
        controllerModel: 'Model',
        controllerFirmwareVersion: 'Wer. firmware',
        gnssReceiverMainManufacturer: 'Producent',
        gnssReceiverMainModel: 'Model',
        gnssReceiverMainFirmwareVersion: 'Wer. firmware',
        gnssReceiverSubManufacturer: 'Producent',
        gnssReceiverSubModel: 'Model',
        gnssReceiverSubFirmwareVersion: 'Wer. firmware',
        machineInfoCompanyName: 'Producent',
        machineInfoMachineType: 'Typ maszyny',
        machineInfoMachineName: 'Nazwa maszyny',
        machineInfoMachineId: 'ID. maszyny',
        rentalStatus: 'Przypisanie',
        lastUpdated: 'Ostatnio zaktualizowany',
      },
      isError: {
        true: 'Nieprawidłowy format powiadomienia o stanie kontrolera',
        false: 'Normalny',
      },
      isOnline: {
        true: 'Online',
        false: 'Offline',
      },
      machineInfoMachineType: {
        excavator: 'Koparka',
        roadroller: 'Walec drogowy',
        dozer: 'Spycharka',
        rigiddump: 'Wywrotka sztywna',
        wheelloader: 'Ładowarka kołowa',
        grader: 'Gredarka',
        automobile: 'Samochód',
      },
      licenseMachineType: {
        excavator: 'Koparka',
        roadroller: 'Walec drogowy',
        dozer: 'Spycharka',
        rigiddump: 'Wywrotka sztywna',
        wheelloader: 'Ładowarka kołowa',
        grader: 'Gredarka',
        automobile: 'Samochód',
        excavator_license: 'Koparka : %{licenseGrantInfo}',
        roadroller_license: 'Walec drogowy : %{licenseGrantInfo}',
        dozer_license: 'Spycharka : %{licenseGrantInfo}',
        rigiddump_license: 'Wywrotka sztywna : %{licenseGrantInfo}',
        wheelloader_license: 'Ładowarka kołowa: %{licenseGrantInfo}',
        grader_license: 'Gredarka : %{licenseGrantInfo}',
        automobile_license: 'Samochód: %{licenseGrantInfo}',
      },
      gnssSettingStatus: {
        sending: 'Przenoszenie',
        sent: 'Przeniesiono',
        failtosend: 'Przeniesienie nieudane',
      },
      rentalStatus: {
        approved: 'Zatwierdzony',
        unapproved: 'Niezaakceptowany',
        returned: 'Zwrócono',
      },
      controllerStatusCode: {
        version: {
          1: '',
          2: '',
        },
        ahrsEstimateStatus: {
          0: '',
          1: '',
          2: '',
          3: '',
          4: '',
          5: '',
          6: '',
        },
        positionStatus: {
          0: '',
          1: '',
          2: '',
          3: '',
          4: 'Nie wykryto anteny GNSS',
        },
        baseStationConnectionStatus: {
          0: '',
          1: '',
          2: '',
        },
        boomImuStatus: {
          0: '',
          1: 'Nie wykryto IMU wysięgnika',
          2: 'Nie wykryto IMU wysięgnika',
          3: 'Nie wykryto IMU wysięgnika',
        },
        armImuStatus: {
          0: '',
          1: 'Nie wykryto IMU ramienia',
          2: 'Nie wykryto IMU ramienia',
          3: 'Nie wykryto IMU ramienia',
        },
        bucketImuStatus: {
          0: '',
          1: 'Nie wykryto IMU łyżki',
          2: 'Nie wykryto IMU łyżki',
          3: 'Nie wykryto IMU łyżki',
        },
        bodyImuStatus: {
          0: '',
          1: 'Nie wykryto IMU nadwozia',
          2: 'Nie wykryto IMU nadwozia',
          3: 'Nie wykryto IMU nadwozia',
        },
        boomHeadPressureSensorStatus: {
          0: '',
          1: 'Nie wykryto czołowego czujnika ciśnienia',
          2: 'Nie wykryto czołowego czujnika ciśnienia',
          3: 'Nie wykryto czołowego czujnika ciśnienia',
        },
        boomBottomPressureSensorStatus: {
          0: '',
          1: 'Nie wykryto dolnego czujnika ciśnienia',
          2: 'Nie wykryto dolnego czujnika ciśnienia',
          3: 'Nie wykryto dolnego czujnika ciśnienia',
        },
        tiltBucketImuStatus: {
          0: '',
          1: 'Nie wykryto IMU łyżki przechylnej',
          2: 'Nie wykryto IMU łyżki przechylnej',
          3: 'Nie wykryto IMU łyżki przechylnej',
        },
        secondBoomImuStatus: {
          0: '',
          1: 'Nie wykryto IMU 2. wysięgnika',
          2: 'Nie wykryto IMU 2. wysięgnika',
          3: 'Nie wykryto IMU 2. wysięgnika',
        },
        rotarySensorStatus: {
          0: '',
          1: 'Niewykryty czujnik przesunięcia',
          2: 'Niewykryty czujnik przesunięcia',
          3: 'Niewykryty czujnik przesunięcia',
          4: 'Niewykryty czujnik przesunięcia',
          5: 'Niewykryty czujnik przesunięcia',
          6: 'Niewykryty czujnik przesunięcia',
        },
        boomOffsetSensorStatus: {
          0: '',
          1: 'Niewykryty czujnik obrotu',
          2: 'Niewykryty czujnik obrotu',
          3: 'Niewykryty czujnik obrotu',
          4: 'Niewykryty czujnik obrotu',
          5: 'Niewykryty czujnik obrotu',
          6: 'Niewykryty czujnik obrotu',
        },
        tiltrotatorStatus: {
          0: '',
          1: 'Niewykryty tiltrotator',
          2: 'Niewykryty tiltrotator',
          3: 'Niewykryty tiltrotator',
        },
      },
    },
    retrofitRentals: {
      name: 'Przypisanie',
      fields: {
        id: 'ID',
        retrofitId: 'ID. maszyny',
        fromCorporationId: 'ID przypisującego przedsiębiorstwa',
        fromCorporationName: 'Przypisujące przedsiębiorstwo',
        toCorporationId: 'Przypisane przedsiębiorstwo',
        toCorporationName: 'Firma jest w użyciu',
        retrofitRentalId: 'ID przypisania',
        isApproved: 'Status',
        isReturned: 'Zwrócono',
        planStartDate: 'Planowana data rozpoczęcia',
        planEndDate: 'Planowana data zakończenia',
        actualStartDate: 'Data rozpoczęcia',
        actualEndDate: 'Data zakończenia',
        rentalUsers: 'E-mail',
        email: 'E-mail',
        rentalStatus: 'Status',
      },
      isApproved: {
        true: 'Zatwierdzony',
        false: 'Niezaakceptowany',
      },
      rentalStatus: {
        approved: 'Zatwierdzony',
        unapproved: 'Niezaakceptowany',
        returned: 'Zwrócono',
      },
    },
    pendingRentals: {
      name: 'Oczekiwanie na zatwierdzenie',
      fields: {
        fromCorporationName: 'Przypisujące przedsiębiorstwo',
        planStartDate: 'Planowana data rozpoczęcia',
        planEndDate: 'Planowana data zakończenia',
      },
    },
    pendingTransfers: {
      name: 'Oczekiwanie na zatwierdzenie',
      fields: {
        fromCorporationName: 'Przenosząca firma',
        planDate: 'Planowana data transferu',
        lastUpdated: 'Ostatnio zaktualizowany',
      },
    },
    pendingAlternateRegists: {
      name: 'Oczekiwanie na zatwierdzenie',
      fields: {
        fromCorporationName: 'Obecny właściciel',
        lastUpdated: 'Ostatnio zaktualizowany',
      },
    },
    assignments: {
      name: 'Transfer',
      fields: {
        id: 'ID',
        retrofitId: 'ID. maszyny',
        fromCorporationId: 'ID firmy przenoszącej',
        fromCorporationName: 'Przenosząca firma',
        toCorporationId: 'Przeniesiona firma',
        toCorporationName: 'Firma',
        isApproved: 'Status',
        planDate: 'Planowana data transferu',
        actualDate: 'Data transferu',
        transferUsers: 'E-mail',
        email: 'E-mail',
        lastUpdated: 'Ostatnio zaktualizowany',
      },
      isApproved: {
        true: 'Zatwierdzony',
        false: 'Niezaakceptowany',
      },
    },
    buckets: {
      name: 'Łyżka',
      fields: {
        companyName: 'Nazwa firmy',
        siteId: 'Plac budowy',
        name: 'Nazwa',
        corporationId: 'Firma jest w użyciu',
        bucketType: 'Typ',
        bk3File: 'Plik BK3',
        'bk3File.title': 'Plik BK3',
        gltfFile: 'Plik łyżki',
        uploadedBy: 'Zarejestrowane przez',
        updatedBy: 'Zaktualizowane przez',
        fileStatus: 'Status',
        lastUpdated: 'Ostatnio zaktualizowany',
        uploadBk: 'Prześlij BK3',
      },
      bucketType: {
        slope: 'Pochylenie',
        standard: 'Standard',
        tilt: 'Pochylenie',
        tiltrotator: 'Obrotnik pochylenia',
      },
      uploadedBy: {
        admin: 'Administrator',
        tablet: 'Tablet',
      },
      fileStatus: {
        converting: 'Konwertowanie',
        completed: 'Zakończone',
        error: 'Błąd',
      },
    },
    projects: {
      name: 'Projekt',
      fields: {
        name: 'Nazwa projektu',
        coordinate: 'Układ współrz.',
        version: 'Wersja',
        status: 'Status',
        lastUpdated: 'Ostatnio zaktualizowany',
        regionId: 'Region',
        projectionId: 'Projekcja',
        geoidId: 'Geoid',
        datumId: 'Odniesienie',
        file: 'Plik(i)',
        retrofitId: 'Maszyna docelowa',
      },
      status: {
        converted: 'Zakończone',
        waitforconvert: 'Konwertowanie',
        error: 'Błąd',
      },
    },
    extensionarms: {
      name: 'Wydłużenie Ramię',
      fields: {
        extensionarmType: 'Typ',
        name: 'Nazwa',
        corporationId: 'Firma jest w użyciu',
        corporationName: 'Firma jest w użyciu',
        siteId: 'Plac budowy',
        siteName: 'Plac budowy',
        lastUpdated: 'Ostatnio zaktualizowany',
        extensionarmFile: 'Plik wydłużenie ramię',
      },
      extensionarmType: {
        basic: 'Wydłużenie Ramię',
        a: 'Typ A',
        b: 'Typ B',
      },
    },
    extensionarmShares: {
      fields: {
        corporationId: 'Udostępnij do',
        name: 'Nazwa',
        extensionarmType: 'Typ',
        extensionarmFile: {
          title: 'Plik wydłużenie ramię',
        },
      },
    },
    corporations: {
      name: 'Firma',
      fields: {
        name: 'Firma jest w użyciu',
      },
    },
    retrofitShareFiles: {
      name: 'Dziennik aplikacji',
      fields: {
        name: 'Nazwa pliku',
        fileTime: 'Data i czas otrzymania',
        url: 'Pobierz',
      },
    },
    retrofitConfigs: {
      name: 'Informacje konfiguracyjne',
      fields: {
        timeStamp: 'Data i czas otrzymania',
        url: 'Pobierz',
      },
    },
    retrofitKitInfos: {
      name: 'Informacje o sterowniku',
      fields: {
        timeStamp: 'Data i czas otrzymania',
        url: 'Pobierz',
      },
    },
    retrofitCalibs: {
      name: 'Informacje o kalibracji',
      fields: {
        timeStamp: 'Data i czas otrzymania',
        url: 'Pobierz',
        detailInfo: {
          createDateTime: 'Utwórz datę i godzinę',
          updateDateTime: 'Data i godzina aktualizacji',
          machineInfo: {
            companyName: 'Producent',
            machineType: 'Typ maszyny',
            machineName: 'Nazwa maszyny',
            machineId: 'ID. maszyny',
            drumConfiguration: 'Konfiguracja maszyny',
            guidanceMode: 'Tryb naprowadzania',
          },
          machineGeometryInfo: {
            boomLength: 'Długość wysięgnika',
            secondBoomLength: 'Długość 2. wysięgnika',
            armLength: 'Długość ramienia',
            armTopPinToBucketSideLinkPinLength:
              'Odl. od górn. sw. ram. do sw. bocz. łącz. łyż.',
            boomTopPinToBucketSideLinkPinLength:
              'Odl. od górn. sw. wys. do sw. bocz. łączn. łyż.',
            bucketLink1Length: 'Odl. od bocz. sw. łyż. do sw. sił. łyż.',
            bucketLink2Length: 'Odl. od sw. sił. łyż. do sw. łącz. łyż.',
            bodyCenterToBoomFootPin: {
              x: 'X sw. stopy wys. wzgl. środ. nadw.',
              y: 'Y sw. stopy wys. wzgl. śr. nadw.',
              z: 'Z sw. stopy wys. wzgl. śr. nadw.',
            },
            drumWidth: 'Szerokość ostrza',
            length: 'Długość między butami',
            shoeInterval: 'Długość odstępu między butami',
            bladeWidthForDozer: 'Szerokość ostrza dla spycharki',
            distanceFromBodyToBlade: 'Odległość od korpusu do lemiesza',
            frontToMainGnssAntenna:
              'Długość od środka linii prostej łączącej przedni uziemiony koniec z główną anteną gnss X',
            frontToSubGnssAntenna:
              'Długość od środka linii prostej łączącej przedni uziemiony koniec z przodu do podrzędnej anteny GNSS X',
            centerToMainGnssAntenna:
              'Y odległość od środka do środka głównej fazy elektrycznej GNSS',
            centerToSubGnssAntenna:
              'Y odległość od środka do środka fazy elektrycznej sub GNSS',
            groundToMainGnssAntenna:
              'Z odległość od uziemienia do centrum głównej fazy elektrycznej GNSS',
            groundToSubGnssAntenna:
              'Z odległość od ziemi do centrum fazy elektrycznej sub GNSS',
          },
          imuMountInfo: {
            boomImuOffsetAngle: 'Kąt przes. IMU wysięgnika',
            secondBoomImuOffsetAngle: 'Kąt przesunięcia IMU 2. wysięgnika',
            armImuOffsetAngle: 'Kąt przes. IMU ramienia',
            bucketImuOffsetAngle: 'Kąt przes. IMU łyżki',
            bodyImuOffsetRollAngle: 'Kąt bocz. prz. IMU nadw.',
            bodyImuOffsetPitchAngle: 'Kąt wzdł. przes. IMU nad.',
          },
          gnssMountInfo: {
            main: {
              bodyCenterToGnss: {
                x: 'X gł. GNSS wzgl. śr. nad.',
                y: 'Y gł. GNSS wzgl. śr. nad.',
                z: 'Z gł. GNSS wzgl. śr. nad.',
              },
            },
            sub: {
              bodyCenterToGnss: {
                x: 'X pod. GNSS wzgl. śr.nad.',
                y: 'Y pod. GNSS wzgl. śr.nad.',
                z: 'Z pod. GNSS wzgl. śr.nad.',
              },
            },
          },
          extendedModel: {
            swingBoom: 'Model z wysięgnik obrotowy',
            twoPieceBoom: 'Model z wysięgnik 2-częściowy',
            minimalSwingRadius: 'Model z wysięgnik przesunięciem',
          },
          measuredValues: {
            otherLength: {
              machineHeight: 'Odstęp przeciwciężaru',
            },
            bodyImu1: {
              roll: 'Przechylenie wartości zmierzonej IMU',
              pitch: 'Pochylenie wartości mierzonej IMU',
              yaw: 'Odchylenie zmierzonej wartości IMU',
            },
            bodyImu2: {
              roll: 'Wartość zmierzona przechylenia IMU',
              pitch: 'Wartość pomiaru nachylenia IMU',
              yaw: 'Odchylenie zmierzonej wartości IMU',
            },
            frontLeft: {
              x: 'Zmierzona wartość lewego przedniego X',
              y: 'Zmierzona wartość lewego przedniego Y',
              z: 'Zmierzona wartość lewego przedniego Z',
            },
            frontRight: {
              x: 'Zmierzona wartość prawego przedniego X',
              y: 'Zmierzona wartość prawego przedniego Y',
              z: 'Zmierzona wartość prawego przedniego Z',
            },
            frontGroundingEnd: {
              x: 'Wartość pomiarowa przedniego końca obręczy X',
              y: 'Wartość pomiarowa przedniego końca obręczy Y',
              z: 'Zmierzona wartość przedniego końca obręczy Z',
            },
            rearGroundingEnd: {
              x: 'Wartość pomiarowa tylnego końca obręczy X',
              y: 'Wartość pomiarowa tylnego końca obręczy Y',
              z: 'Wartość pomiarowa tylnego końca wiązki Z',
            },
            mainGnssAntenna: {
              x: 'Zmierzona wartość głównej anteny GNSS X',
              y: 'Zmierzona wartość głównej anteny GNSS Y',
              z: 'Wartość pomiarowa głównej anteny GNSS Z',
            },
            subGnssAntenna: {
              x: 'Zmierzona wartość podrzędnej anteny GNSS X',
              y: 'Wartość pomiarowa podrzędnej anteny GNSS Y',
              z: 'Wartość pomiarowa podrzędnej anteny GNSS Z',
            },
            prismPoleOffset: 'Wysokość słupa pryzmatu',
            antennaElectricalCenterHeight:
              'Wysokość od środka elektrycznego anteny',
          },
        },
      },
    },
    retrofitAccuracies: {
      name: 'Wynik kontroli dokładności',
      fields: {
        checkDateTime: 'Data i czas potwierdzenia',
        surveyPoint: 'Punkt pomiarowy',
        positionN: 'Wartość zmierzona : N',
        positionE: 'E',
        positionZ: 'Z',
        machineOffsetN: 'Wartość przesunięcia : N',
        machineOffsetE: 'E',
        machineOffsetZ: 'Z',
        referencePointName: 'Nazwa punktu odniesienia',
        referencePointN: 'Punkt odniesienia : N',
        referencePointE: 'E',
        referencePointZ: 'Z',
        unitLength: 'Jednostka długości',
      },
    },
    retrofitBasicSettings: {
      name: 'Ustawienia podstawowe',
      fields: {
        timeStamp: 'Data i czas otrzymania',
        url: 'Pobierz',
      },
    },
    retrofitErrors: {
      name: 'Informacje o błędzie',
      fields: {
        notifiedTime: 'Data i godzina wystąpienia',
        errorCodes: 'Kod błędu: Szczegóły',
      },
    },
    retrofitToposurveys: {
      name: 'Lista pomiarów topografii',
      fields: {
        surveyDate: 'Data i godzina przeprowadzenia ankiety',
        projectName: 'Nazwa projektu',
        layerName: 'Warstwa',
        pointName: 'Nazwa punktu',
        surveyPoint: 'Punkt pomiarowy',
        n: 'Wartość zmierzona : N',
        e: 'E',
        z: 'Z',
        unitLength: 'Jednostka długości',
      },
    },
    asbuilts: {
      name: 'Wyniki budowy',
      fields: {
        execStartDate: 'Rozpoczęcie wykonywania',
        execEndDate: 'Zakończenie wykonywania',
        startPeriod: 'Okres nabycia od',
        endPeriod: 'Okres nabycia do',
        execStatus: 'Status',
        url: '',
        csvType: 'Format danych',
        createUser: 'Utwórz użytkownika',
      },
      execStatus: {
        complete: 'Zakończone',
        inprogress: 'Przetwarzanie',
        error: 'Błąd',
        completewithoutdata: 'Zakończone (Brak danych)',
      },
    },
    asbuiltTime: {
      fields: {
        id: 'ustawiony czas',
      },
    },
    retrofitBasicInfos: {
      name: 'Informacje podstawowe',
      fields: {
        uuid: 'UUID',
        retrofitDistributorId: 'Dystrybutor',
        retrofitRegionId: 'Region',
        corporationId: 'ID firmy',
        corporationName: 'Nazwa firmy',
        basicInfoManufacturer: 'Producent',
        basicInfoModel: 'Model',
        basicInfoProductNumber: 'Nr produktu',
        basicInfoSerialNumber: 'Nr seryjny',
        lastUpdated: 'Ostatnio zaktualizowany',
        description: 'Opis',
        ownerId: 'ID firmy',
        ownerName: 'Nazwa firmy',
      },
      tag: {
        retrofitBasicInfosDetail: 'Informacje podstawowe',
        sendBack: 'Wyślij z powrotem',
      },
      sendBack: {
        fields: {
          site: 'Plac budowy',
          corporation: 'Firma jest w użyciu',
          ntripSettingInfo: 'ID Ntrip',
          toRetrofit: 'Alternatywny kontroler',
          notificationEmailAddress: 'E-mail',
        },
      },
    },
    retrofitBulkActions: {
      name: 'Rejestracja zbiorowa',
      fields: {
        uploadFileName: 'Nazwa pliku',
        execStartDate: 'Data i czas rozpoczęcia wykonywania',
        execEndDate: 'Data i czas zakończenia wykonywania',
        execStatus: 'Status',
        uploadFile: 'Zarejestrowany plik',
        resultFile: 'Wyniki rejestracji',
      },
      execStatus: {
        complete: 'Zakończone',
        inprogress: 'Przetwarzanie',
        error: 'Błąd',
      },
    },
    retrofitAdminCorporationBulkActions: {
      name: 'Masowe działania administratora firmy',
      fields: {
        uploadFileName: 'Nazwa pliku',
        execStartDate: 'Data i czas rozpoczęcia wykonywania',
        execEndDate: 'Data i czas zakończenia wykonywania',
        execStatus: 'Status',
        uploadFile: 'Zarejestrowany plik',
        resultFile: 'Wyniki rejestracji',
      },
      execStatus: {
        complete: 'Zakończone',
        inprogress: 'Przetwarzanie',
        error: 'Błąd',
      },
    },
    tasks: {
      name: 'Zadanie',
      fields: {
        name: 'Nazwa',
        status: 'Status',
        description: 'Opis',
        projectSourceLayerId: 'Warstwa',
        retrofitIds: 'Maszyna docelowa',
        expiredDate: 'Data ważności',
        createDate: 'Data ważności',
        rectangle: 'Obsługiwany zakres',
        lastUpdated: 'Ostatnio zaktualizowany',
      },
      status: {
        error: 'Błąd',
        converting: 'Konwertowanie',
        sending: 'Przenoszenie',
        sent: 'Przeniesiono',
        failtosend: 'Przeniesienie nieudane',
      },
    },
    compactionMaterials: {
      name: 'Materiały',
      fields: {
        name: 'Nazwa',
      },
    },
    weathers: {
      name: 'Warunki pogodowe',
      fields: {
        name: 'Nazwa',
      },
    },
    operators: {
      name: 'Operatorzy',
      fields: {
        name: 'Nazwa',
      },
    },
    compactionWorkAreas: {
      name: 'Obszary robót zagęszczania',
      fields: {
        name: 'Nazwy obszarów roboczych',
        blockSize: 'Rozmiar bloku',
        description: 'Opis',
      },
    },
    compactionLayers: {
      name: 'Warstwy zagęszczania',
      fields: {
        name: 'Nazwa',
        thickness: 'Grubość',
        passCount: 'Liczba przejść',
        compactionMaterialId: 'Materiał',
        inUse: 'Status',
        description: 'Opis',
      },
      inUse: {
        true: 'w użyciu',
        false: 'Nie używane',
      },
    },
    gnssSettings: {
      name: 'Ustawienia Ntrip',
      fields: {
        registCorporationName: 'Zarejestrowane przez',
        ownerCorporationId: 'ID firmy',
        ownerCorporationName: 'Nazwa firmy',
        name: 'Nazwa',
        url: 'URL serwera',
        portnum: 'Port',
        userId: 'ID',
        password: 'Hasło',
        retrofitId: 'Maszyna docelowa',
        status: 'Status',
        updateDate: 'Ostatnio zaktualizowany',
      },
      status: {
        waitforsend: 'Konwertowanie',
        sending: 'Przenoszenie',
        sent: 'Przeniesiono',
        failtosend: 'Przeniesienie nieudane',
      },
    },
    supportMode: {
      name: 'Tryb wsparcia',
      fields: {
        enabled: 'Włącz tryb pomocy',
        corporationId: 'ID firmy',
        corporationName: 'Nazwa firmy',
        siteId: 'ID miejsca budowy',
        siteName: 'Plac budowy',
        latitude: 'Szerokość geograficzna',
        longitude: 'Długość geograficzna',
        workPeriodStartDate: 'Faktyczny okres budowy (data rozpoczęcia)',
        workPeriodEndDate: 'Faktyczny okres budowy (data zakończenia)',
        photoUrl: 'URL obrazu witryny',
        description: 'Opis',
      },
    },
    userNotices: {
      name: 'Powiadomienie',
      noticeType: {
        emergencyimportant: 'Nagły wypadek/Ważne',
        emergency: 'Nagły wypadek',
        important: 'Svarbu',
      },
      fields: {
        noticeType: 'Waga',
        noticeTypes: 'Waga',
        noticeCategory: 'Typ powiadomienia',
        title: 'Tytuł',
        content: 'Zawartość',
        publicationStartDate: 'Data i czas rozpoczęcia (UTC)',
        publicationEndDate: 'Data zakończenia i czas(UTC)',
        file: 'Załączniki',
        language: 'Język',
        emergency: 'Nagły wypadek',
        important: 'Svarbu',
      },
    },
    firmwares: {
      name: 'Firmware',
      fields: {
        version: 'Wersja',
        compatibilityLevel: 'Poziom zgodności',
        firmwareFile: 'Plik oprogramowania',
        firmwareFileCheckSum: 'Suma kontrolna oprogramowania',
        firstInitializationFile: 'Plik inicjacyjny 1',
        firstInitializationFileCheckSum: 'Suma kontrolna pliku inicjacyjnego 1',
        secondInitializationFile: 'Plik inicjacyjny 2',
        secondInitializationFileCheckSum:
          'Suma kontrolna pliku inicjacyjnego 2',
      },
    },
    bucketShares: {
      name: 'Dzielenie się wiaderkiem',
      fields: {
        fromBucketId: 'Z identyfikatora kubełka',
        corporationId: 'ID firmy',
        name: 'Nazwa',
      },
    },
    extensionarmShare: {
      name: 'Udostępnianie wydłużenie ramię',
      fields: {
        name: 'Nazwa',
      },
    },
    retrofitCorporationLicenses: {
      name: 'LICENCJA',
      fields: {
        licenseId: 'Id. licencji',
        licenseType: 'Typ',
        issuedAt: 'Data wydania',
        expiresAt: 'Data ważności',
        retrofitId: 'Maszyny',
        comment: 'Opis',
        licenseStatus: 'Status',
        licensePermission: 'LICENCJA',
        productType: 'Nazwa produktu',
      },
      licenseStatus: {
        unlicense: 'Nieprzydzielony',
        disable: 'Niepoprawny',
        enable: 'Ważny',
      },
    },
    retrofitNamePlateFiles: {
      fields: {
        corporationName: 'Nazwa firmy',
        namePlates: 'Zdjęcie',
      },
    },
    siteConfigures: {
      fields: {
        unitLength: 'Jednostka długości',
      },
    },
    geofences: {
      name: 'Geofencing',
      fields: {
        type: 'Typ',
        name: 'Nazwa',
        dimension: 'Wymiar',
        trigger: 'Wyzwalacz',
        valid: 'Ważny',
        alertType: 'Typ alarmu',
        startDate: 'Data rozpoczęcia',
        endDate: 'Data zakończenia',
        transparency: 'Przejrzysty',
        elevation: 'Wzniesienie',
        height: 'Wysokość',
        radius: 'Promień',
        lastUpdated: 'Ostatnio zaktualizowany',
        points: 'Obsługiwany zakres',
        thickness: 'Grubość',
      },
      type: {
        line: 'Linia',
        polygon: 'Wielokąt',
        circle: 'Koło',
        point: 'Punktu',
        wall: 'Ściana',
      },
      dimension: {
        twodimension: '2D',
        threedimension: '3D',
      },
      valid: {
        true: 'Ważny',
        false: 'Niepoprawny',
      },
      alertType: {
        attention: 'Uwaga',
        notice: 'Uwaga',
        warning: 'Ostrzeżenie',
        caution: 'Uwaga',
        danger: 'Niebezpieczeństwo',
      },
      transparency: {
        true: 'Przejrzysty',
        false: 'Nieprzezroczysty',
      },
    },
    geofenceAlerts: {
      name: 'Alerty',
      fields: {
        timeStamp: 'Data i czas wykrycia',
        geofenceName: 'Nazwa geofence',
        alertType: 'Typ alarmu',
        collisionPoint: 'Punkt kolizji',
        retrofit: {
          machineInfoMachineName: 'Maszyny',
        },
      },
      alertType: {
        attention: 'Uwaga',
        notice: 'Uwaga',
        warning: 'Ostrzeżenie',
        caution: 'Uwaga',
        danger: 'Niebezpieczeństwo',
      },
    },
    ntrips: {
      name: 'Zarządzanie Ntrip',
      fields: {
        name: 'Id. użytkownika',
        ntripPass: 'Hasło',
        serial: 'Nr seryjny',
        customerCorporationId: 'ID firmy',
        customerCorporationName: 'Nazwa firmy',
        ntrip: 'Status',
        numberOfExchanges: 'Liczba wymian',
        id: 'ID',
        licenceId: 'Id. licencji',
        dateOfRegistration: 'Data rejestracji',
        billingToCustomers: 'Rozliczenie z klientami',
        paymentToKomatsu: 'Płatność dla Komatsu',
        applicantId: 'ID kandydata',
        applicant: 'Kandydat',
        requestCorporationId: 'Poproś o ID firmy',
        requestCorporationName: 'Poproś o nazwę firmy',
        isUsed: 'Jest używany',
      },
      ntrip: {
        publish: 'Wypłacone',
        stop: 'Stop',
        delete: 'Usunięty',
      },
    },
    retrofitAlternateRegists: {
      name: 'Rejestracja proxy kontrolera',
      fields: {
        id: 'ID',
        retrofitId: 'retrofitId',
        fromCorporationId: 'z identyfikatora firmy',
        fromCorporationName: 'Rejestracja proxy kontrolera',
        toCorporationId: 'Użytkownika',
        toCorporationName: 'Nazwa firmy',
        isApproved: 'Status',
        createDate: 'Data zgłoszenia',
        actualDate: 'Data zatwierdzenia',
        transferUsers: 'E-mail',
        email: 'E-mail',
        lastUpdated: 'Ostatnio zaktualizowany',
      },
      isApproved: {
        true: 'Zatwierdzony',
        false: 'Niezaakceptowany',
      },
    },
    pendingApprovals: {
      name: 'Oczekiwanie na zatwierdzenie',
      fields: {
        approvalType: 'Typ',
        requestedBy: 'Firma kandydata',
        lastUpdated: 'Ostatnio zaktualizowany',
      },
      approvalType: {
        assignment: 'Przypisanie',
        transfer: 'Transfer',
        agent: 'Rejestracja proxy kontrolera',
      },
    },
    retrofitSendbacks: {
      name: 'Wyślij z powrotem',
      fields: {
        fromRetrofitId: 'Z Retrofit',
        toRetrofitId: 'Alternatywny kontroler',
        notificationEmailAddress: 'E-mail',
        siteId: 'Plac budowy',
        corporationId: 'ID firmy',
        corporationName: 'Firma jest w użyciu',
        ntripId: 'Informacje Ntrip',
        isComplete: 'Wysyłka zakończona',
      },
    },
    siteBuriedStructures: {
      fields: {
        epsgCode: 'Epsg',
      },
    },
    fileStorages: {
      fields: {
        name: 'Name',
        createdBy: {
          user: {
            profile: {
              name: 'Author',
            },
          },
        },
        updatedAt: 'Last Modified',
        updatedby: {
          user: {
            profile: {
              name: 'Last Modified By',
            },
          },
        },
        files: {
          totalSize: 'Size',
        },
        file: {
          size: 'Size',
        },
      },
      nodeType: {
        bucket: 'Bucket',
        directory: 'Folder',
        file: 'File',
      },
    },
  },
};

export default message;
