import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { DoneButton, DialogOKOnly } from '../../../..';
import { SaveIcon } from '../../../../../assets';
import { validFileType } from '../utils';
import {
  landlogDataProvider,
  FileStorageData,
} from '../../../../../dataProvider';

const targetFiledName = 'file';
const targetExtensions = ['gc3', 'cal'];

interface Props {
  form: any;
  disabled: boolean;
  onClick: any;
}

const SaveButton: React.FC<Partial<Props>> = ({ form, onClick, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState(['']);
  const translate = useTranslate();

  const handleClick = async () => {
    const uploadFiles = form
      .getFieldState(targetFiledName)
      .value.map(({ title }: { title: string }) => title);

    if (!validFileType(uploadFiles, targetExtensions)) {
      setOpen(true);
      setMessages([translate('admin.dialog.projectFileUpload.message')]);
      return;
    }

    // Groupwareにデータが存在するか確認
    const scGroupwareFiles = form
      .getFieldState(targetFiledName)
      .value.map(({ fileInfo }: { fileInfo: any }) => fileInfo)
      .filter((item: any) => item !== undefined);

    if (scGroupwareFiles.length > 0) {
      const errorFiles = await Promise.all(
        scGroupwareFiles.map(async (file: FileStorageData) => {
          const { id, parentNodeId, bucketId } = file;
          try {
            // getOneが使用できないため、getListで1件データを取得する
            await landlogDataProvider.getList('fileStorages', {
              pagination: {
                page: 1,
                perPage: 1,
              },
              sort: { field: 'id', order: 'ASC' },
              filter: {
                nodeId: id,
                parentNodeId,
                bucketId,
              },
            });
            return null; // エラーがない場合はnullを返す
          } catch (error) {
            return file;
          }
        }),
      );
      // エラーが発生したファイルのみをフィルタリング
      const filteredErrorFiles = errorFiles.filter(file => file !== null);
      if (filteredErrorFiles.length > 0) {
        const errorFileName = filteredErrorFiles.map(file => file.name);
        setOpen(true);
        setMessages([
          translate('admin.dialog.filePicker.errorMessage'),
          ...errorFileName,
        ]);
        return;
      }
    }

    if (onClick) {
      onClick();
    } else {
      form.submit();
    }
  };

  return (
    <>
      <DialogOKOnly
        open={open}
        onClose={() => setOpen(false)}
        title=""
        messages={messages}
      />
      <DoneButton {...rest} onClick={handleClick} label="ra.action.save">
        <SaveIcon />
      </DoneButton>
    </>
  );
};

SaveButton.displayName = 'SaveButton';
export default SaveButton;
