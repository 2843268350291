import { fetchUtils } from 'react-admin';
import { encode } from 'querystring';
import { config } from '../../utils';
import { FileStorageBuckets, LandLogListFileStorageProps, getOption } from '.';

interface ListResponse {
  total: number;
  buckets: FileStorageBuckets[];
}

export default async (
  props: LandLogListFileStorageProps,
): Promise<ListResponse> => {
  const params = encode({ ...props });
  const uri = `${config.api.landlog.fileStorageUri}/v2/file_storage/buckets`;
  const { json } = await fetchUtils.fetchJson(
    `${uri}?${params}`,
    await getOption(),
  );
  return json;
};
