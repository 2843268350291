import { FileStorageBuckets, FileStorageData } from '..';
import { convertEpochTime, convertFileSize } from '../../utils';

export default (fileStorage: FileStorageBuckets): FileStorageData => ({
  id: fileStorage.id,
  name: fileStorage.name,
  nodeType: 'bucket',
  resourceOwnerId: fileStorage.resource_owner_id,
  createdAt: convertEpochTime(fileStorage.created_at),
  updatedAt: convertEpochTime(fileStorage.updated_at),
  createdBy: {
    user: fileStorage.created_by.user
      ? {
          id: fileStorage.created_by.user.id,
          profile: fileStorage.created_by.user.profile
            ? {
                name: fileStorage.created_by.user.profile.name,
              }
            : { name: '' },
        }
      : { id: '', profile: { name: '' } },
    client: {
      clientId: fileStorage.created_by.client.client_id,
      isDevice: fileStorage.created_by.client.is_device,
      displayName: fileStorage.created_by.client.display_name
        ? fileStorage.created_by.client.display_name
        : '',
      isFirstParty: fileStorage.updated_by.client.is_first_party
        ? fileStorage.updated_by.client.is_first_party
        : false,
    },
  },
  updatedBy: {
    user: fileStorage.updated_by.user
      ? {
          id: fileStorage.updated_by.user.id,
          profile: fileStorage.updated_by.user.profile
            ? {
                name: fileStorage.updated_by.user.profile.name,
              }
            : { name: '' },
        }
      : { id: '', profile: { name: '' } },
    client: {
      clientId: fileStorage.updated_by.client.client_id,
      isDevice: fileStorage.updated_by.client.is_device,
      displayName: fileStorage.updated_by.client.display_name
        ? fileStorage.updated_by.client.display_name
        : '',
      isFirstParty: fileStorage.updated_by.client.is_first_party
        ? fileStorage.updated_by.client.is_first_party
        : false,
    },
  },
  files: {
    totalCount: fileStorage.files.total_count,
    totalSize: convertFileSize(fileStorage.files.total_size),
  },
});
