import { GetListParams, GetListResult } from 'react-admin';
import { keys, omitBy, isUndefined } from 'lodash';
import {
  landlogFileStorageBucketConvert,
  landlogFileStorageNodeConvert,
  landlogListFileStoragesBuckets,
  landlogListFileStoragesNodes,
  FileStorageData,
} from '..';

export type LandLogListFileStorageProps = Partial<{
  resource_owner_id: string; // eslint-disable-line camelcase
  parent_node_id: string; // eslint-disable-line camelcase
  limit: number;
  offset: number;
  order_by: string | undefined; // eslint-disable-line camelcase
  order: 'asc' | 'desc';
  name: string;
  get_user_info: boolean; // eslint-disable-line camelcase
  get_client_info: boolean; // eslint-disable-line camelcase
}>;

export default async (params: GetListParams): Promise<GetListResult> => {
  const { resourceOwnerId, bucketId, parentNodeId, nodeId, q, ...rest } =
    params.filter;
  const { page, perPage } = params.pagination;
  const { field, order } = params.sort;
  if (keys(rest).length > 0) {
    console.error(
      `Filters are not supported in Get scGroupwareFile List: ${rest} `,
    );
  }

  if (!bucketId) {
    // Bucket 一覧取得
    let orderBy = field === 'name' ? field : undefined;
    switch (field) {
      case 'name':
        orderBy = 'name';
        break;
      case 'updatedAt':
        orderBy = 'updated_at';
        break;
      default:
    }
    const props: LandLogListFileStorageProps = {
      resource_owner_id: resourceOwnerId,
      limit: perPage,
      offset: (page - 1) * perPage,
      order_by: orderBy,
      order: order && order === 'ASC' ? 'asc' : 'desc',
      name: q,
      get_user_info: true,
      get_client_info: true,
    };

    const { buckets, total } = await landlogListFileStoragesBuckets(
      omitBy(props, isUndefined),
    );
    const data: FileStorageData[] = buckets.map(bucket => {
      return landlogFileStorageBucketConvert(bucket);
    });
    return { total, data };
  }
  // Node 一覧取得
  let orderBy = field === 'name' ? field : undefined;
  switch (field) {
    case 'name':
      orderBy = 'name';
      break;
    case 'updatedAt':
      orderBy = 'updated_at';
      break;
    case 'file.size':
      orderBy = 'file_size';
      break;
    default:
  }
  const props: LandLogListFileStorageProps = {
    parent_node_id: parentNodeId,
    limit: perPage,
    offset: (page - 1) * perPage,
    order_by: orderBy,
    order: order && order === 'ASC' ? 'asc' : 'desc',
    name: q,
    get_user_info: true,
  };

  const { node, nodes, total } = await landlogListFileStoragesNodes(
    omitBy(props, isUndefined),
    bucketId,
    nodeId,
  );
  const data: FileStorageData[] = node
    ? [landlogFileStorageNodeConvert(node)]
    : nodes.map(nodeData => {
        return landlogFileStorageNodeConvert(nodeData);
      });

  return { total, data };
};
