import React, { useEffect, useState } from 'react';
import { FormDataConsumer, required, useTranslate } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import {
  BreadcrumbsFiled,
  Create,
  CustomForm,
  Loading,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
  FileInput,
  FileField,
  RegionSelectInput,
  LoadingView,
  FinalFormConsumer,
} from '../../..';
import {
  useCreateInSite,
  useNameValidation,
  useFileNameValidation,
  getResourceOwnerId,
} from '../../../../hooks';
import { propsOf, coordinateType } from '../../../../utils';
import { SaveButton } from './views';
import { FilePickerDialogPopupButton } from '../../../organisms/scGroupwareFilePicker';

const useStyles = makeStyles(() =>
  createStyles({
    labelLineHeight: {
      '& .MuiFormLabel-root': {
        lineHeight: '1rem',
      },
      '& .MuiInputBase-root': {
        '& .MuiSelect-root': {
          height: '100%',
        },
      },
    },
  }),
);

const SiteProjectCreate: React.FC = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const { props, site } = useCreateInSite('projects');
  const { resource, siteId: propsSiteId } = props;
  const siteId = site?.id || '';
  const nameValidation = useNameValidation(
    'projectNames',
    'admin.validation.duplicateProject',
    { siteId },
  );
  const fileNameValidation = useFileNameValidation();
  const [apiState, setApiState] = useState<{
    fetched: boolean;
    loading: boolean;
    data?: { resourceOwnerId: string };
  }>({
    fetched: false,
    loading: true,
    data: undefined,
  });

  useEffect(() => {
    if (apiState.fetched) return;
    setApiState({ fetched: true, loading: true });

    const api = async () => {
      // accounts
      const { resourceOwnerId } = await getResourceOwnerId(propsSiteId);
      return { resourceOwnerId };
    };
    api().then(data => {
      setApiState({ fetched: true, loading: false, data });
    });
  }, [apiState.fetched, propsSiteId]);

  if (!site || apiState.loading || !apiState.data) return <Loading />;
  const { name: siteName, unitLength } = site;
  const coordinateTypeProjection = 'Projection';
  const { resourceOwnerId } = apiState.data;
  return (
    <LoadingView>
      <Create {...props}>
        <CustomForm
          title="admin.pages.siteProjectCreate"
          initialValues={
            {
              siteId,
              unitLength,
            } as any
          }
          saveButton={<SaveButton />}
        >
          <BreadcrumbsFiled
            breadcrumbs={[
              'resources.sites.name',
              '',
              siteName,
              'resources.projects.name',
            ]}
            label="ra.action.create"
          />
          <TextInput
            resource={resource}
            source="name"
            validate={[required(), fileNameValidation, nameValidation]}
          />
          <SelectInput
            resource={resource}
            source="coordinate"
            choices={coordinateType}
            validate={required()}
          />
          {/* 「region」「projection」「datum」の項目は、「Coordinate Type」がProjectionの場合のみ表示 */}
          <RegionSelectInput
            {...propsOf(RegionSelectInput, {
              coordinateType: coordinateTypeProjection,
            })}
          />
          <FinalFormConsumer>
            {({ form }) => (
              <>
                <FormDataConsumer>
                  {({ formData }) =>
                    formData.coordinate === coordinateTypeProjection && (
                      <ReferenceInput
                        resource={resource}
                        source="projectionId"
                        reference="projections"
                        filter={{ regionId: formData.regionId }}
                        perPage={1000}
                        onChange={() => {
                          form.change('datumId', null);
                        }}
                      >
                        <SelectInput optionText="name" validate={required()} />
                      </ReferenceInput>
                    )
                  }
                </FormDataConsumer>
                <FormDataConsumer>
                  {({ formData }) =>
                    formData.coordinate === coordinateTypeProjection && (
                      <ReferenceInput
                        resource={resource}
                        source="datumId"
                        reference="datums"
                        filter={{ projectionId: formData.projectionId }}
                        perPage={1000}
                      >
                        <SelectInput optionText="name" validate={required()} />
                      </ReferenceInput>
                    )
                  }
                </FormDataConsumer>
                <FormDataConsumer>
                  {({ formData }) =>
                    formData.coordinate === coordinateTypeProjection && (
                      <ReferenceInput
                        resource={resource}
                        source="geoidId"
                        reference="geoids"
                        filter={{ regionId: formData.regionId }}
                        perPage={1000}
                      >
                        <SelectInput optionText="name" />
                      </ReferenceInput>
                    )
                  }
                </FormDataConsumer>
              </>
            )}
          </FinalFormConsumer>
          <FileInput
            multiple
            resource={resource}
            source="file"
            validate={required()}
            accept=".xml,.tp3,.gc3,.dxf,.tn3,.ttm,.svd,.dsz,.cal,.ln3"
            placeholder={
              <p>
                {translate('admin.label.fileInput.plaseholder', {
                  fileTypes: translate('admin.label.fileInput.project'),
                })}
              </p>
            }
          >
            <FileField source="src" title="title" />
          </FileInput>
          <FilePickerDialogPopupButton
            resource={resource}
            source="fileStorages"
            resourceOwnerId={resourceOwnerId}
          />
          <ReferenceArrayInput
            resource={resource}
            source="retrofitId"
            reference="retrofits"
            filter={{ siteId }}
            sort={{ field: 'machineInfoMachineName', order: 'ASC' }}
            className={classes.labelLineHeight}
          >
            <SelectArrayInput optionText="machineInfoMachineName" />
          </ReferenceArrayInput>
        </CustomForm>
      </Create>
    </LoadingView>
  );
};

SiteProjectCreate.displayName = 'SiteProjectCreate';
export default SiteProjectCreate;
