// for da
// デンマーク語
import danishMessage from 'ra-language-danish';
import en from './en';

const { ra } = danishMessage;
const message: typeof en = {
  ra: {
    ...ra,
    input: {
      ...ra.input,
      password: {
        toggle_visible: '',
        toggle_hidden: '',
      },
    },
    navigation: {
      ...ra.navigation,
      next: '',
      prev: '',
      no_results: 'Ikke fundet',
      page_rows_per_page: 'Angiver antallet af stykker:',
    },
    action: {
      ...ra.action,
      add_filter: '',
      confirm: 'OK',
      move_up: '',
      move_down: '',
      select_all: '',
      select_row: '',
      search: 'søge',
    },
    message: {
      ...ra.message,
      bulk_delete_title: '',
      bulk_delete_content: 'Vil du slette %{name}?',
    },
  },
  admin: {
    title: {
      normal: 'Smart Construction Pilot',
      split1: 'Smart Construction',
      split2: 'Pilot',
    },
    copyright: 'Copyright © EARTHBRAIN Ltd. All rights reserved.',
    approval: 'Afventer godkendelse',
    usermenu: {
      userNotice: 'Underretning',
      terms: 'TJENESTEVILKÅR',
      manual: 'Manual',
      downloads: 'Hent',
    },
    breadcrumbs: {
      details: 'Detaljer',
      retrofitEdit: 'Detaljer',
      taskCreate: 'Opret opgave',
      taskEdit: 'Opgavedetaljer',
      geofenceCreate: 'Oprette',
      geofenceEdit: 'Rediger',
      geofenceAlertList: 'Geofence alarm',
      geofenceAlertShow: 'Detaljer',
      compactionWorkAreaCreate: 'Tilføj komprimeringsarbejdsområder',
      compactionWorkAreaShow: 'Komprimeringsarbejdsområder',
      compactionWorkAreaEdit: 'Rediger komprimeringsarbejdsområder',
      compactionLayerCreate: 'Opret komprimeringslag',
      compactionLayerEdit: 'Rediger komprimeringslag',
      supportMode: 'Supporttilstand',
      retrofitManage: 'Controllerregistrering',
      retrofitManageAdmin: 'Controllerregistrering (Admin)',
      retrofitNamePlateFiles: 'Controllerregistrering',
      retrofitAlternateRegistCreate: 'Controller Proxy Registrering',
      retrofitAlternateRegistEdit: 'Detaljer',
      retrofitManageAdminBulkActionCreate: 'Registrering',
      terms: 'TJENESTEVILKÅR',
      downloads: 'Hent',
    },
    pages: {
      siteList: 'Byggepladsliste',
      siteRetrofitCreate: 'Tilføj maskine til arbejdspladsen',
      siteRetrofitEdit: '',
      siteBucketCreate: 'Tilføj en spand til arbejdspladsen',
      siteBucketEdit: 'Spand detaljer',
      siteProjectEdit: 'Projekt detaljer',
      siteProjectCreate: 'Projektfilregistrering',
      siteExtensionarmCreate: 'Tilføj forlængelsesarm til arbejdspladsen',
      taskCreate: 'Opret opgave',
      taskEdit: 'Opgavedetaljer',
      geofenceAlertList: 'Geofence alarm',
      geofenceAlertShow: 'Geofence alarm detaljer',
      geofenceCreate: 'Opret geofence',
      geofenceEdit: 'Rediger geofence',
      compactionWorkAreaCreate: 'Tilføj komprimeringsarbejdsområder',
      compactionLayerCreate: 'Opret komprimeringslag',
      compactionLayerEdit: 'Rediger komprimeringslag',
      siteFileEdit: 'Detaljer',
      siteFileCreate: 'Tilføj fil til arbejdspladsen',
      retrofitList: 'Maskinliste',
      retrofitEdit: '',
      retrofitRentalCreate: 'Opgaveregistrering',
      retrofitTransferCreate: 'Overførselsregistrering',
      retrofitSendBack: 'Send registrering tilbage',
      bucketList: 'Spand liste',
      bucketEdit: 'Spand detaljer',
      bucketCreate: 'Spand registrering',
      bucketRentalCreate: 'Spand deling',
      extensionarmList: 'Forlængerarmsliste',
      extensionarmEdit: 'Forlængerarmdetaljer',
      extensionarmShare: 'Forlængerarmdeling',
      retrofitBulkActionList: 'Bulk registreringsliste',
      retrofitBulkActionCreate: 'Bulk registrering',
      retrofitBasicInfoList: 'Controllerliste',
      retrofitBasicInfoCreate: 'Controllerregistrering',
      retrofitBasicInfoEdit: 'Controlleropdatering',
      gnssSettingList: 'Ntrip-indstillingsliste',
      gnssSettingCreate: 'Opret Ntrip-indstilling',
      gnssSettingAdminCreate: 'Opret Ntrip-indstilling',
      gnssSettingOwnerCreate: 'Opret Ntrip-indstilling',
      gnssSettingEdit: 'Rediger Ntrip-indstilling',
      gnssSettingAdminEdit: 'Rediger Ntrip-indstilling',
      gnssSettingOwnerEdit: 'Rediger Ntrip-indstilling',
      approvalList: 'Maskinliste',
      retrofitRentalEdit: 'Opgavedetaljer',
      retrofitTransferEdit: 'Overførselsdetaljer',
      asbuiltsCreate: 'Opnå byggeresultat',
      asbuiltsShow: 'Asbuild Historik',
      asbuiltsRetrofitCreate: 'Opnå byggeresultat',
      retrofitConfigList: 'Konfigurationsinfo',
      retrofitKitInfoList: 'Styreenhedsopl.',
      retrofitAccuraciesList: 'Nøjagtighedskontrol resultat',
      retrofitAccuraciesShow: 'Detaljer',
      retrofitCalibaList: 'Info om kalibrering',
      retrofitCalibInfo: 'Detaljer om maskinkalibrering',
      retrofitBasicSettingList: 'Grundlæggende indstillinger',
      retrofitErrorList: 'Fejlinfo',
      retrofitErrorShow: 'Detaljer',
      retrofitTopographicSurveyList: 'Liste topog. undersøg.',
      retrofitTopographicSurveyShow: 'Topografiske oplysninger',
      retrofitShareFileList: 'Ansøgningslog',
      retrofitCorporationLicenseList: 'Licensliste',
      retrofitCorporationLicenseEdit: 'Licensdetaljer',
      CompactionLayerList: 'Komprimeringslagene',
      userNoticeList: 'Notifikationsliste',
      userNoticeCreate: 'Tilføj meddelelse',
      userNoticeEdit: 'Opdater meddelelse',
      userNoticeDrawer: 'Underretning',
      retrofitAlternateRegists: 'Controller Proxy Registreringsliste',
      firmwareList: 'Firmwareliste',
      firmwareShow: 'Firmware detaljer',
      firmwareCreate: 'Tilføj firmware',
      retrofitManage: 'Tekst',
      retrofitNamePlateFiles: 'Foto',
      retrofitManageAdminBulkAction: 'Bulk registrering',
      retrofitManageAdminBulkActionCreate: 'Bulk registrering',
      retrofitAlternateRegistCreate: 'Controller Proxy Registrering',
      retrofitAlternateRegistEdit: 'Controller Proxy Registreringsoplysninger',
      retrofitAlternateRegistCreateText: 'Tekst',
      retrofitAlternateRegistCreatePhoto: 'Foto',
      ntripList: 'Ntrip-info',
      ntripEdit: 'Ntrip-håndtering',
    },
    label: {
      login: 'Log ind',
      selected: 'Valgte (%{length} tilfælde)',
      listNoResults: 'Data ikke fundet',
      maintenance: {
        title: 'I øjeblikket under vedligeholdelse.',
        message: `I øjeblikket har vi midlertidigt suspenderet tjenesterne på grund af vedligeholdelse.
      Vi undskylder for ulejligheden, men vent venligst et øjeblik.`,
      },
      fileInput: {
        project: 'xml, tp3, tn3, ln3, gc3, dxf, ttm, svd, dsz, cal',
        bucket: 'bk3',
        csv: 'csv',
        pdf: 'pdf',
        namePlate: 'jpg, png',
        plaseholder: 'Slip eller vælg filerne (%{fileTypes}) til upload.',
        maxFileNum:
          'Det maksimale antal controllere, der kan registreres på én gang, er fem.',
      },
      nosetting: 'Ingen indstilling',
      siteRetrofitList: {
        remoteSupport: 'Fjernsupport',
      },
      retrofitEdit: {
        breadcrumbs: 'Detaljer',
        belongsDetails: '',
        ntripSettingInfo: 'Ntrip-indstillingsinfo',
        machineryDetails: 'Maskin',
        retrofitDetails: 'Styreenhed',
        basicInfo: 'Grundoplysninger',
        controller: 'Styreenhed',
        reciverMain: 'GNSS-hovedmodtager',
        reciverSub: 'GNSS-undermodtager',
        retrofitstatus: 'Styreenhedsstatus',
        license: 'Licens',
        licenseId: 'Licens-id',
        expiredDate: 'Udløbsdato',
      },
      retrofitRental: {
        rentalDetails: 'Opgavedetaljer',
        rentalInfo: 'Opgavedetaljer',
        lending: 'Opgave',
        return: 'Retur',
        lendingDestination: 'Tildelt virksomhed',
        lendingCompany: 'Virksomhedsnavn',
        lendingEmail: 'E-mail',
        companyName: 'Virksomhedsnavn',
      },
      retorfitTransfer: {
        tab: 'Overførsel',
        transferInfo: 'Overførselsdetaljer',
        transferButton: 'Overførsel',
        transferEditButton: 'Rediger overførsel',
      },
      siteConfigureEdit: {
        onButton: 'TIL',
        offButton: 'FRA',
      },
      approvals: {
        rentalTab: 'Opgave',
        transferTab: 'Overførsel',
      },
      buckets: {
        bucketTab: 'Skovl',
        extensionarmTab: 'Forlængerarm',
        extensionarmFile: 'Forlængerarmfil',
        shareTo: 'Del til',
      },
      bucketRental: {
        corporation: 'Del til',
        bucketType: 'Type',
        bk3File: 'BK3-fil',
      },
      retrofitBasic: {
        basicInfoTab: 'Grundoplysninger',
        bulkActionTab: 'Bulk registrering',
        ntripTab: 'Ntrip-håndtering',
      },
      retrofitSendbacks: {
        retrofitSendbackInfo: 'Send tilbage',
        initialize: 'Initialisering',
      },
      retrofitBasicInfos: {
        bulkRegistrationTitle: 'Bulk opdatering',
        retrofitDistributor: 'Distributør',
        retrofitRegion: 'Region',
        corporationId: 'Virksomheds-ID',
        corporationName: 'Virksomhedsnavn',
      },
      retrofitBasicInfoEdit: {
        corporationId: 'Virksomheds-ID i brug',
        corporationName: 'Virksomhed i brug',
      },
      gnssSettingsEdit: {
        ownerCorporationName: 'Virksomhedsnavn',
      },
      terms: {
        title: 'TJENESTEVILKÅR',
        message: 'Klik på linket nedenfor for at gennemgå Brugsvilkår.',
        confirmMessage1:
          'For at bruge Smart Construction Pilot skal du acceptere følgende dokumenter.',
        confirmMessage2:
          'Hvis du ikke accepterer, kan du ikke bruge Smart Construction Pilot.',
        select: 'Vælg din region.',
      },
      downloads: {
        title: 'Projektfilkonverter',
        message:
          'Dette er et stand-alone-program, der opretter en projektfil ud fra designfiler.',
        button: 'Hent',
      },
      retrofitBasicInfoCreate: {
        uuid: 'Automatisk nummerering',
      },
      retrofitBulkActions: {
        uploadFileDownload: 'Hent',
        resultFileDownload: 'Hent',
      },
      tasks: {
        coordinate: 'Koordinater (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
      },
      compactionWorkAreas: {
        blockSize: 'Blokstørrelse (%{unit})',
      },
      compactionLayers: {
        thickness: 'Tykkelse (%{unit})',
      },
      pendingRentals: {
        breadcrumbs: 'Detaljer',
        rentalDetail: 'Opgavedetaljer',
      },
      pendingTransfers: {
        breadcrumbs: 'Detaljer',
        transferDetail: 'Overførselsdetaljer',
      },
      pendingAlternateRegists: {
        breadcrumbs: 'Detaljer',
        alternateRegistDetail: 'Controller Proxy Registreringsoplysninger',
      },
      supportMode: {
        retrofit: 'Maskin',
        optional: 'Valgfri',
      },
      retrofitList: {
        assignment: 'Opgave',
        license: 'Licens',
      },
      userNotices: {
        languageDetails: 'Notifikationsindhold',
        language: 'Sprog',
        languageEn: 'English',
        languageJa: 'Japansk',
        languageFr: 'Fransk',
        languageDe: 'Tysk',
        languageEs: 'Spansk',
        Information: 'Underretning',
        Maintenance: 'Vedligeholdelse',
        emergency: 'Nød',
        important: 'Viktig',
      },
      userNoticeDrawer: {
        more: '',
        close: '',
      },
      retrofitsManage: {
        menuTitle: 'Controllerregistrering',
        corporationName: 'Virksomhedsnavn',
        basicInfoSerialNumber: 'Serienummer',
        confirmInput: 'Indtast serienummer igen',
      },
      retrofitNamePlateFiles: {
        serialNumber: 'Serienummer',
        status: 'Status',
        imageFile: 'Billede',
        message1:
          'Tag et billede af controllerens serienummer eller QR-kode, der er klart og uskarp.',
        message2: 'Billeder skal være mindre end %{mbyte} MB.',
        errormessage:
          'Det maksimale antal controllere, der kan registreres på én gang, er fem.',
      },
      retrofitCorporationLicenses: {
        licenseStatusChoices: {
          enable: 'Gyldig',
          disable: 'Ugyldig',
          unLicense: 'Ikke allokeret',
        },
        machine: 'Maskin',
        before: 'Før',
        after: 'Efter',
      },
      geofences: {
        project: 'Projekt',
        projectSourceLayer: 'Lag',
        rgb: 'Farve(RGB)',
        select: 'Valg geofence',
        coordinates: 'Koordinater',
        coordinatesLabel: 'Koordinater (N,E)',
        unitLength: 'Enhed:%{unit}',
      },
      geofenceDialog: {
        coordinate: 'Koordinater (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
        radius: 'Radius',
      },
      retrofitAlternateRegists: {
        menuTitle: 'Controller Proxy Registrering',
        corporationName: 'Virksomhedsnavn',
        email: 'E-mail',
        basicInfoSerialNumber: 'Serienummer',
        confirmInput: 'Indtast serienummer igen',
      },
      ntripList: {
        unusedTotal: 'Ubrugt i alt',
      },
      calibrationDetail: {
        breadcrumbs: 'Detaljer',
        machineInfo: 'Maskinoplysninger',
        machineGeometryInfo: 'Info om maskingeometri',
        bodyCenterToBoomFootPin: '',
        imuMountInfo: 'IMU-indlæs.info',
        gnssMountInfo: '',
        bodyCenterToGnssMain: 'GNSS-hov.indlæs.info',
        bodyCenterToGnssSub: 'Hov.-GNSS-indlæs.info',
        extendedModel: 'Udvidet model',
        measuredValues: 'Info om kalibrering',
      },
      siteList: {
        statusChoices: {
          completed: 'Fuldført',
          waiting: 'Før byggeriet begynder',
          working: 'Under opførelse',
          waitingworking: 'Før byggeriet begynder/Under opførelse',
        },
      },
    },
    errorInfo: {
      projects: {
        system_scapi_error: 'Datakonverteringsfejl',
        system_localization_quantity_error: `Flere filer, der indeholder lokaliseringsoplysninger, kan ikke registreres.
      Registrer venligst kun én fil, der indeholder lokaliseringsoplysninger.`,
        system_detect_0byte_file_error: `Filregistrering til serveren mislykkedes på grund af en kommunikationsfejl.
      Registrer venligst filen igen.`,
        system_batch_error: 'Systemfejl (P001)',
        system_parameter_error: 'Systemfejl (P002)',
        system_parameterfile_notfound: 'Systemfejl (P003)',
        system_exception: 'Systemfejl (P004)',
        system_logfile_error: 'Systemfejl (P005)',
        system_parameterfile_parameter_error: 'Systemfejl (P006)',
        desing_localization_error: `Der er ingen lokaliseringsoplysninger tilgængelige.
      Registrer venligst en af følgende filer:

      ・GC3-fil
      ・TP3-fil med kontrolpunkter
      ・CAL-fil`,
        desing_no_support_dxf_error:
          'DXF-fil(er) kunne ikke læses, fordi dens version er ældre end AutoCAD2000.',
        desing_tile_error: 'Kunne ikke oprette projektfil.',
        desing_outline_error: 'Oprettelse af ydre grænse linje mislykkedes',
        desing_area_length_error:
          'Processen er blevet afbrudt, fordi projektområdet er for stort. Kontrollér, om designdataene og CAD-dataenes målestok er korrekte.',
        desing_layer_name_duplication_error: `Projektdata med duplikerede lagernavne kan ikke registreres.
      Skift venligst navnet på designlaget eller registrer, så der kun er én fil med samme designlagernavn.`,
        targetFiles: '[Filnavn]',
        targetLayers: '[Lag]',
        errorFiles: '・%{errorFile}',
      },
      tasks: {
        none: 'Ingen',
        system_batch_error: 'Systemfejl (T001)',
        system_parameter_error: 'Systemfejl (T002)',
        system_parameterfile_notfound: 'Systemfejl (T003)',
        system_exception: 'Systemfejl (T004)',
        system_logfile_error: 'Systemfejl (T005)',
        system_basefile_notfound: 'Systemfejl (T006)',
        system_prjfile_multi: 'Systemfejl (T007)',
        system_basefile_read_error: 'Systemfejl (T008)',
        system_target_layer_notfound: 'Systemfejl (T009)',
        system_target_layer_duplication: 'Samme lagernavn findes allerede.',
        system_taskarea_error: 'Ugyldigt opgaveområde',
        system_taskarea_design_notfound:
          'Der er ingen data til projektfilen inden for opgaveområdet (TIN, linework, punkter).',
        system_tile_error: 'Kunne ikke oprette projektfil.',
        system_outline_error: 'Oprettelse af ydre grænse linje mislykkedes',
        system_parameterfile_error: 'Systemfejl',
      },
      retrofitError: {
        1000: 'UDP-data fra styreenhed ikke detekteret',
        2000: 'IMU for karrosseri ikke detekteret',
        2001: 'Fejl i IMU-hardware for karosseri',
        2002: 'Fejl i IMU-algoritme for karosseri',
        2003: 'Fejl for IMU-data for karrosseri',
        2004: 'Fejl i IMU-initialisering for karosseri',
        2100: 'IMU for bom ikke detekteret',
        2101: 'Fejl i IMU-hardware for bom',
        2102: 'Fejl i IMU-algoritme for bom',
        2103: 'Fejl for IMU-data for bom',
        2104: 'Fejl i IMU-initialisering for bom',
        2200: 'IMU for arm ikke detekteret',
        2201: 'Fejl i IMU-hardware for arm',
        2202: 'Fejl i IMU-algoritme for arm',
        2203: 'Fejl for IMU-data for arm',
        2204: 'Fejl i IMU-initialisering for arm',
        2300: 'IMU for skovl ikke detekteret',
        2301: 'Fejl i IMU-hardware for skovl',
        2302: 'Fejl i IMU-algoritme for skovl',
        2303: 'Fejl for IMU-data for skovl',
        2304: 'Fejl i IMU-initialisering for skovl',
        2400: 'IMU for krængningsskovl ikke detekteret',
        2401: 'Fejl i IMU-hardware for krængningsskovl',
        2402: 'Fejl i IMU-algoritme for krængningsskovl',
        2403: 'Fejl i IMU-data for krængningsskovl',
        2404: 'Fejl i IMU-initialisering for krængningsskovl',
        2500: '2. bom IMU ikke detekteret',
        2501: 'Fejl i IMU-hardware for 2. bom',
        2502: 'Fejl i IMU-algoritme for 2. bom',
        2503: 'Fejl i IMU-data for 2. bom',
        2504: 'Fejl i IMU-initialisering for 2. bom',
        2600: 'Svingvinkelsensor ikke opdaget',
        2601: 'Systemfejl i svingvinkelsensoren',
        2602: 'Fejl i svingvinkelsensorens hardware',
        2700: 'Forskydningssensor ikke opdaget',
        2701: 'Systemfejl i Forskydningssensor',
        2702: 'Fejl i Forskydningssensor hardware',
        3000: 'Tryksensor på hovedside ikke detekteret',
        3100: 'Tryksensor på bundside ikke detekteret',
        4000: 'GNSS-antenne ikke detekteret',
        5000: 'Korrektionsoplysninger ikke detekteret',
        5001: 'Radiomodtagersignal ikke detekteret',
      },
    },
    dialog: {
      menu: {
        confirmManualLink:
          'Du vil blive omdirigeret til en anden hjemmeside. Er det i orden?',
      },
      bucketEdit: {
        deleteConfirm: {
          messageTargetItem: 'Skovl',
        },
      },
      retrofitBasicInfoList: {
        saveValidation: {
          message: 'Virksomheds-ID og virksomhedsnavn er påkrævet.',
        },
        save: {
          message1: 'Virksomheds-ID/navn kan ikke ændres, fordi det indeholder',
          message2: 'maskine(r) tildelt til et andet firma.',
          message3: '',
          message4: 'Kontroller de nedenstående serienumre',
        },
      },
      retrofitBasicInfoEdit: {
        deleteConfirm: {
          messageTargetItem: 'Styreenhed',
        },
        undeletable: {
          message: 'Controlleren er i brug og kan ikke slettes.',
        },
      },
      Ntrip: {
        save: {
          stop: 'Vil du stoppe denne Ntrip-indstilling?',
          delete: 'Vil du slette Ntrip-indstilling?',
        },
      },
      siteRetrofitList: {
        title: 'Supplerende oplysninger',
        message: 'Byggeperioden er afsluttet.',
      },
      siteConfigureEdit: {
        title: {
          commonSetting: 'Fælles indstilling',
          siteSetting: 'Byggepladsindstillinger',
        },
        mapBackgroundColor: 'Baggrundsfarve',
        message:
          'Funktionen til detektions-/advarselsmeddelelse ved kontakt med geohegn vil muligvis ikke fungere korrekt på grund af det miljø og de betingelser, hvori den bruges. Stol ikke ukritisk på denne funktion, og sørg for, at du forstår funktionen og anvendelsesbetingelserne.',
      },
      geofenceList: {
        confirmDelete: {
          message: 'Vil du slette denne geofence?',
        },
      },
      retrofitRentalCreate: {
        unableTieUpJobSite: {
          message1:
            'Maskinen er knyttet til en arbejdsplads og kan ikke tildeles. Frakobl venligst maskinen fra arbejdsstedet.',
          message2: 'Afbryd venligst maskinen fra byggepladsen.',
        },
        sameOwner: {
          message: 'Maskinen kan ikke tildeles, fordi din virksomhed er ejer.',
        },
        unableTransfer: {
          message:
            'Maskinen afventer overførselsgodkendelse og kan ikke registreres til tildeling.',
        },
        unableAlternateRegist: {
          message:
            'Maskinen afventer Controller Proxy Registration Approval og kan ikke registreres til tildeling.',
        },
      },
      retrofitTransferCreate: {
        messageSiteId:
          'Maskinen er knyttet til en byggeplads og kan ikke overføres. Frakobl venligst maskinen fra byggepladsen.',
        messageRentaled: 'Maskinen er tildelt og kan ikke overføres.',
        messageLicense: 'Maskinen har en licens og kan ikke overføres.',
        pendingApprovals: {
          message1:
            'Overførselsanmodningen er modtaget. Vent venligst på godkendelsen.',
          message2:
            'Hvis du har spørgsmål, skal du kontakte os fra følgende URL.',
        },
      },
      retrofitSendBacks: {
        submit:
          'Vil du sende controlleren tilbage og arve indstillingerne til målcontrolleren?',
        deleteNtrip: 'Vil du initialisere den returnerede controller?',
        unableRegion:
          'Denne controller er blevet sendt til udlandet. Hvis denne controller er den korrekte, nulstil "Region" til null.',
        unableCorporation: {
          message1: 'Controlleren kan ikke sendes tilbage.',
          message2:
            'Hvis du tror, dette er en fejl, skal du kontakte din forhandler.',
        },
        retrofitRentaled: 'Controlleren er tildelt og kan ikke sendes tilbage.',
        tiedToSite:
          'Maskinen er knyttet til en arbejdsplads og kan ikke sendes tilbage.',
      },
      retrofitAddToSiteAction: {
        title: 'Supplerende oplysninger',
        message:
          'Der er en maskine, der venter på godkendelse, så den kan ikke linkes til arbejdsstedet.',
      },
      retrofitAddToSiteActionTransfer: {
        title: 'Supplerende oplysninger',
        message:
          'Maskinen er ved at blive overført og kan ikke knyttes til arbejdsstedet.',
      },
      retrofitEdit: {
        unableSettingGnessInfo: {
          message:
            'Den valgte Ntrip-indstilling bliver sendt til en anden maskine.',
        },
        unableTransferInfo: {
          message: 'For at overføre, annuller følgende indstillinger.',
          reasonSiteId: 'Byggeplads',
          reasonRentaled: 'Opgave',
          reasonLicense: 'Licens',
        },
      },
      retrofitRentalEdit: {
        overDoubleRentalStartDate: {
          message1:
            'Det tildelte firma tildeler i øjeblikket maskinen til en anden virksomhed. Startdatoen for tildelingen til en anden virksomhed er %{date}.',
          message2: 'Vælg en slutdato efter %{date}.',
        },
        confirmTieUpJobsite: {
          message:
            'Maskinen er knyttet til en byggeplads hos den tildelte virksomhed. Ønsker du at afslutte denne opgave?',
        },
        confirmDoubleRental: {
          message:
            '"Det tildelte firma tildeler i øjeblikket maskinen til en anden virksomhed. Det tildelte firma tildeler i øjeblikket maskinen til en anden virksomhed. Indstilling af en slutdato afslutter også tildelingen til en anden virksomhed. Vil du indstille slutdatoen?"',
        },
        unapprovedSave: {
          message: 'Denne opgave er ikke blevet godkendt og kan ikke gemmes.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Vil du slette denne opgave?',
        },
        approvedDelete: {
          title: '',
          message: 'Denne opgave er blevet godkendt og kan ikke slettes.',
        },
      },
      retrofitTransferEdit: {
        unapprovedSave: {
          title: '',
          message: 'Maskinen er i øjeblikket på opgave og kan ikke overføres.',
        },
        approvedSave: {
          message: 'Maskinen har en licens og kan ikke overføres.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Vil du slette denne overførsel?',
        },
        approvedDelete: {
          title: '',
          message: 'Denne overførsel er blevet godkendt og kan ikke slettes.',
        },
      },
      extensionarmEdit: {
        deleteConfirm: {
          messageTargetItem: 'Forlængerarm',
        },
      },
      projectFileUpload: {
        message: 'Flere kontrolpunktfiler (.gc3/.cal) kan ikke registreres.',
      },
      projectEdit: {
        confirm: {
          message:
            'Når du opdaterer projektet, slettes de eksisterende opgaver. Ønsker du at opdatere?',
        },
      },
      projectConversionStatus: {
        title: 'Konverteringsstatus',
        button: 'OK',
      },
      taskConversionStatus: {
        title: 'Konverteringsstatus',
        button: 'OK',
      },
      taskList: {
        undeletable: {
          message: 'Den valgte opgave kan ikke slettes, fordi den er i brug.',
        },
        unableToCreate: {
          message: 'Der er ikke registreret konstruktionsdata i projektet.',
        },
        unableToDelete: {
          message1:
            'Opgaven kan ikke slettes, fordi status for projektet og status for opgaven er som følger.',
          message2: 'Projekt: Konvertering',
          message3: 'Opgave: Fejl',
        },
        unableToUpdate: {
          message1:
            'Opgaven kan ikke redigeres, fordi status for projektet og status for opgaven ikke er som følger.',
          message2: 'Projekt: [Konverteret]',
          message3: 'Opgave: [Overførsel][Overført][Overførsel mislykkedes]',
        },
      },
      taskEdit: {
        deleteConfirm: {
          messageTargetItem: 'Opgave',
        },
        unableToDelete: {
          message: 'Den valgte opgave kan ikke slettes, fordi den er i brug.',
        },
      },
      taskMap: {
        crossingMessage: 'Opgaveområdet er gennemskåret.',
        sameValueMessage: 'De samme koordinater er blevet indtastet.',
        areaZeroMessage: 'Der er ikke noget område til opgaveområdet.',
        noPolygonMessage:
          'Opgaven kan ikke registreres, fordi den endnu ikke er registreret.',
      },
      compactionMaterials: {
        add: {
          title: 'Tilføj et materiale',
        },
        update: {
          title: 'Opdater materiale',
        },
      },
      weathers: {
        add: {
          title: 'Tilføj et vejr',
        },
        update: {
          title: 'Opdater vejr',
        },
      },
      operators: {
        add: {
          title: 'Tilføj en operatør',
        },
        update: {
          title: 'Opdater operatør',
        },
      },
      compactionWorkAreas: {
        update: {
          message: 'Rediger komprimeringsarbejdsområder',
        },
        undeletable: {
          message:
            'Det komprimerede lag inkluderet i byggeområdet er i brug og kan ikke slettes.',
        },
      },
      compactionLayers: {
        undeletable: {
          message: 'Det komprimerede lag er i brug og kan ikke slettes.',
        },
        deleteConfirm: {
          messageTargetItem: 'Komprimeringlag',
        },
      },
      gnssSettings: {
        unableSettingGnessInfo: {
          message:
            'Der sendes en anden Ntrip-indstilling til den valgte maskine.',
        },
        confirmDelete: {
          message: 'Vil du slette Ntrip-indstilling?',
        },
        confirmDeleteWithMachine: {
          message:
            'Maskinen er i øjeblikket linket. Ønsker du at slette Ntrip-indstillingen?',
        },
      },
      userNoticeEdit: {
        deleteConfirm: {
          messageTargetItem: 'Underretning',
        },
      },
      retrofitCorporationLicenses: {
        confirm: {
          message: 'Indstil licens(er) til følgende maskine.',
        },
        changeDisable: {
          message: "Skift licens-ID'et for følgende byggemaskiner til gyldigt.",
        },
        changeTemporary: {
          message:
            'Indstil licens(er) til følgende maskine. Den nuværende midlertidige licens vil blive ugyldig.',
        },
        errorAdd: {
          message:
            'Maskinens licens kan ikke ændres, fordi Alle licenser allerede er indstillet.',
        },
        errorSameType: {
          message:
            'Maskinens licens kan ikke ændres, fordi den samme type licens allerede er indstillet.',
        },
        errorUnlimited: {
          message:
            'Maskinen har allerede indstillet ubegrænset licens, andre licenser kan ikke indstilles.',
        },
        addAll: {
          message:
            'Følgende maskine har allerede fået indstillet licens(er). Vil du indstille alle licenser?',
        },
        compare: {
          message: 'Ændr licenserne for følgende maskine(r).',
        },
        warning: {
          message: '%{retrofit} har licens af %{id}. Vil du afbryde licensen?',
        },
        unableTransfer: {
          message:
            'Maskinen er ved at blive overført, så licensen ikke kan linkes.',
        },
      },
      retrofitNamePlateFiles: {
        error: {
          serial: 'Der er data, der ikke lykkedes at analysere billedet.',
        },
      },
      retrofitManageTab: {
        pendingApprovals: {
          message1:
            'Registreringsanmodningen er blevet modtaget. Vent venligst, indtil den er godkendt.',
          message2:
            'Hvis du har spørgsmål, skal du kontakte os fra følgende URL.',
        },
        corporationIdCheck: {
          message1:
            'Controlleren er allerede blevet registreret. Kontroller ved at søge i maskinerne.',
          message2:
            'Hvis du ikke kan finde målkontrolleren på listen, skal du kontakte os fra følgende URL.',
        },
        earthbrainIdCheck: {
          message1: 'Vi beklager ulejligheden',
          message2:
            "men kontakt os venligst fra URL'en nedenfor med følgende oplysninger.",
          message3: '-Controller serienummer',
          message4: '-Navn på maskinen som skal registreres (valgfrit)',
          message5: '-Virksomheds-ID, der skal registreres',
        },
        invalidSerialNumber: {
          message1: 'Controlleren er allerede i brug af et andet firma.',
          message2: 'Hvis du ønsker at ændre det af andre årsager, ',
          message3:
            'Kontakt os venligst på den nedenstående URL med følgende oplysninger.',
          message4: '-Controller serienummer',
          message5: '-Navn på maskinen som skal registreres (valgfrit)',
          message6: '-Virksomheds-ID, der skal registreres',
          message7: '-Årsag til ændring',
        },
        lendingRetrofits: {
          message1: 'Controlleren er tildelt.',
          message2:
            'Ansøg venligst igen efter, at tildelingen er afsluttet hos tildelingsvirksomheden.',
          message3:
            'Hvis du har spørgsmål, skal du kontakte os fra følgende URL.',
        },
        siteAttached: {
          message1: 'Controlleren er knyttet til en arbejdsplads.',
          message2:
            'Ansøg venligst igen efter at have afbrudt maskinen fra byggepladsen.',
          message3:
            'Hvis du har spørgsmål, skal du kontakte os fra følgende URL.',
        },
        serialNumberExistError: {
          message1:
            'Dette er serienummeret på en controller, der ikke eksisterer. Sørg for, at det controllerserienummer, du har indtastet, er korrekt.',
          message2:
            'Hvis problemet fortsætter, skal du kontakte os fra følgende URL.',
        },
        ntripShortage: {
          message1: 'Vi kunne ikke udstede din Ntrip-bruger-ID og adgangskode.',
          message2: 'Kontakt os venligst på den nedenstående URL.',
        },
        serialnumberCheck: {
          message: 'Kontroller produkterne med de følgende serienumre',
        },
      },
      retrofitAlternateRegists: {
        deleteConfirm: {
          messageTargetItem: 'retrofitAlternateRegists',
        },
        saveValidation: {
          message: 'E-mail er påkrævet',
        },
        pendingApprovals: {
          message1:
            'Registreringsanmodningen er modtaget. Vent indtil den er godkendt.',
          message2:
            'Hvis du har spørgsmål, skal du kontakte os fra følgende URL.',
        },
        corporationIdCheck: {
          message1:
            'Controlleren er allerede blevet registreret. Kontroller ved at søge i maskinerne.',
          message2:
            'Hvis du ikke kan finde målkontrolleren på listen, skal du kontakte os fra følgende URL.',
        },
        earthbrainIdCheck: {
          message1: 'Vi beklager ulejligheden',
          message2:
            "men kontakt os venligst fra URL'en nedenfor med følgende oplysninger.",
          message3: '-Controller serienummer',
          message4: '-Navn på maskinen som skal registreres (valgfrit)',
          message5: '-Virksomheds-ID, der skal registreres',
        },
        invalidSerialNumber: {
          message1: 'Controlleren er allerede i brug af et andet firma.',
          message2: 'Hvis du ønsker at ændre det af andre årsager, ',
          message3:
            'Kontakt os venligst på den nedenstående URL med følgende oplysninger.',
          message4: '-Controller serienummer',
          message5: '-Navn på maskinen som skal registreres (valgfrit)',
          message6: '-Virksomheds-ID, der skal registreres',
          message7: '-Årsag til ændring',
        },
        lendingRetrofits: {
          message1: 'Controlleren er tildelt.',
          message2:
            'Ansøg venligst igen efter, at tildelingen er afsluttet hos tildelingsvirksomheden.',
          message3:
            'Hvis du har spørgsmål, skal du kontakte os fra følgende URL.',
        },
        siteAttached: {
          message1: 'Controlleren er knyttet til en arbejdsplads.',
          message2:
            'Ansøg venligst igen efter at have afbrudt maskinen fra byggepladsen.',
          message3:
            'Hvis du har spørgsmål, skal du kontakte os fra følgende URL.',
        },
        serialNumberExistError: {
          message1:
            'Dette er serienummeret på en controller, der ikke eksisterer. Sørg for, at det controllerserienummer, du har indtastet, er korrekt.',
          message2:
            'Hvis problemet fortsætter, skal du kontakte os fra følgende URL.',
        },
        ntripShortage: {
          message1: 'Vi kunne ikke udstede din Ntrip-bruger-ID og adgangskode.',
          message2: 'Kontakt os venligst på den nedenstående URL.',
        },
        serialnumberCheck: {
          message: 'Kontroller produkterne med de følgende serienumre',
        },
      },
      pendingApprovals: {
        ownerCorporation: {
          message1: 'Denne controller er allerede ejet af din virksomhed.',
          message2: 'Kontakt venligst ansøgerfirmaet.',
        },
        userCorporation: {
          message1:
            'Du kan ikke godkende denne anmodning, fordi du allerede udlåner denne controller.',
          message2: 'Kontakt venligst ansøgerfirmaet.',
        },
      },
      licensePurchaseNotice: {
        button: 'OK',
        message1: `Der er en maskine i brug på stedet uden en licens.
        En licens er påkrævet for brug på stedet.
        Hvis du ikke har købt en licens, bedes du købe en fra Smart Construction Marketplace.`,
        message2: `
        Hvis du lejer fra et andet firma, bedes du anmode om en licens til maskinen fra udlejningskilden.

        Hvis du har spørgsmål, bedes du tjekke følgende supportside.`,
      },
      siteBuriedStructureEdit: {
        title: 'Buried Structure',
      },
      filePicker: {
        title: 'Import From Groupware',
        breadcrumbHome: 'Home',
        selectedFile: 'Selected Files',
        buttonLabel: 'Import From Groupware',
        errorMessage: 'The selected file(s) do not exist in Groupware.',
        footer: 'and %{remainingCount} more',
        undefinedResourceOwner: {
          message1: 'Could not import the file(s) from Groupware.',
          message2:
            'Please confirm you are registered as a member of the site.',
        },
      },
    },
    tootip: {
      retrofitShareFileList: 'Ansøgningslog',
      retrofitConfigList: 'Konfigurationsinfo',
      retrofitKitInfoList: 'Styreenhedsopl.',
      retrofitAccuraciesList: 'Nøjagtighedskontrol resultat',
      retrofitCalibaList: 'Info om kalibrering',
      retrofitBasicSettingList: 'Grundindstillinger',
      retrofitErrorList: 'Fejldetaljer',
      retrofitTopographicSurveyList: 'Topografisk undersøgelse',
      retrofitAsbuilts: 'Byggeresultater',
    },
    actions: {
      ok: 'OK',
      add: 'Tilføj',
      create: 'Registrering',
      yes: 'Ja',
      no: 'Ne',
      confirmation: 'Bekræft',
      save: 'GEM',
      cancel: 'Annuller',
      required: 'Påkrævet',
      addMachineToSite: 'Tilføj maskine til arbejdspladsen',
      addBucketToSite: 'Tilføj',
      deleteFromSite: 'Frakoble fra Jobsite',
      removeLicense: 'Frakoble fra maskinen',
      lendMachine: 'Opgave',
      transferMachine: 'Overførsel',
      remoteSupport: 'Fjernsupport',
      lendBucket: 'Del',
      approval: 'Godkendelse',
      connect: 'Forbindelse',
      creationInstructions: 'Opnå byggeresultat',
      csvDownload: 'Hent',
      retrofitBundleCreate: 'Bulk registrering',
      termsAccept: 'Enig',
      termsDeny: 'Uenig',
      bulkRegistration: 'Bulk opdatering',
      bulkRegistrationOK: 'OK',
      compactionItems: 'Komprimeringsemner',
      addLanguage: 'Tilføj sprog',
    },
    message: {
      error: 'Der er opstået en fejl',
      confirm: 'Er det ok?',
      fileUpload: 'Træk og slip filer her.',
      success: 'Registreringen er fuldført',
      approved: 'Godkendelsen er blevet fuldført',
      deleteConfirmTitle: '',
      deleteConfirmContent: 'Vil du slette %{title}?',
      bulkDeleteConfirmContent: 'Vil du slette %{title}?',
      disconnectFromSite: 'Vil du frakoble fra Jobsite?',
      saveConfirmContent: 'Bekræft',
      removeLicense: 'Vil du frakoble fra maskinen?',
      task: {
        crossingMessage: 'Opgaveområdet er gennemskåret.',
        sameValueMessage: 'De samme koordinater er blevet indtastet.',
        areaZeroMessage: 'Der er ikke noget område til opgaveområdet.',
        invalidPolygonMessage: 'De samme koordinater er blevet indtastet.',
      },
      geofence: {
        crossingMessage: 'Geofence-området er gennemskåret.',
        sameValueMessage: 'De samme koordinater er blevet indtastet.',
        areaZeroMessage: 'Der er ikke noget område til geofence-området.',
      },
      licenseError:
        'Du har ikke en licens til Smart Construction Pilot(Web App).',
      retrofitsManage: {
        serialNumberMatchError: 'Serienummer matcher ikke.',
        serialNumberInputError: 'Serienummer findes ikke.',
        invalidAccess:
          'Du er ikke autoriseret til at se denne side, vend tilbage til forsiden.',
      },
      retrofitsAlternateRegistText: {
        serialNumberMatchError: 'Serienummer matcher ikke.',
        serialNumberInputError: 'Serienummer findes ikke.',
        retrofitRentaled:
          'Virksomhedsoplysninger kan ikke opdateres, mens controlleren er på opgave.',
        invalidCorporation:
          'Da din virksomhed ikke er en distributør, kan du ikke udføre proxyregistrering af controlleren.',
      },
    },
    validation: {
      startDate: 'Startdato',
      endDate: 'Slutdato',
      invalidDateFormat: 'Datoformatet er ugyldigt.',
      invalidDateTimeFormat: 'Dato- og tidsformatet er ugyldigt.',
      duplicateProject: 'Projekt navn er dupliceret',
      incorrectBeforeDate: '%{target} skal indstilles før %{before}.',
      incorrectAfterDate: '%{target} skal indstilles efter %{after}.',
      incorrectPlanStartDate:
        'Planlagt startdato skal være før den planlagte slutdato.',
      incorrectPlanEndDate:
        'Planlagt slutdato skal være en dato efter den planlagte startdato.',
      incorrectActualStartDate: 'Startdatoen skal være før slutdatoen.',
      incorrectActualEndDate:
        'Slutdatoen skal indstilles til en dato efter startdatoen.',
      withActualStartDate: 'Angiv også startdatoen.',
      inputAvailableDate: 'Datoen, der kan indtastes, er fra %{date}.',
      registrationRequired:
        'Vælg enten det tildelte firma eller e-mailadresse.',
      bothInput:
        'Du kan kun indtaste enten det tildelte firma eller e-mailadressen.',
      supportMode: 'Angiv enten et firma-ID eller et byggeplads-ID.',
      invalidBkFile: 'Det er ikke en BK3-fil.',
      invalidPdfFile: 'Det er ikke en PDF-fil.',
      duplicatedBucketName: 'Spandnavn er duplikeret',
      duplicatedTaskName: 'Opgavenavn er duplikeret.',
      duplicateGeofenceName: 'Geofence-navnet er dupliceret',
      duplicatedCompactionWorkAreaName:
        'Dupliceret komprimeringsarbejdsområdenavn',
      duplicatedCompactionLayerName: 'Dupliceret komprimeringslagsnavn',
      duplicated: {
        compactionMaterials: {
          message: 'Dupliceret materiale navn',
        },
        weathers: {
          message: 'Dupliceret vejrnnavn',
        },
        operators: {
          message: 'Dupliceret operatørnavn',
        },
      },
      containInvalidString:
        'Der er inkluderet ugyldige tegn. (%{msg} er ikke tilladt)',
      validHalfWidthAlphanumeric:
        'Kun halvbrede alfanumeriske tegn er tilladt.',
      validNumberFormat: 'Ikke et nummer.',
      validIntegerFormat: 'Ikke et heltal.',
      validIntegerRangeFormat: 'Input er tilladt fra 0 til 2147483647.',
      validDuplicateLanguage: 'Det samme sprog er valgt.',
      validBeforeDate:
        '%{target} skal indstilles til en dato og tid før %{before}.',
      validAfterDate:
        '%{target} skal indstilles til en dato og tid efter %{after}.',
    },
  },
  resources: {
    sites: {
      name: 'Byggeplads',
      fields: {
        name: 'Byggeplads',
        corporationName: 'Virksomhedsnavn',
        status: 'Status',
        workPeriodStartDate: 'Startdato',
        workPeriodEndDate: 'Slutdato',
      },
      status: {
        completed: 'Fuldført',
        waiting: 'Før byggeriet begynder',
        working: 'Under opførelse',
      },
    },
    retrofits: {
      name: 'Maskin',
      fields: {
        information: 'Maskinoplysninger',
        rental: 'Opgave',
        sendBack: 'Send tilbage',
        corporationId: 'Virksomhed i brug',
        siteId: 'Byggeplads',
        uuid: 'UUID',
        isError: 'Status',
        isOnline: 'Online',
        lastOnlineTime: 'Seneste forbindelse',
        basicInfoManufacturer: 'Producent',
        basicInfoModel: 'Model',
        basicInfoProductNumber: 'Produktnr.',
        basicInfoSerialNumber: 'Serienummer',
        controllerManufacturer: 'Producent',
        controllerModel: 'Model',
        controllerFirmwareVersion: 'Firmwarevers.',
        gnssReceiverMainManufacturer: 'Producent',
        gnssReceiverMainModel: 'Model',
        gnssReceiverMainFirmwareVersion: 'Firmwarevers.',
        gnssReceiverSubManufacturer: 'Producent',
        gnssReceiverSubModel: 'Model',
        gnssReceiverSubFirmwareVersion: 'Firmwarevers.',
        machineInfoCompanyName: 'Producent',
        machineInfoMachineType: 'Maskintype',
        machineInfoMachineName: 'Maskinnavn',
        machineInfoMachineId: 'Maskin-ID',
        rentalStatus: 'Opgave',
        lastUpdated: 'Senest opdateret',
      },
      isError: {
        true: 'Format for meddelelse om controller-status er forkert',
        false: 'Normal',
      },
      isOnline: {
        true: 'Online',
        false: 'Offline',
      },
      machineInfoMachineType: {
        excavator: 'Gravko',
        roadroller: 'Vejvalse',
        dozer: 'Bulldozer',
        rigiddump: 'Stiv kipper',
        wheelloader: 'Hjullæsser',
        grader: 'Grader',
        automobile: 'Automobil',
      },
      licenseMachineType: {
        excavator: 'Gravko',
        roadroller: 'Vejvalse',
        dozer: 'Bulldozer',
        rigiddump: 'Stiv kipper',
        wheelloader: 'Hjullæsser',
        grader: 'Grader',
        automobile: 'Automobil',
        excavator_license: 'Gravko : %{licenseGrantInfo}',
        roadroller_license: 'Vejvalse : %{licenseGrantInfo}',
        dozer_license: 'Bulldozer : %{licenseGrantInfo}',
        rigiddump_license: 'Stiv kipper : %{licenseGrantInfo}',
        wheelloader_license: 'Hjullæsser: %{licenseGrantInfo}',
        grader_license: 'Grader : %{licenseGrantInfo}',
        automobile_license: 'Automobil: %{licenseGrantInfo}',
      },
      gnssSettingStatus: {
        sending: 'Overførsel',
        sent: 'Overført',
        failtosend: 'Overførsel mislykkedes',
      },
      rentalStatus: {
        approved: 'Godkendt',
        unapproved: 'Ikke godkendt',
        returned: 'Returneret',
      },
      controllerStatusCode: {
        version: {
          1: '',
          2: '',
        },
        ahrsEstimateStatus: {
          0: '',
          1: '',
          2: '',
          3: '',
          4: '',
          5: '',
          6: '',
        },
        positionStatus: {
          0: '',
          1: '',
          2: '',
          3: '',
          4: 'GNSS-antenne ikke detekteret',
        },
        baseStationConnectionStatus: {
          0: '',
          1: '',
          2: '',
        },
        boomImuStatus: {
          0: '',
          1: 'IMU bom ikke detekteret',
          2: 'IMU bom ikke detekteret',
          3: 'IMU bom ikke detekteret',
        },
        armImuStatus: {
          0: '',
          1: 'IMU arm ikke detekteret',
          2: 'IMU arm ikke detekteret',
          3: 'IMU arm ikke detekteret',
        },
        bucketImuStatus: {
          0: '',
          1: 'IMU skovl ikke detekteret',
          2: 'IMU skovl ikke detekteret',
          3: 'IMU skovl ikke detekteret',
        },
        bodyImuStatus: {
          0: '',
          1: 'IMU karrosseri ikke detekteret',
          2: 'IMU karrosseri ikke detekteret',
          3: 'IMU karrosseri ikke detekteret',
        },
        boomHeadPressureSensorStatus: {
          0: '',
          1: 'Tryksensor på hovedside ikke detekteret',
          2: 'Tryksensor på hovedside ikke detekteret',
          3: 'Tryksensor på hovedside ikke detekteret',
        },
        boomBottomPressureSensorStatus: {
          0: '',
          1: 'Tryksensor på bundside ikke detekteret',
          2: 'Tryksensor på bundside ikke detekteret',
          3: 'Tryksensor på bundside ikke detekteret',
        },
        tiltBucketImuStatus: {
          0: '',
          1: 'IMU krængningsskovl ikke detekteret',
          2: 'IMU krængningsskovl ikke detekteret',
          3: 'IMU krængningsskovl ikke detekteret',
        },
        secondBoomImuStatus: {
          0: '',
          1: '2. bom IMU ikke detekteret',
          2: '2. bom IMU ikke detekteret',
          3: '2. bom IMU ikke detekteret',
        },
        rotarySensorStatus: {
          0: '',
          1: 'Forskydningssensor ikke opdaget',
          2: 'Forskydningssensor ikke opdaget',
          3: 'Forskydningssensor ikke opdaget',
          4: 'Forskydningssensor ikke opdaget',
          5: 'Forskydningssensor ikke opdaget',
          6: 'Forskydningssensor ikke opdaget',
        },
        boomOffsetSensorStatus: {
          0: '',
          1: 'Svingvinkelsensor ikke opdaget',
          2: 'Svingvinkelsensor ikke opdaget',
          3: 'Svingvinkelsensor ikke opdaget',
          4: 'Svingvinkelsensor ikke opdaget',
          5: 'Svingvinkelsensor ikke opdaget',
          6: 'Svingvinkelsensor ikke opdaget',
        },
        tiltrotatorStatus: {
          0: '',
          1: 'Tiltrotator ikke opdaget',
          2: 'Tiltrotator ikke opdaget',
          3: 'Tiltrotator ikke opdaget',
        },
      },
    },
    retrofitRentals: {
      name: 'Opgave',
      fields: {
        id: 'ID',
        retrofitId: 'Maskin-ID',
        fromCorporationId: 'Tildelende virksomheds-ID',
        fromCorporationName: 'Tildelende virksomhed',
        toCorporationId: 'Tildelt virksomhed',
        toCorporationName: 'Virksomhed i brug',
        retrofitRentalId: 'Opgave-ID',
        isApproved: 'Status',
        isReturned: 'Returneret',
        planStartDate: 'Planlagt startdato',
        planEndDate: 'Planlagt slutdato',
        actualStartDate: 'Startdato',
        actualEndDate: 'Slutdato',
        rentalUsers: 'E-mail',
        email: 'E-mail',
        rentalStatus: 'Status',
      },
      isApproved: {
        true: 'Godkendt',
        false: 'Ikke godkendt',
      },
      rentalStatus: {
        approved: 'Godkendt',
        unapproved: 'Ikke godkendt',
        returned: 'Returneret',
      },
    },
    pendingRentals: {
      name: 'Afventer godkendelse',
      fields: {
        fromCorporationName: 'Tildelende virksomhed',
        planStartDate: 'Planlagt startdato',
        planEndDate: 'Planlagt slutdato',
      },
    },
    pendingTransfers: {
      name: 'Afventer godkendelse',
      fields: {
        fromCorporationName: 'Overførselsselskab',
        planDate: 'Planlagt overførselsdato',
        lastUpdated: 'Senest opdateret',
      },
    },
    pendingAlternateRegists: {
      name: 'Afventer godkendelse',
      fields: {
        fromCorporationName: 'Nuværende ejer',
        lastUpdated: 'Senest opdateret',
      },
    },
    assignments: {
      name: 'Overførsel',
      fields: {
        id: 'ID',
        retrofitId: 'Maskin-ID',
        fromCorporationId: 'Overførselsselskabets ID',
        fromCorporationName: 'Overførselsselskab',
        toCorporationId: 'Overført virksomhed',
        toCorporationName: 'Virksomhed',
        isApproved: 'Status',
        planDate: 'Planlagt overførselsdato',
        actualDate: 'Overførselsdato',
        transferUsers: 'E-mail',
        email: 'E-mail',
        lastUpdated: 'Senest opdateret',
      },
      isApproved: {
        true: 'Godkendt',
        false: 'Ikke godkendt',
      },
    },
    buckets: {
      name: 'Skovl',
      fields: {
        companyName: 'Virksomhedsnavn',
        siteId: 'Byggeplads',
        name: 'Navn',
        corporationId: 'Virksomhed i brug',
        bucketType: 'Type',
        bk3File: 'BK3-fil',
        'bk3File.title': 'BK3-fil',
        gltfFile: 'Skovlfil',
        uploadedBy: 'Registreret af',
        updatedBy: 'Opdateret af',
        fileStatus: 'Status',
        lastUpdated: 'Senest opdateret',
        uploadBk: 'Upload BK3',
      },
      bucketType: {
        slope: 'Skråning',
        standard: 'Standard',
        tilt: 'Kippen',
        tiltrotator: 'Tilt rotator',
      },
      uploadedBy: {
        admin: 'Administrator',
        tablet: 'Tablet',
      },
      fileStatus: {
        converting: 'Konvertering',
        completed: 'Fuldført',
        error: 'Fejl',
      },
    },
    projects: {
      name: 'Projekt',
      fields: {
        name: 'Projektnavn',
        coordinate: 'Koordinatsystem',
        version: 'Version',
        status: 'Status',
        lastUpdated: 'Senest opdateret',
        regionId: 'Region',
        projectionId: 'Projektion',
        geoidId: 'Geoid',
        datumId: 'Dato',
        file: 'Fil(er)',
        retrofitId: 'Målrettet maskine',
      },
      status: {
        converted: 'Fuldført',
        waitforconvert: 'Konvertering',
        error: 'Fejl',
      },
    },
    extensionarms: {
      name: 'Forlængerarm',
      fields: {
        extensionarmType: 'Type',
        name: 'Navn',
        corporationId: 'Virksomhed i brug',
        corporationName: 'Virksomhed i brug',
        siteId: 'Byggeplads',
        siteName: 'Byggeplads',
        lastUpdated: 'Senest opdateret',
        extensionarmFile: 'Forlængerarmfil',
      },
      extensionarmType: {
        basic: 'Forlængerarm',
        a: 'Type A',
        b: 'Type B',
      },
    },
    extensionarmShares: {
      fields: {
        corporationId: 'Del til',
        name: 'Navn',
        extensionarmType: 'Type',
        extensionarmFile: {
          title: 'Forlængerarmfil',
        },
      },
    },
    corporations: {
      name: 'Virksomhed',
      fields: {
        name: 'Virksomhed i brug',
      },
    },
    retrofitShareFiles: {
      name: 'Ansøgningslog',
      fields: {
        name: 'Filnavn',
        fileTime: 'Modtagelsesdato og -tidspunkt',
        url: 'Hent',
      },
    },
    retrofitConfigs: {
      name: 'Konfigurationsinfo',
      fields: {
        timeStamp: 'Modtagelsesdato og -tidspunkt',
        url: 'Hent',
      },
    },
    retrofitKitInfos: {
      name: 'Styreenhedsopl.',
      fields: {
        timeStamp: 'Modtagelsesdato og -tidspunkt',
        url: 'Hent',
      },
    },
    retrofitCalibs: {
      name: 'Info om kalibrering',
      fields: {
        timeStamp: 'Modtagelsesdato og -tidspunkt',
        url: 'Hent',
        detailInfo: {
          createDateTime: 'Opret dato og klokkeslæt',
          updateDateTime: 'Opdater dato og klokkeslæt',
          machineInfo: {
            companyName: 'Producent',
            machineType: 'Maskintype',
            machineName: 'Maskinnavn',
            machineId: 'Maskin-ID',
            drumConfiguration: 'Konfiguration af maskine',
            guidanceMode: 'Vejledningstilstand',
          },
          machineGeometryInfo: {
            boomLength: 'Længde af bom',
            secondBoomLength: 'Længde af 2. bom',
            armLength: 'Længde af arm',
            armTopPinToBucketSideLinkPinLength:
              'Afst. ml. armtopstift og skovlledsstift i siden',
            boomTopPinToBucketSideLinkPinLength:
              'Afst. ml. bomtopstift og skovlledsstift i siden',
            bucketLink1Length:
              'Afst. ml. skovlstift i siden og skovlcylinderstift',
            bucketLink2Length: 'Afst. ml. skovlcylinderstift og skovlledsstift',
            bodyCenterToBoomFootPin: {
              x: 'Bom. sokkelleje X ift. karr.midte',
              y: 'Bom. sokkelleje Y ift. karr.midte',
              z: 'Bom. sokkelleje Z ift. karr.midte',
            },
            drumWidth: 'Bredde på bladet',
            length: 'Længde mellem skoene',
            shoeInterval: 'Interval længde af sko',
            bladeWidthForDozer: 'Bredde af klinge til dozer',
            distanceFromBodyToBlade: 'Afstand fra krop til blad',
            frontToMainGnssAntenna:
              'Længde fra midten af en lige linje, der forbinder den forreste jordede ende til hovedantennen X',
            frontToSubGnssAntenna:
              'Længde fra midten af en lige linje, der forbinder den forreste jordede ende foran til under-GNSS-antenne X',
            centerToMainGnssAntenna:
              'Y afstand fra centrum til hoved-GNSS elektrisk fase centrum',
            centerToSubGnssAntenna: `Y-afstand fra midten til sub-GNSS' elektriske fasecenter`,
            groundToMainGnssAntenna:
              'Z afstand fra jorden til hoved-GNSS-elektriske fasecenter',
            groundToSubGnssAntenna:
              'Z-afstand fra jord til sub-GNSS elektrisk fasecenter',
          },
          imuMountInfo: {
            boomImuOffsetAngle: 'Forsk. vinkel bom IMU',
            secondBoomImuOffsetAngle: 'Forsk. vinkel for 2. bom IMU',
            armImuOffsetAngle: 'Forsk. vinkel arm IMU',
            bucketImuOffsetAngle: 'Forsk. vinkel skovl IMU',
            bodyImuOffsetRollAngle: 'Forsk. ruln.vinkel karr. IMU',
            bodyImuOffsetPitchAngle: 'Forsk. hældn.vinkel karr. IMU',
          },
          gnssMountInfo: {
            main: {
              bodyCenterToGnss: {
                x: 'Hov.-GNSS X ift. karr.midte',
                y: 'Hov.-GNSS Y ift. karr.midte',
                z: 'Hov.-GNSS Z ift. karr.midte',
              },
            },
            sub: {
              bodyCenterToGnss: {
                x: 'Und.-GNSS X ift. karr.midte',
                y: 'Und.-GNSS Y ift. karr.midte',
                z: 'Und.-GNSS Z ift. karr.midte',
              },
            },
          },
          extendedModel: {
            swingBoom: 'Svingbom-model',
            twoPieceBoom: '2-delt bom-model',
            minimalSwingRadius: 'Forskydningsbom-model',
          },
          measuredValues: {
            otherLength: {
              machineHeight: 'Kontravægtsspillerum',
            },
            bodyImu1: {
              roll: 'Roll af IMU-målt værdi',
              pitch: `IMU'ens målte værdi for hældning`,
              yaw: 'Yaw af IMU målt værdi',
            },
            bodyImu2: {
              roll: 'Roll af IMU målt værdi',
              pitch: 'Pitch af IMU målt værdi',
              yaw: 'Yaw af IMU målt værdi',
            },
            frontLeft: {
              x: 'Målt værdi af forreste venstre X',
              y: 'Målt værdi af forreste venstre Y',
              z: 'Målt værdi af forreste venstre Z',
            },
            frontRight: {
              x: 'Målt værdi af forreste højre X',
              y: 'Målt værdi af forreste højre Y',
              z: 'Opmålt værdi af forreste højre Z',
            },
            frontGroundingEnd: {
              x: 'Opmålt værdi af forreste ende X',
              y: 'Opmålt værdi af forreste ende Y',
              z: 'Opmålt værdi af den forreste ende Z',
            },
            rearGroundingEnd: {
              x: 'Opmålt værdi af bageste ende X',
              y: 'Opmålt værdi af den bageste ende Y',
              z: 'Opmålt værdi af bageste gounding-ende Z',
            },
            mainGnssAntenna: {
              x: 'Opmålt værdi af GNSS-hovedantenne X',
              y: 'Opmålt værdi af hoved-GNSS-antenne Y',
              z: 'Opmålt værdi af hoved-GNSS-antenne Z',
            },
            subGnssAntenna: {
              x: 'Opmålt værdi af sub-GNSS-antenne X',
              y: 'Opmålt værdi af sub-GNSS-antenne Y',
              z: 'Opmålt værdi af sub-GNSS-antenne Z',
            },
            prismPoleOffset: 'Prismestangens højde',
            antennaElectricalCenterHeight:
              'Højde fra antennens elektriske center',
          },
        },
      },
    },
    retrofitAccuracies: {
      name: 'Nøjagtighedskontrol resultat',
      fields: {
        checkDateTime: 'Bekræftelsesdato og tid',
        surveyPoint: 'Målt punkt',
        positionN: 'Målt værdi : N',
        positionE: 'E',
        positionZ: 'Z',
        machineOffsetN: 'Offsetværdi : N',
        machineOffsetE: 'E',
        machineOffsetZ: 'Z',
        referencePointName: 'Navn på referencepunkt',
        referencePointN: 'Referencepunkt: N',
        referencePointE: 'E',
        referencePointZ: 'Z',
        unitLength: 'Længdeenhed',
      },
    },
    retrofitBasicSettings: {
      name: 'Grundindstillinger',
      fields: {
        timeStamp: 'Modtagelsesdato og -tidspunkt',
        url: 'Hent',
      },
    },
    retrofitErrors: {
      name: 'Fejlinfo',
      fields: {
        notifiedTime: 'Forekomstdato og tid',
        errorCodes: 'Fejlkode: Detaljer',
      },
    },
    retrofitToposurveys: {
      name: 'Liste topog. undersøg.',
      fields: {
        surveyDate: 'Undersøgelsesdato og -tid',
        projectName: 'Projektnavn',
        layerName: 'Lag',
        pointName: 'Punktnavn',
        surveyPoint: 'Målt punkt',
        n: 'Målt værdi : N',
        e: 'E',
        z: 'Z',
        unitLength: 'Længdeenhed',
      },
    },
    asbuilts: {
      name: 'Byggeresultater',
      fields: {
        execStartDate: 'Udførelse start',
        execEndDate: 'Udførelse afsluttet',
        startPeriod: 'Anskaffelsesperiode fra',
        endPeriod: 'Anskaffelsesperiode til',
        execStatus: 'Status',
        url: '',
        csvType: 'Dataformat',
        createUser: 'Opret bruger',
      },
      execStatus: {
        complete: 'Fuldført',
        inprogress: 'Behandling',
        error: 'Fejl',
        completewithoutdata: 'Fuldført (Ingen data)',
      },
    },
    asbuiltTime: {
      fields: {
        id: 'indstillet tid',
      },
    },
    retrofitBasicInfos: {
      name: 'Grundoplysninger',
      fields: {
        uuid: 'UUID',
        retrofitDistributorId: 'Distributør',
        retrofitRegionId: 'Region',
        corporationId: 'Virksomheds-ID',
        corporationName: 'Virksomhedsnavn',
        basicInfoManufacturer: 'Producent',
        basicInfoModel: 'Model',
        basicInfoProductNumber: 'Produktnr.',
        basicInfoSerialNumber: 'Serienummer',
        lastUpdated: 'Senest opdateret',
        description: 'Beskrivelse',
        ownerId: 'Virksomheds-ID',
        ownerName: 'Virksomhedsnavn',
      },
      tag: {
        retrofitBasicInfosDetail: 'Grundoplysninger',
        sendBack: 'Send tilbage',
      },
      sendBack: {
        fields: {
          site: 'Byggeplads',
          corporation: 'Virksomhed i brug',
          ntripSettingInfo: 'Ntrip ID',
          toRetrofit: 'Alternativ controller',
          notificationEmailAddress: 'E-mail',
        },
      },
    },
    retrofitBulkActions: {
      name: 'Bulk registrering',
      fields: {
        uploadFileName: 'Filnavn',
        execStartDate: 'Udførelse startdato og -tid',
        execEndDate: 'Udførelse slutdato og -tid',
        execStatus: 'Status',
        uploadFile: 'Registreret fil',
        resultFile: 'Registreringsresultater',
      },
      execStatus: {
        complete: 'Fuldført',
        inprogress: 'Behandling',
        error: 'Fejl',
      },
    },
    retrofitAdminCorporationBulkActions: {
      name: 'Administrationsvirksomhed i masse fra administrator af virksomheden',
      fields: {
        uploadFileName: 'Filnavn',
        execStartDate: 'Udførelse startdato og -tid',
        execEndDate: 'Udførelse slutdato og -tid',
        execStatus: 'Status',
        uploadFile: 'Registreret fil',
        resultFile: 'Registreringsresultater',
      },
      execStatus: {
        complete: 'Fuldført',
        inprogress: 'Behandling',
        error: 'Fejl',
      },
    },
    tasks: {
      name: 'Opgave',
      fields: {
        name: 'Navn',
        status: 'Status',
        description: 'Beskrivelse',
        projectSourceLayerId: 'Lag',
        retrofitIds: 'Målrettet maskine',
        expiredDate: 'Udløbsdato',
        createDate: 'Udløbsdato',
        rectangle: 'Understøttet rækkevidde',
        lastUpdated: 'Senest opdateret',
      },
      status: {
        error: 'Fejl',
        converting: 'Konvertering',
        sending: 'Overførsel',
        sent: 'Overført',
        failtosend: 'Overførsel mislykkedes',
      },
    },
    compactionMaterials: {
      name: 'Materialer',
      fields: {
        name: 'Navn',
      },
    },
    weathers: {
      name: 'Vejret',
      fields: {
        name: 'Navn',
      },
    },
    operators: {
      name: 'Operatører',
      fields: {
        name: 'Navn',
      },
    },
    compactionWorkAreas: {
      name: 'Komprimeringsarbejdsområder',
      fields: {
        name: 'Arbejdsområdenavne',
        blockSize: 'Blokstørrelse',
        description: 'Beskrivelse',
      },
    },
    compactionLayers: {
      name: 'Komprimeringslagene',
      fields: {
        name: 'Navn',
        thickness: 'Tykkelse',
        passCount: 'Antal gange',
        compactionMaterialId: 'Materiale',
        inUse: 'Status',
        description: 'Beskrivelse',
      },
      inUse: {
        true: 'i brug',
        false: 'Ubrugt',
      },
    },
    gnssSettings: {
      name: 'Ntrip-indstilling',
      fields: {
        registCorporationName: 'Registreret af',
        ownerCorporationId: 'Virksomheds-ID',
        ownerCorporationName: 'Virksomhedsnavn',
        name: 'Navn',
        url: 'Server-URL',
        portnum: 'Port',
        userId: 'ID',
        password: 'Adgangskode',
        retrofitId: 'Målrettet maskine',
        status: 'Status',
        updateDate: 'Senest opdateret',
      },
      status: {
        waitforsend: 'Konvertering',
        sending: 'Overførsel',
        sent: 'Overført',
        failtosend: 'Overførsel mislykkedes',
      },
    },
    supportMode: {
      name: 'Supporttilstand',
      fields: {
        enabled: 'Aktiver supporttilstand',
        corporationId: 'Virksomheds-ID',
        corporationName: 'Virksomhedsnavn',
        siteId: 'Byggeplads ID',
        siteName: 'Byggeplads',
        latitude: 'Breddegrad',
        longitude: 'Længdegrad',
        workPeriodStartDate: 'Faktisk byggeperiode (startdato)',
        workPeriodEndDate: 'Faktisk byggeperiode (slutdato)',
        photoUrl: 'Webstedsbillede URL',
        description: 'Beskrivelse',
      },
    },
    userNotices: {
      name: 'Underretning',
      noticeType: {
        emergencyimportant: 'Nød/Vigtig',
        emergency: 'Nød',
        important: 'Viktig',
      },
      fields: {
        noticeType: 'Betydning',
        noticeTypes: 'Betydning',
        noticeCategory: 'Notifikationstype',
        title: 'Titel',
        content: 'Indhold',
        publicationStartDate: 'Startdato og -tid (UTC)',
        publicationEndDate: 'Slutdato og -tid(UTC)',
        file: 'Vedhæftninger',
        language: 'Sprog',
        emergency: 'Nød',
        important: 'Viktig',
      },
    },
    firmwares: {
      name: 'Firmware',
      fields: {
        version: 'Version',
        compatibilityLevel: 'Kompatibilitetsniveau',
        firmwareFile: 'Firmwarefil',
        firmwareFileCheckSum: 'Firmwarekontrolsum',
        firstInitializationFile: 'Initialiseringsfil 1',
        firstInitializationFileCheckSum: 'Initialiseringsfil 1 CheckSum',
        secondInitializationFile: 'Initialiseringsfil 2',
        secondInitializationFileCheckSum: 'Initialiseringsfil 2 CheckSum',
      },
    },
    bucketShares: {
      name: 'Spand deling',
      fields: {
        fromBucketId: 'Fra spand-id',
        corporationId: 'Virksomheds-ID',
        name: 'Navn',
      },
    },
    extensionarmShare: {
      name: 'Forlængerarmdeling',
      fields: {
        name: 'Navn',
      },
    },
    retrofitCorporationLicenses: {
      name: 'LICENS',
      fields: {
        licenseId: 'Licens-id',
        licenseType: 'Type',
        issuedAt: 'Udgivelsesdato',
        expiresAt: 'Udløbsdato',
        retrofitId: 'Maskin',
        comment: 'Beskrivelse',
        licenseStatus: 'Status',
        licensePermission: 'LICENS',
        productType: 'Produktnavn',
      },
      licenseStatus: {
        unlicense: 'Ikke allokeret',
        disable: 'Ugyldig',
        enable: 'Gyldig',
      },
    },
    retrofitNamePlateFiles: {
      fields: {
        corporationName: 'Virksomhedsnavn',
        namePlates: 'Foto',
      },
    },
    siteConfigures: {
      fields: {
        unitLength: 'Længdeenhed',
      },
    },
    geofences: {
      name: 'Geohegn',
      fields: {
        type: 'Type',
        name: 'Navn',
        dimension: 'Dimension',
        trigger: 'Udløser',
        valid: 'Gyldig',
        alertType: 'Type af advarsel',
        startDate: 'Startdato',
        endDate: 'Slutdato',
        transparency: 'Gennemsigtig',
        elevation: 'Højde',
        height: 'Højde',
        radius: 'Radius',
        lastUpdated: 'Senest opdateret',
        points: 'Understøttet rækkevidde',
        thickness: 'Tykkelse',
      },
      type: {
        line: 'Linje',
        polygon: 'Polygon',
        circle: 'Cirkel',
        point: 'Punkt',
        wall: 'Væg',
      },
      dimension: {
        twodimension: '2D',
        threedimension: '3D',
      },
      valid: {
        true: 'Gyldig',
        false: 'Ugyldig',
      },
      alertType: {
        attention: 'Bemærkning',
        notice: 'Bemærk',
        warning: 'Advarsel',
        caution: 'Forsigtig',
        danger: 'Fare',
      },
      transparency: {
        true: 'Gennemsigtig',
        false: 'Ugennemsigtig',
      },
    },
    geofenceAlerts: {
      name: 'Advarsler',
      fields: {
        timeStamp: 'Opdagelsesdato og tid',
        geofenceName: 'Geofence-navn',
        alertType: 'Type af advarsel',
        collisionPoint: 'Kollisionspunkt',
        retrofit: {
          machineInfoMachineName: 'Maskin',
        },
      },
      alertType: {
        attention: 'Bemærkning',
        notice: 'Bemærk',
        warning: 'Advarsel',
        caution: 'Forsigtig',
        danger: 'Fare',
      },
    },
    ntrips: {
      name: 'Ntrip-håndtering',
      fields: {
        name: 'Bruger-id',
        ntripPass: 'Adgangskode',
        serial: 'Serienummer',
        customerCorporationId: 'Virksomheds-ID',
        customerCorporationName: 'Virksomhedsnavn',
        ntrip: 'Status',
        numberOfExchanges: 'Antal udvekslinger',
        id: 'ID',
        licenceId: 'Licens-id',
        dateOfRegistration: 'Registreringsdato',
        billingToCustomers: 'Fakturering til kunder',
        paymentToKomatsu: 'Betaling til Komatsu',
        applicantId: 'Ansøger-ID',
        applicant: 'Ansøger',
        requestCorporationId: 'Anmod om virksomheds-ID',
        requestCorporationName: 'Anmod om virksomhedsnavn',
        isUsed: 'Er i brug',
      },
      ntrip: {
        publish: 'Udbetalt',
        stop: 'Stop',
        delete: 'Slettet',
      },
    },
    retrofitAlternateRegists: {
      name: 'Controller Proxy Registrering',
      fields: {
        id: 'ID',
        retrofitId: 'retrofitId',
        fromCorporationId: 'fra selskabs-id',
        fromCorporationName: 'Controller Proxy Registrering',
        toCorporationId: 'Bruger',
        toCorporationName: 'Virksomhedsnavn',
        isApproved: 'Status',
        createDate: 'Ansøgningsdato',
        actualDate: 'Godkendelsesdato',
        transferUsers: 'E-mail',
        email: 'E-mail',
        lastUpdated: 'Senest opdateret',
      },
      isApproved: {
        true: 'Godkendt',
        false: 'Ikke godkendt',
      },
    },
    pendingApprovals: {
      name: 'Afventer godkendelse',
      fields: {
        approvalType: 'Type',
        requestedBy: 'Ansøgerfirma',
        lastUpdated: 'Senest opdateret',
      },
      approvalType: {
        assignment: 'Opgave',
        transfer: 'Overførsel',
        agent: 'Controller Proxy Registrering',
      },
    },
    retrofitSendbacks: {
      name: 'Send tilbage',
      fields: {
        fromRetrofitId: 'Fra Retrofit',
        toRetrofitId: 'Alternativ controller',
        notificationEmailAddress: 'E-mail',
        siteId: 'Byggeplads',
        corporationId: 'Virksomheds-ID',
        corporationName: 'Virksomhed i brug',
        ntripId: 'Ntrip-info',
        isComplete: 'Send tilbage fuldført',
      },
    },
    siteBuriedStructures: {
      fields: {
        epsgCode: 'Epsg',
      },
    },
    fileStorages: {
      fields: {
        name: 'Name',
        createdBy: {
          user: {
            profile: {
              name: 'Author',
            },
          },
        },
        updatedAt: 'Last Modified',
        updatedby: {
          user: {
            profile: {
              name: 'Last Modified By',
            },
          },
        },
        files: {
          totalSize: 'Size',
        },
        file: {
          size: 'Size',
        },
      },
      nodeType: {
        bucket: 'Bucket',
        directory: 'Folder',
        file: 'File',
      },
    },
  },
};

export default message;
