import { convertFileSize } from '../../../utils';
import { FileStorageData } from '../../types';

const buckets: FileStorageData[] = [
  {
    id: 'i7fhvgbr87p30l2sg4hs',
    name: 'SC_APP',
    resourceOwnerId: '44268432-8882-429e-bea5-f5c519087267',
    nodeType: 'bucket',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
        displayName: 'SC-DashboardSTG',
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
        displayName: 'SC-DashboardSTG',
      },
    },
    files: {
      totalCount: '13',
      totalSize: convertFileSize('42490343'),
    },
  },
  {
    id: 'fpnvgr4u2uk8738tsfsc',
    name: 'ファイル管理',
    resourceOwnerId: '44268432-8882-429e-bea5-f5c519087267',
    nodeType: 'bucket',
    createdAt: '2024-10-09T16:01:00Z',
    updatedAt: '2024-10-09T16:39:00Z',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
        displayName: 'Smart Construction Groupware',
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
        displayName: 'Smart Construction Groupware',
      },
    },
    files: {
      totalCount: '2',
      totalSize: convertFileSize('37990383'),
    },
  },
];

const folders: FileStorageData[] = [
  {
    id: '2ol9gklb0naeng3hp33e',
    parentNodeId: null,
    nodeType: 'directory',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: true,
    key: '/AsBuilt/',
    name: 'AsBuilt',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    bucketId: 'i7fhvgbr87p30l2sg4hs',
  },
  {
    id: '7s7sr9l8vgg4roo66sjr',
    parentNodeId: 'e9c4u1ol13e1s6ukssgn',
    nodeType: 'directory',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: true,
    key: '/Design/',
    name: 'Design',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    bucketId: 'i7fhvgbr87p30l2sg4hs',
  },
  {
    id: '50kra2gd506enickn6pj',
    parentNodeId: null,
    nodeType: 'directory',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: true,
    key: '/Localization/',
    name: 'Localization',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    bucketId: 'i7fhvgbr87p30l2sg4hs',
  },
  {
    id: '0ff4tm9skcqtug71jrph',
    parentNodeId: null,
    nodeType: 'directory',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: true,
    key: '/MachineTransmission/',
    name: 'MachineTransmission',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    bucketId: 'i7fhvgbr87p30l2sg4hs',
  },
  {
    id: 'hlhu8ek07ikfhu8h11ia',
    parentNodeId: 'e9c4u1ol13e1s6ukssgn',
    nodeType: 'directory',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: true,
    key: '/Ortho image/',
    name: 'Ortho image',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    bucketId: 'i7fhvgbr87p30l2sg4hs',
  },
  {
    id: 'lbrgioq9itg3g52ch8q6',
    parentNodeId: null,
    nodeType: 'directory',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: true,
    key: '/Output/',
    name: 'Output',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    bucketId: 'i7fhvgbr87p30l2sg4hs',
  },
  {
    id: '0rack05f8hc9r6mtp19o',
    parentNodeId: null,
    nodeType: 'directory',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: true,
    key: '/Preconstruction/',
    name: 'Preconstruction',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    bucketId: 'i7fhvgbr87p30l2sg4hs',
  },
  {
    id: 'i2qdceuk3fb14dp8cmhr',
    parentNodeId: null,
    nodeType: 'directory',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: true,
    key: '/Shared/',
    name: 'Shared',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    bucketId: 'i7fhvgbr87p30l2sg4hs',
  },
  {
    id: '3ee94ffftc9lquah7ti4',
    parentNodeId: null,
    nodeType: 'directory',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: true,
    key: '/Survey/',
    name: 'Survey',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    bucketId: 'i7fhvgbr87p30l2sg4hs',
  },
  {
    id: 'e9c4u1ol13e1s6ukssgn',
    parentNodeId: '0ff4tm9skcqtug71jrph',
    nodeType: 'directory',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: true,
    key: '/Untitled folder/',
    name: 'Untitled folder',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    bucketId: 'i7fhvgbr87p30l2sg4hs',
  },
  {
    id: 'rl9p46mehcb6mvurbpap',
    parentNodeId: null,
    nodeType: 'directory',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: true,
    key: '/Vector overlay/',
    name: 'Vector overlay',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    bucketId: 'i7fhvgbr87p30l2sg4hs',
  },
];

const files: FileStorageData[] = [
  {
    id: 'kj2nvq3olln5v9fhbqp3',
    parentNodeId: 'e9c4u1ol13e1s6ukssgn',
    nodeType: 'file',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: false,
    key: '/Untitled folder/MIHAMA2nd_20161111.tp3',
    name: 'MIHAMA2nd_20161111.tp3',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    file: {
      size: convertFileSize('4433520'),
      checksum: 'uYEYE2f1/WLMujUQ3Fu7xw==',
    },
    bucketId: 'i7fhvgbr87p30l2sg4hs',
  },
  {
    id: 'vhjb6pp1vn1k14rgb1hm',
    parentNodeId: 'e9c4u1ol13e1s6ukssgn',
    nodeType: 'file',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: false,
    key: '/Untitled folder/MIHAMA2nd_20161111.xml',
    name: 'MIHAMA2nd_20161111.xml',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    file: {
      size: convertFileSize('36910'),
      checksum: 'BJdKwJ6UuSrT1qccYtS24w==',
    },
    bucketId: 'i7fhvgbr87p30l2sg4hs',
  },
  {
    id: 'qh68bro3ivng50jkenka',
    parentNodeId: 'e9c4u1ol13e1s6ukssgn',
    nodeType: 'file',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: false,
    key: '/Untitled folder/README.md',
    name: 'README.md',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    file: {
      size: convertFileSize('408'),
      checksum: 'sdEUO4osURtaXXPZ30uoIQ==',
    },
    bucketId: 'i7fhvgbr87p30l2sg4hs',
  },
  {
    id: 'ps8h58sot33fnaul62f9',
    parentNodeId: 'e9c4u1ol13e1s6ukssgn',
    nodeType: 'file',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: false,
    key: '/Untitled folder/alignment.xml',
    name: 'alignment.xml',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    file: {
      size: convertFileSize('44223'),
      checksum: 'cueKW5br4B8Ynzq+5+dnYA==',
    },
    bucketId: 'i7fhvgbr87p30l2sg4hs',
  },
  {
    id: '9lts71cf62232r1j2q2a',
    parentNodeId: 'e9c4u1ol13e1s6ukssgn',
    nodeType: 'file',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: false,
    key: '/Untitled folder/building.dxf',
    name: 'building.dxf',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    file: {
      size: convertFileSize('3640418'),
      checksum: 'ZTqEIODm5YcphfzXCGkOvQ==',
    },
    bucketId: 'i7fhvgbr87p30l2sg4hs',
  },
  {
    id: 'b1hii741qnqdb2m535r3',
    parentNodeId: 'e9c4u1ol13e1s6ukssgn',
    nodeType: 'file',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: false,
    key: '/Untitled folder/mihama.geojson',
    name: 'mihama.geojson',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    file: {
      size: convertFileSize('941'),
      checksum: '3knHBdi2qAH3DKUQqX8F2g==',
    },
    bucketId: 'i7fhvgbr87p30l2sg4hs',
  },
  {
    id: '5fmka6bh85ka74p5m9kq',
    parentNodeId: 'e9c4u1ol13e1s6ukssgn',
    nodeType: 'file',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: false,
    key: '/Untitled folder/mihama_asbuilt.txt',
    name: 'mihama_asbuilt.txt',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    file: {
      size: convertFileSize('49553'),
      checksum: 'RXuz1wRnUaeBKA23KucVRg==',
    },
    bucketId: 'i7fhvgbr87p30l2sg4hs',
  },
  {
    id: 'n9635dhsual82rnradqr',
    parentNodeId: 'e9c4u1ol13e1s6ukssgn',
    nodeType: 'file',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: false,
    key: '/Untitled folder/pnpm-lock.yaml',
    name: 'pnpm-lock.yaml',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    file: {
      size: convertFileSize('721989'),
      checksum: '4zo4ZBBkjO34T51+MuEuGQ==',
    },
    bucketId: 'i7fhvgbr87p30l2sg4hs',
  },
  {
    id: '96tbd03pe69v6vou88q6',
    parentNodeId: 'e9c4u1ol13e1s6ukssgn',
    nodeType: 'file',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: false,
    key: '/Untitled folder/pnpm-workspace.yaml',
    name: 'pnpm-workspace.yaml',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    file: {
      size: convertFileSize('27'),
      checksum: 'i/74GgJ3gzt0iKie/lNJxw==',
    },
    bucketId: 'i7fhvgbr87p30l2sg4hs',
  },
  {
    id: 'kj2nvq3olln5v9fhbqp4',
    parentNodeId: '7s7sr9l8vgg4roo66sjr',
    nodeType: 'file',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: false,
    key: 'MachineTransmission/Untitled folder/Design/1735286761489_eb9f92a2-e674-4f84-84ca-412118ebbb8f_★newMIHAMA_SekkeiData_0.tp3',
    name: '1735286761489_eb9f92a2-e674-4f84-84ca-412118ebbb8f_★newMIHAMA_SekkeiData_0.tp3',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    file: {
      size: convertFileSize('4121'),
      checksum: 'uYEY23r23dvsdgwer=',
    },
    bucketId: 'i7fhvgbr87p30l2sg4hs',
  },
  {
    id: 'kj2nvq3olln5v9fhbqp5',
    parentNodeId: '7s7sr9l8vgg4roo66sjr',
    nodeType: 'file',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: false,
    key: 'MachineTransmission/Untitled folder/Design/1735286761489_eb9f92a2-e674-4f84-84ca-412118ebbb8f_★newMIHAMA_SekkeiData_0.tp3',
    name: '1735286761489_eb9f92a2-e674-4f84-84ca-412118ebbb8f_★newMIHAMA_SekkeiData_0.tp3',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    file: {
      size: convertFileSize('21'),
      checksum: 'uYEY23r23dvsdgwer=',
    },
    bucketId: 'i7fhvgbr87p30l2sg4hs',
  },
  {
    id: 'kj2nvq3oll23r423r4fc',
    parentNodeId: '7s7sr9l8vgg4roo66sjr',
    nodeType: 'file',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: false,
    key: 'MachineTransmission/Untitled folder/Design/2rfwev489_eb9f92a2-e674-4f84-84ca-412118ebbb8f_★newMIHAMA_SekkeiData_0.tp3',
    name: '2rfwev489_eb9f92a2-e674-4f84-84ca-412118ebbb8f_★newMIHAMA_SekkeiData_0.tp3',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    file: {
      size: convertFileSize('70103'),
      checksum: 'uYEY23r23dvsdgwer=',
    },
    bucketId: 'i7fhvgbr87p30l2sg4hs',
  },
  {
    id: 'i0-9uyhubhkjn',
    parentNodeId: '7s7sr9l8vgg4roo66sjr',
    nodeType: 'file',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: false,
    key: 'MachineTransmission/Untitled folder/Design/0vft78_eb9f92a2-e674-4f84-84ca-412118ebbb8f_★newMIHAMA_SekkeiData_0.tp3',
    name: '0vft78_eb9f92a2-e674-4f84-84ca-412118ebbb8f_★newMIHAMA_SekkeiData_0.tp3',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    file: {
      size: convertFileSize('793242'),
      checksum: 'uYEY23r23dvsdgwer=',
    },
    bucketId: 'i7fhvgbr87p30l2sg4hs',
  },
  {
    id: '093y2hroihvwyegr8w',
    parentNodeId: '7s7sr9l8vgg4roo66sjr',
    nodeType: 'file',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: false,
    key: 'MachineTransmission/Untitled folder/Design/j09wfsodvnoi_eb9f92a2-e674-4f84-84ca-412118ebbb8f_★newMIHAMA_SekkeiData_0.tp3',
    name: 'j09wfsodvnoi_eb9f92a2-e674-4f84-84ca-412118ebbb8f_★newMIHAMA_SekkeiData_0.tp3',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    file: {
      size: convertFileSize('684098'),
      checksum: 'uYEY23r23dvsdgwer=',
    },
    bucketId: 'i7fhvgbr87p30l2sg4hs',
  },
  {
    id: 'qyef09wgri3t4',
    parentNodeId: '7s7sr9l8vgg4roo66sjr',
    nodeType: 'file',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: false,
    key: 'MachineTransmission/Untitled folder/Design/8hsoefihw8hr3toi2-e674-4f84-84ca-412118ebbb8f_★newMIHAMA_SekkeiData_0.tp3',
    name: '8hsoefihw8hr3toi2-e674-4f84-84ca-412118ebbb8f_★newMIHAMA_SekkeiData_0.tp3',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    file: {
      size: convertFileSize('92842792'),
      checksum: 'uYEY23r23dvsdgwer=',
    },
    bucketId: 'i7fhvgbr87p30l2sg4hs',
  },
];

const fileStorages: FileStorageData[] = [...buckets, ...folders, ...files];

export default fileStorages;
